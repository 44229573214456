import { v4 as uuidv4 } from "uuid";
// import { Notify } from "quasar";
import Lib from "src/h/helpers.js";
import pickerimage from "src/components/picker-picture/picker.vue";
import moment from "moment";

export default {
  props: {},
  components: {
    pickerimage,
  },
  data: () => ({
    streamerSystemNotify: null,
    sessionID: uuidv4(),
    list: [],
    lastNotification: {},
    notificationClosingID: null,
    animationTime: 300,
    animationTimeSmall: 100,
    messageMaxLength: 100,
    visibleDetails: false,
    calcMessageIntervalKey: "",
    timerMessageInterval: null,
    containerMessagesHeight: null,
    notificationAllClosing: false,
    linkGoto: null,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    userID() {
      return this.$store.getters.user?.ID;
    },
  },
  mounted() {
    if (
      this.userID !== undefined &&
      this.userID !== null &&
      this.$streamer.isConnect.value == true
    ) {
      this.streamerSystemNotifyConnect(this.userID);
    }
    this.setTimerMessageInterval();
  },
  beforeUnmount() {
    this.streamerSystemNotifyDisconnect();
    this.clearTimerMessageInterval();
  },
  watch: {
    "$streamer.isConnect.value": {
      handler(newVal, oldVal) {
        if (
          newVal === true &&
          oldVal === false &&
          this.userID !== undefined &&
          this.userID !== null
        ) {
          this.streamerSystemNotifyConnect(this.userID);
        }
      },
      immediate: false,
    },
    userID: {
      handler(newVal, oldVal) {
        if (
          newVal !== undefined &&
          newVal !== null &&
          this.$streamer.isConnect.value == true &&
          this.streamerSystemNotify == null
        ) {
          this.streamerSystemNotifyConnect(newVal);
        }
      },
      immediate: false,
    },
    visibleDetails: {
      handler(newVal, oldVal) {
        this.recalculateContainerMessagesHeight();
      },
      immediate: false,
    },
  },
  methods: {
    streamerSystemNotifyConnect(userID) {
      var lnk = this;
      lnk.streamerSystemNotify = lnk.$streamer.addTicket(
        "system_notification",
        userID,
        function (obj, sessionID) {
          switch (obj.Operation) {
            case "get_info":
              if (Array.isArray(obj.Object) && obj.Object.length > 0) {
                lnk.list = obj.Object;
              } else {
                lnk.list = [];
              }
              lnk.showLastNotification();
              break;
            case "new_notification":
              Lib.CopyObject(obj.Object, lnk.lastNotification);
              // if we want replace new message with animation, need setTimeout
              setTimeout(() => {
                lnk.list.splice(0, 0, obj.Object);
                Lib.PlayAudiosampleFromURL("chat-notification.wav", 0.01);
              }, lnk.animationTime);
              // lnk.showLastNotification();
              break;
            case "delete_notification":
              lnk.notificationDeleted(obj.Object);
              break;
            case "delete_all_notifications":
              lnk.allNotificationDeleted(obj.Object);
              break;
          }
        },
        function (obj, lastSend, lastOp) {
          // let res = JSON.parse(obj);
          if (lastOp == "generate_ticket_token") {
            lnk.loaded = false;
          } else if (lastOp == "message") {
            switch (lastSend?.Operation) {
              case "delete_notification":
                lnk.notificationClosingID = null;
                break;
              case "delete_all_notifications":
                lnk.notificationAllClosing = false;
                break;
            }
          }
        },
        this.sessionID,
        function () {
          lnk.streamerSystemNotify.send({
            Operation: "get_info",
            Object: {},
          });
        },
      );
    },
    streamerSystemNotifyDisconnect() {
      if (this.streamerSystemNotify !== null) {
        this.streamerSystemNotify.removeTicket();
      }
    },
    showLastNotification() {
      if (this.list.length > 0) {
        Lib.CopyObject(this.list[0], this.lastNotification);
      } else {
        this.lastNotification = {};
      }
      // this.$q.notify({
      //   message: "New message",
      //   color: "v",
      //   position: "top-right",
      //   group: false, // don't group with the same message
      //   classes: "notification-dialog",
      //   timeout: 0, // don't auto-close
      //   actions: [
      //     {
      //       icon: "close",
      //       color: "white",
      //       round: true,
      //       handler: () => {
      //         /* ... */
      //       },
      //     },
      //   ],
      // });
    },
    closeLastNotification() {
      this.closeNotification(this.lastNotification.ID);
    },
    closeNotification(ID) {
      this.notificationClosingID = ID;
      this.streamerSystemNotify.send({
        Operation: "delete_notification",
        Object: {
          ID: ID,
        },
      });
    },
    closeAllNotifications() {
      this.notificationAllClosing = true;
      this.streamerSystemNotify.send({
        Operation: "delete_all_notifications",
        Object: {},
      });
    },
    allNotificationDeleted(obj) {
      this.notificationAllClosing = false;
      if (obj.Count) {
        // this for animation
        Lib.CopyObject(
          {
            ID: null,
            // ChatMessage: { ID: null },
          },
          this.lastNotification,
        );
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].hide = true;
        }

        // after delete all notifications get list from backend again (if have other notifications)
        setTimeout(() => {
          this.list.splice(0, this.list.length);
          this.streamerSystemNotify.send({
            Operation: "get_info",
            Object: {},
          });
        }, this.animationTime);
      }
    },
    notificationDeleted(obj) {
      this.notificationClosingID = null;
      if (obj.ID == this.lastNotification.ID) {
        // first notification
        if (this.list.length > 1) {
          let ext = false;
          for (let i = 0; i < this.list.length; i++) {
            if (obj.ID === this.list[i].ID) {
              this.list.splice(i, 1);
              ext = true;
              break;
            }
          }
          if (ext) {
            setTimeout(() => {
              this.showLastNotification();
            }, this.animationTime);
          }
        } else {
          // if message is last for animation fade need create empty message before close
          Lib.CopyObject(
            {
              ID: null,
              // ChatMessage: { ID: null },
            },
            this.lastNotification,
          );
          setTimeout(() => {
            this.list.splice(0, 1);
            this.showLastNotification();
          }, this.animationTime);
        }
      } else {
        // from list notifications
        for (let i = 0; i < this.list.length; i++) {
          if (obj.ID === this.list[i].ID) {
            this.list[i].hide = true; // we create this temporary field for animation (will remove below)
            setTimeout(() => {
              this.list.splice(i, 1);
            }, this.animationTimeSmall);
            break;
          }
        }
      }
    },
    changeShowDetails() {
      this.visibleDetails = !this.visibleDetails;
    },
    goToNotifyObject(obj) {
      // Note: ref-eeed0b73: add here other notifications type
      if (obj.ObjectType == "chat_message") {
        switch (obj.ChatMessage?.ObjectType) {
          case "challengestudio":
            this.linkGoto = "/challenge-studio/" + obj.ChatMessage?.ObjectID;
            break;
          case "campusstudio":
            this.linkGoto = "/campus/" + obj.ChatMessage?.ObjectID;
            break;
          case "storystudio":
            this.linkGoto = "/story-studio/" + obj.ChatMessage?.ObjectID;
            break;
          case "classroom":
            this.linkGoto = "/classroom/" + obj.ChatMessage?.ObjectID;
            break;
          default:
            return;
        }
      } else if (obj.ObjectType == "shared_content") {
        switch (obj.SharedContent?.ObjectType) {
          case "challenge":
            this.linkGoto = "/challenge-studio/" + obj.SharedContent?.ObjectID;
            break;
          case "story":
            this.linkGoto = "/story-studio/" + obj.SharedContent?.ObjectID;
            break;
          case "channel":
            this.linkGoto = "/c/id/" + obj.SharedContent?.ObjectID;
            break;
          case "playlist":
            this.linkGoto = "/playlist/" + obj.SharedContent?.ObjectID;
            break;
          default:
            return;
        }
      } else if (obj.ObjectType == "story_pass_turn") {
        this.linkGoto =
          "/story-pass/" +
          obj.StoryPassTurn?.StoryParentID +
          "?l=" +
          obj.StoryPassTurn?.Link;
      } else if (obj.ObjectType == "membership_owner_to_user") {
        switch (obj.MembershipOwnerToUser?.ObjectType) {
          case "classroom":
            this.linkGoto = "/classroom/" + obj.MembershipOwnerToUser?.ObjectID;
            break;
          case "campus":
            this.linkGoto = "/campus/" + obj.MembershipOwnerToUser?.ObjectID;
            break;
          case "organization":
            this.linkGoto = "/settings/organizations";
            break;
          default:
            return;
        }
      } else if (obj.ObjectType == "membership_user_to_owner") {
        switch (obj.MembershipUserToOwner?.ObjectType) {
          case "classroom":
            this.linkGoto = "/classroom/" + obj.MembershipUserToOwner?.ObjectID;
            break;
          case "campus":
            this.linkGoto = "/campus/" + obj.MembershipUserToOwner?.ObjectID;
            break;
          case "organization":
            this.linkGoto =
              "/organization/" + obj.MembershipUserToOwner?.ObjectID;
            break;
          default:
            return;
        }
      } else if (obj.ObjectType == "genai_content_ready") {
        switch (obj.GenaiContent?.ObjectType) {
          case "story":
            this.linkGoto = "/story-pass/" + obj.GenaiContent?.ObjectID;
            break;
          default:
            return;
        }
      } else {
        console.error("Error-9d41db9e: can't goto notify object ");
        return;
      }
      setTimeout(() => {
        this.$refs["linkgoto_" + this.sessionID].click();
        this.closeNotification(obj.ID);
      }, 100);
    },
    dateMessageIntervalStr(datesrc) {
      let a = moment(Date.now());
      let b = moment(datesrc);

      if (a.diff(b, "year") > 0) {
        return (
          a.diff(b, "year") +
          " " +
          (a.diff(b, "year") == 1
            ? this.$t("-raw-system-notifications-year-ago")
            : this.$t("-raw-system-notifications-years-ago"))
        );
      } else if (a.diff(b, "months") > 0) {
        return (
          a.diff(b, "months") +
          " " +
          (a.diff(b, "months") == 1
            ? this.$t("-raw-system-notifications-month-ago")
            : this.$t("-raw-system-notifications-months-ago"))
        );
      } else if (a.diff(b, "days") > 0) {
        return (
          a.diff(b, "days") +
          " " +
          (a.diff(b, "days") == 1
            ? this.$t("-raw-system-notifications-day-ago")
            : this.$t("-raw-system-notifications-days-ago"))
        );
      } else if (a.diff(b, "hours") > 0) {
        return (
          a.diff(b, "hours") +
          " " +
          (a.diff(b, "hours") == 1
            ? this.$t("-raw-system-notifications-hour-ago")
            : this.$t("-raw-system-notifications-hours-ago"))
        );
      } else if (a.diff(b, "minutes") > 0) {
        return (
          a.diff(b, "minutes") +
          " " +
          (a.diff(b, "minutes") == 1
            ? this.$t("-raw-system-notifications-minute-ago")
            : this.$t("-raw-system-notifications-minutes-ago"))
        );
      } else {
        return this.$t("-raw-system-notifications-now");
      }
    },
    clearTimerMessageInterval() {
      if (this.timerMessageInterval) {
        clearInterval(this.timerMessageInterval);
      }
    },
    setTimerMessageInterval() {
      this.clearTimerMessageInterval();
      this.timerMessageInterval = setInterval(
        function (pnt) {
          // pnt.calcMessageIntervalKey++;
          pnt.calcMessageIntervalKey = Math.random().toString(36).slice(2, 7);
        },
        5000, // every 5sec recalc message about time message
        this,
      );
    },
    recalculateContainerMessagesHeight() {
      setTimeout(() => {
        let elem = document.getElementById("chip_" + this.sessionID);
        if (elem) {
          this.containerMessagesHeight =
            window.innerHeight - elem.getBoundingClientRect().bottom - 25;
        }
      }, 100);
    },
    capitalize(str) {
      return Lib.capitalize(str);
    },
  },
};
