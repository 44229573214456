<template>
  <!-- <hero class="xs" /> -->
  <!-- class="gt-xs" -->
  <component v-for="(section, s) in sections" :key="s" :is="section" />
</template>

<script setup>
import hero from "./section1.vue";
// import about from "./section2.vue";
// import student from "./section3.vue";
// import teacher from "./section4.vue";
// import content from "./section5.vue";
// import stories from "./section6.vue";
// import questions from "./section7.vue";
import footer from "./section8.vue";
// import pricingplansmobile from "../pricing/pricing-plans-mobile.vue";

const sections = [
  hero,
  // about,
  // student,
  // pricingplansmobile,
  // teacher,
  // content,
  // stories,
  // questions,
  footer,
];
</script>
