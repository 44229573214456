import enus from "./en-US.json";
import eses from "./es-ES.json";
import ruru from "./ru-RU.json";
import zhtw from "./zh-TW.json";
import zhcn from "./zh-CN.json";
import fifi from "./fi-FI.json";
import kokr from "./ko-KR.json";
import kage from "./ka-GE.json";
import hyam from "./hy-AM.json";
import heil from "./he-IL.json";
import frfr from "./fr-FR.json";
import dede from "./de-DE.json";
import ptbr from "./pt-BR.json";
import sahru from "./sah-RU.json";
import plpl from "./pl-PL.json";
import hiin from "./hi-IN.json";
import jajp from "./ja-JP.json";
import itit from "./it-IT.json";
import ttru from "./tt-RU.json";
import ukua from "./uk-UA.json";
import trtr from "./tr-TR.json";
import thth from "./th-TH.json";
import arar from "./ar-AR.json";
import kkkz from "./kk-KZ.json";
import svsv from "./sv-SV.json";
import nono from "./no-NO.json";
import lala from "./la-LA.json";
import idid from "./id-ID.json";
import fair from "./fa-IR.json";
import mnkgm from "./mnk-GM.json";
import swasw from "./swa-SW.json";
import kikki from "./kik-KI.json";
import luglg from "./lug-LG.json";

export default {
  "en-US": enus,
  "es-ES": eses,
  "ru-RU": ruru,
  "zh-TW": zhtw,
  "zh-CN": zhcn,
  "ko-KR": kokr,
  "ka-GE": kage,
  "hy-AM": hyam,
  "he-IL": heil,
  "fr-FR": frfr,
  "de-DE": dede,
  "pt-BR": ptbr,
  "sah-RU": sahru,
  "pl-PL": plpl,
  "hi-IN": hiin,
  "ja-JP": jajp,
  "fi-FI": fifi,
  "it-IT": itit,
  "tt-RU": ttru,
  "uk-UA": ukua,
  "tr-TR": trtr,
  "th-TH": thth,
  "ar-AR": arar,
  "kk-KZ": kkkz,
  "sv-SV": svsv,
  "no-NO": nono,
  "la-LA": lala,
  "id-ID": idid,
  "fa-IR": fair,
  "mnk-GM": mnkgm,
  "swa-SW": swasw,
  "kik-KI": kikki,
  "lug-LG": luglg,
};
