import api from "src/h/api.js";

export default {
  namespaced: true,
  state: {
    challenge: {
      ID: null,
      Name: null,
      Mode: null,
      AudioSampleRefID: null,
      Privacy: null,
      Share: [],
      Picture: null,
      Difficulty: null,
      SourceLanguageID: null,
      LanguageID: null,
      Categories: [],
      Level: null,
    },
    annotation: null,
    editAnnotations: [],
    needSavePicture: false,
    editedOggBlob: null,
    pictureStatus: null,
  },
  mutations: {
    challenge: (state, payload) => {
      if (!payload.Categories) {
        payload.Categories = [];
      }
      state.challenge = payload;
    },
    needSavePicture: (state, payload) => {
      state.needSavePicture = payload;
    },
    setChallengeSpelling: (state, payload) => {
      state.challenge.Spelling = payload;
    },
    setChallengeName: (state, payload) => (state.challenge.Name = payload),
    setChallengeMode: (state, payload) => (state.challenge.Mode = payload),
    setChallengeLanguage: (state, payload) =>
      (state.challenge.LanguageID = payload),
    setChallengeLevel: (state, payload) => (state.challenge.Level = payload),
    setChallengeSourceLanguage: (state, payload) => {
      state.challenge.SourceLanguageID = payload;
    },
    setChallengeCategories: (state, payload) => {
      state.challenge.Categories.splice(
        0,
        state.challenge.Categories.length,
        ...payload,
      );
    },
    setChallengePicture: (state, payload) => {
      state.challenge.Picture = payload;
      state.challenge.PictureDelete = false;
    },
    setChallengePrivacy: (state, payload) =>
      (state.challenge.Privacy = payload),
    deleteChallengePicture: (state) => {
      state.challenge.Picture = null;
      state.challenge.PictureDelete = true;
    },
    setChallengeDifficulty: (state, payload) =>
      (state.challenge.Difficulty = payload),
    setChallengeAudiosampleRefId: (state, payload) =>
      (state.challenge.AudioSampleRefID = payload),
    setAnnotation: (state, payload) => (state.annotation = payload),
    setAnnotations: (state, payload) => (state.annotations = payload),
    setTranscription: (state, payload) =>
      (state.annotation.Transcription = payload),
    setDescription: (state, payload) =>
      (state.annotation.Description = payload),
    setAcceptancePronunciation: (state, payload) => {
      if (state.annotation) {
        const acc = state.annotation.Acceptance || {};
        acc.Pronunciation = payload;
        state.annotation.Acceptance = acc;
      }
    },
    setLinkedItemID: (state, payload) =>
      (state.annotation.LinkedItemID = payload),
    deleteAnnotation: (state) => (state.annotation = null),
    clearAnnotations: (state) => {
      state.annotation = null;
      state.editLessonItem = null;
      state.annotations = [];
    },
    setEditedOggBlob: (state, payload) => {
      state.editedOggBlob = payload;
    },
    setPictureStatus: (state, payload) => (state.pictureStatus = payload),
  },
  actions: {
    updateChallenge({ commit, state }) {
      commit("setLoading", true, { root: true });
      return api
        .Call({
          url: "/api/v1/challenge",
          method: "patch",
          data: state.challenge, // NOTE: replace with updatedChallenge to attach to channel
          show_error: true,
          show_success: true,
        })
        .then(
          (challengeResponse) => {
            commit("challenge", challengeResponse);
            commit("setLoading", false, { root: true });
            commit("needSavePicture", false);

            if (
              this.$router.currentRoute.value.params.lessonID &&
              this.$router.currentRoute.value.params.challengeIndex
            ) {
              this.$router.push(
                "/lesson-studio/" +
                  this.$router.currentRoute.value.params.lessonID +
                  "/" +
                  this.$router.currentRoute.value.params.challengeIndex,
              );
            } else {
              this.$router.push("/challenge/" + state.challenge?.ID);
            }
          },
          (e) => {
            commit("setLoading", false, { root: true });
            commit("needSavePicture", false);
          },
        );
    },
    deleteChallengePicture({ commit, state }) {
      commit("setLoading", true, { root: true });
      return api
        .Call({
          url:
            "/api/v1/asset/file/" + state.challenge.Asset?.challenge_picture[0],
          method: "delete",
        })
        .then(
          () => {
            commit("setLoading", false, { root: true });
          },
          (e) => {
            commit("setLoading", false, { root: true });
          },
        );
    },
    createAudioSample({ dispatch, commit, rootGetters, state }) {
      const audioSample = {
        Label: "LABEL: ref 26c1d731",
        Duration: rootGetters.durationref,
        IsRef: true,
        IsPublic: true, // NOTE: SECURITY: All Audiosamples are Public for now.
        TypeID: "ref",
      };
      api
        .Call({
          url: "/api/v1/audiosample",
          method: "post",
          data: audioSample,
        })
        .then(
          (postAudiosampleResponse) => {
            return dispatch("sendAsset", {
              Blob: state.editedOggBlob,
              ObjectID: postAudiosampleResponse.ID,
              ObjectType: "audiosample",
              isRef: true,
            }).then(() => {
              commit(
                "setChallengeAudiosampleRefId",
                postAudiosampleResponse.ID,
              );
              dispatch("updateAudiosample").then(
                () => {
                  dispatch("updateChallenge").then(
                    () => {},
                    (e) => {
                      commit("setLoading", false, { root: true });
                      console.error(P + "error-1669b194", "updateChallenge", e);
                    },
                  );
                },
                (e) => {
                  commit("setLoading", false, { root: true });
                  console.error(P + "error-60309bb4", "updateAudiosample", e);
                },
              );
            });
          },
          (e) => {
            commit("setLoading", false, { root: true });
            console.error(P + "error-cf9e213a", "postAudiosample", e);
          },
        );
    },
    sendAsset({ commit }, asset) {
      const formData = new FormData();
      formData.append("object_id", asset.ObjectID);
      formData.append("file_type", asset.ObjectType);
      formData.append("file", asset.Blob, { knownLength: asset.Blob.size });

      return api
        .Call({
          url: "/api/v1/asset",
          data: formData,
          method: "post",
        })
        .then(
          (postAssetResponse) => {
            commit("setLoading", false, { root: true });
          },
          (e) => {
            commit("setLoading", false, { root: true });
            console.error(P + "error-32edf091", "postAssetCompare", e);
          },
        );
    },
    updateAudiosample({ state, commit, rootGetters, rootState }) {
      commit("setLoading", true, { root: true });
      api
        .Call({
          url: "/api/v1/audiosample/" + state.challenge.AudioSampleRefID,
          method: "patch",
          data: {
            Label: rootState.audiosampleLabel,
            Duration: Math.round(rootGetters.durationref * 1000),
            Annotations: state.annotations,
          },
        })
        .then(
          () => {
            commit("needSavePicture", true);
          },
          (e) => {
            commit("setLoading", false, { root: true });
            commit(
              "setError",
              { code: "error-3a71deba: updateAudiosample error" },
              { root: true },
            );
            console.log("error-63a406d7: updateAudiosample", e);
          },
        );
    },
    updateAsset({ commit }, asset) {
      const formData = new FormData();
      formData.append("object_id", asset.ObjectID);
      formData.append("file_type", asset.ObjectType);
      formData.append("file", asset.Blob, { knownLength: asset.Blob.size });
      commit("setEditedOggBlob", null);
      api
        .Call({
          url: "/api/v1/asset",
          data: formData,
          method: "post",
        })
        .then(
          (postAssetResponse) => {},
          (e) => {
            commit("setLoading", false, { root: true });
            commit(
              "setError",
              { code: "error-77c96a89: updateAsset error" },
              { root: true },
            );
            console.error("error-77c96a89: updateAsset", e);
          },
        );
    },
  },
  getters: {
    getAnnotation: (state) => state.annotation,
  },
};
