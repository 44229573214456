<template>
  <div class="round-both">
    <q-btn
      flat
      round
      class="text-5"
      style="width: 3rem; height: 3rem"
      v-for="link in filteredleft"
      :aria-label="link.name"
      :class="link === filteredleft[filteredleft.length - 1] ? '' : 'q-mb-md'"
      :color="link.to == $route.path ? 'v' : ''"
      :key="link.to"
      :to="link.to"
    >
      <q-icon size="20pt" :name="link.icon" />
      <q-tooltip
        anchor="center left"
        self="center right"
        :offset="[16, 0]"
        class="gt-sm bg-1 text-3 navtip row items-center q-px-lg round-right"
        transition-show="flip-left"
        transition-hide="flip-right"
        transition-duration="300"
        :class="{
          borderoutlinetopleft: !neu && link === filteredleft[0],
          borderoutlineleft: !neu && link !== filteredleft[0],
          'round-almost-right': link === filteredleft[0],
        }"
        >{{ link.name }}</q-tooltip
      >
    </q-btn>
  </div>
</template>

<script>
export default {
  props: ["neu", "user", "left", "leftmini"],
  data: () => ({
    leftlinks: [
      // {
      //   name: 'Welcome', icon: 'fas fa-hand-spock', to: '/welcome', if: true
      // },
      // {
      //   name: 'Classroom', icon: 'trip_origin', to: '/classroom', if: false
      // },
      // {
      //   name: 'Studio',
      //   icon: 'svguse:iconz.svg#create',
      //   to: '/studio',
      //   if: false,
      //   expand: false
      // },
      // {
      //   name: 'Messages', icon: 'svguse:iconz.svg#chat', to: '/messages'
      // },
      // {
      //   name: 'Records', icon: 'graphic_eq', to: '/records'
      // },
      {
        name: "Subscribe",
        to: "/subscription",
        icon: "verified",
      },
      {
        name: "Contact Us",
        to: "/contactus",
        icon: "connect_without_contact",
      },
      {
        name: "Discovery",
        to: "/discovery",
        icon: "search",
      },
      // {
      //   name: 'Create',
      //   to: '/record',
      //   icon: 'svguse:logo.svg#logo',
      //   if: true
      // },
      // {
      //   name: 'Settings',
      //   to: '/settings',
      //   icon: 'settings',
      //   if: true
      // }
      // {
      //   name: 'Logout', color: 'text-n', icon: 'exit_to_app', action: this.Logout()
      // }
    ],
  }),
  computed: {
    filteredleft() {
      const arr = [];
      for (const i in this.leftlinks) {
        const u = this.user;
        const b = this.leftlinks[i].if;
        // if (!u && b) arr.push(this.leftlinks[i])
        // if (u && !b) arr.push(this.leftlinks[i])
        if (b) {
          if (u) {
            arr.push(this.leftlinks[i]);
          }
        } else {
          arr.push(this.leftlinks[i]);
        }
      }
      return arr;
    },
  },
};
</script>

<style>
.navtip {
  background: linear-gradient(160deg, var(--q-1), var(--q-1), var(--q-t));
  transform-origin: top left;
  height: 50px !important;
  font-size: 12pt;
  font-weight: 1000;
}
.round-almost-right {
  border-radius: 1.5em 0 2em 0;
}
</style>
