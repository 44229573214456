<script src="./index.js"></script>
<style scoped>
.q-tab {
  /* justify-content: start !important; */
  padding-left: 0.75rem;
}
.q-tab__content .q-icon {
  margin-right: 0.5rem !important;
}
.tab {
  color: var(--q-0);
  background: var(--active-bg);
  border-radius: 0.75rem;
  margin-bottom: 0.5rem;
  font-size: 0.5rem;
}

/* last tab */
.tab:nth-of-type(6) {
  margin-bottom: 0;
}

.tab-active {
  color: var(--active-bg);
  background: var(--inactive-bg);
  pointer-events: none;
}

.shadowed-button {
  box-shadow: 3px 3px var(--q-6);
  transition-duration: 0.3s;
}
.shadowed-button:active {
  transition-duration: 0.2s;
  transform: translate(1px, 1px);
  box-shadow: 0 0 var(--q-6) !important;
}
.nice {
  user-select: none;
  background: linear-gradient(45deg, var(--q-h), var(--q-v));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.breadcrumbs-can-click {
  color: var(--q-v);
}
.breadcrumbs-can-click:hover {
  color: var(--q-v);
  cursor: pointer;
  /* transform: scale(1.05); */
}
.labl {
  transition-duration: 0.4s;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  pointer-events: none;
}
</style>

<template>
  <div class="column no-wrap">
    <q-ajax-bar ref="progressAjax" position="top" color="v" size="0.25rem" />

    <surfer
      v-show="false"
      ref="refSurfer"
      :blob="blobRef"
      progress-color="v"
      wave-color="e"
      @ready="$refs.refSurfer.playOrPause()"
    />

    <div class="col row no-wrap" style="width: 100%; height: 100%">
      <!-- LEFT NAV -->
      <div
        class="column justify-between q-ml-sm no-wrap"
        style="
          width: 8rem;
          max-height: 100%;
          overflow-y: auto;
          gap: 1.5rem;
          transition:
            opacity 0.3s ease,
            width 0.3s ease;
        "
        :style="left ? 'opacity: 1;' : 'width: 0; opacity: 0%'"
      >
        <q-tabs
          vertical
          v-model="tab"
          class="ubuntu"
          active-color="0"
          active-class="tab-active"
          indicator-color="transparent"
          style="height: 29.5rem; margin-top: -0rem"
          @update:model-value="setTab(tab)"
        >
          <q-tab
            class="tab"
            style="
              --inactive-bg: linear-gradient(
                90deg,
                var(--q-e) -25%,
                var(--q-2) 25%
              );
              --active-bg: var(--q-e);
            "
            name="lens"
            icon="person"
            :label="$t('-raw-discovery-tab-lens')"
          />
          <q-tab
            class="tab"
            style="
              --inactive-bg: linear-gradient(
                90deg,
                var(--q-h) -25%,
                var(--q-2) 25%
              );
              --active-bg: var(--q-h);
            "
            name="classrooms"
            icon="handshake"
            :label="$t('-raw-discovery-tab-classrooms')"
          />
          <q-tab
            class="tab"
            style="
              --inactive-bg: linear-gradient(
                90deg,
                var(--q-p) -25%,
                var(--q-2) 25%
              );
              --active-bg: var(--q-p);
            "
            name="channels"
            icon="school"
            :label="$t('-raw-discovery-tab-channels')"
          />
          <q-tab
            class="tab"
            style="
              --inactive-bg: linear-gradient(
                90deg,
                var(--q-w) -25%,
                var(--q-2) 25%
              );
              --active-bg: var(--q-w);
            "
            name="playlists"
            icon="playlist-play"
            :label="$t('-raw-discovery-tab-playlists')"
          />
          <q-tab
            class="tab"
            style="
              --inactive-bg: linear-gradient(
                90deg,
                var(--q-v) -25%,
                var(--q-2) 25%
              );
              --active-bg: var(--q-v);
            "
            name="challenges"
            icon="model_training"
            :label="$t('-raw-discovery-tab-challenges')"
          />
          <q-tab
            class="tab"
            style="
              --inactive-bg: linear-gradient(
                90deg,
                var(--q-n) -25%,
                var(--q-2) 25%
              );
              --active-bg: var(--q-n);
            "
            name="stories"
            icon="theater_comedy"
            :label="$t('-raw-discovery-tab-stories')"
          />
        </q-tabs>
        <FavChannels />
      </div>
      <div class="column no-wrap col q-ml-sm" style="gap: 0.75rem">
        <div class="row full-width items-center" style="min-height: 3rem">
          <q-btn
            flat
            rounded
            size="sm"
            icon="o_info"
            class="bg-1 q-pa-sm q-mx-xs"
            to="/settings/profile"
          >
            <q-tooltip class="bg-2 ubuntu text-8 z-max">
              <div class="column fit q-pa-sm">
                <div class="row no-wrap">
                  <div class="col-auto no-wrap q-pr-md">
                    {{ $t("-raw-lens-add-languages-v2") }}
                  </div>
                </div>
              </div>
            </q-tooltip>
          </q-btn>
          <q-btn
            flat
            rounded
            no-caps
            no-wrap
            size="0.7rem"
            class="q-ma-xs q-pa-sm text-e bg-1"
            style="border-radius: 1rem; min-width: 8rem"
            :label="$t('-raw-all-languages')"
            @click="clearLanguageFilter()"
          />
          <q-btn
            v-if="worklangs?.length > 0"
            flat
            rounded
            no-caps
            no-wrap
            size="0.7rem"
            class="q-ma-xs q-pa-sm text-e bg-1"
            style="border-radius: 1rem; min-width: 8rem"
            :label="$t('-raw-all-my-languages')"
            @click="setWorkLanguagesFilter()"
          />

          <div v-for="item in worklangs" :key="item.languageid + '_worklang'">
            <q-btn
              flat
              rounded
              no-caps
              no-wrap
              size="0.7rem"
              class="q-ma-xs q-pa-sm text-e bg-1"
              style="border-radius: 1rem; padding-left: 0.75rem !important"
              :label="
                (Array.isArray(languages) &&
                  languages.find((val) => val.ID === item.languageid)
                    ?.localName) ||
                item.languagename
              "
              @click="setLanguageFilter(item.languageid)"
              :style="
                selectedWorkLangIDs.includes(item.languageid)
                  ? 'background: var(--q-3)'
                  : ''
              "
            >
              <q-icon
                size="10"
                :name="
                  selectedWorkLangIDs.includes(item.languageid)
                    ? 'check_circle'
                    : 'o_circle'
                "
                color="e"
                class="q-ml-xs"
              />
            </q-btn>
          </div>
        </div>
        <q-tab-panels
          v-model="tab"
          class="bg-1"
          style="width: 100%; height: 100%; border-radius: 0.75rem"
        >
          <!-- style="height: 25% !important" -->
          <q-tab-panel
            name="lens"
            class="col-xs-10 col-sm-10 col-md-6 col-lg-4 col-xl-4 q-pa-sm"
          >
            <div
              v-if="featuredClassroomsData.size > 0"
              class="row justify-center q-pt-lg"
            >
              <span style="font-size: 1.5rem; font-weight: bold">
                {{ $t("-raw-discovery-featured-classrooms") }}
              </span>
            </div>

            <div class="row justify-center">
              <div v-if="featuredClassroomsData.size > 0">
                <q-carousel
                  v-model="slide"
                  navigation
                  infinite
                  :autoplay="autoplay"
                  arrows
                  transition-duration="500"
                  @mouseenter="autoplay = false"
                  @mouseleave="autoplay = 7000"
                  padding
                  height="100%"
                  :style="$q.screen.gt.sm ? 'width: 800px' : 'width: 500px'"
                  class="bg-1"
                >
                  <q-carousel-slide
                    v-for="(item, i) in featuredClassroomsData.values()"
                    :key="i"
                    :name="i + 1"
                    class="column no-wrap flex-center"
                  >
                    <featuredClassroom
                      :item="item"
                      :ref="'classroomcard_' + item.ID"
                      @choose="$router.push({ path: `/classroom/${item.ID}` })"
                    />
                  </q-carousel-slide>
                </q-carousel>
              </div>
            </div>

            <div
              v-if="recommendations.length > 0"
              class="row justify-center q-pt-sm q-pb-md"
            >
              <span style="font-size: 1.5rem; font-weight: bold">
                {{ $t("-raw-discovery-recommended-content") }}
              </span>
            </div>

            <div
              class="row items-center justify-center"
              v-for="rw in Math.ceil(recommendations.length / 3)"
              :key="rw + 'row_recommendation'"
            >
              <div
                v-for="cl in 3"
                :key="cl + 'column_recommendation' + rw"
                class="column col-12 col-md-6 col-lg col-xl-3 items-end justify-end q-pa-sm"
              >
                <template v-if="recommendations[(rw - 1) * 3 + cl - 1]">
                  <template
                    v-if="
                      recommendations[(rw - 1) * 3 + cl - 1].ObjectType ==
                      'challenge'
                    "
                  >
                    <challenge
                      :item="recommendations[(rw - 1) * 3 + cl - 1].Challenge"
                      :preview-enabled="true"
                      :ref="recommendations[(rw - 1) * 3 + cl - 1].Challenge.ID"
                      @preview="
                        startPreview(
                          recommendations[(rw - 1) * 3 + cl - 1].Challenge.ID,
                          recommendations[(rw - 1) * 3 + cl - 1].Challenge
                            .AudioSampleRefID,
                        )
                      "
                    />
                  </template>
                  <template
                    v-if="
                      recommendations[(rw - 1) * 3 + cl - 1].ObjectType ==
                      'story'
                    "
                  >
                    <story
                      :item="recommendations[(rw - 1) * 3 + cl - 1].Story"
                      :ref="recommendations[(rw - 1) * 3 + cl - 1].Story.ID"
                    />
                  </template>
                  <template
                    v-if="
                      recommendations[(rw - 1) * 3 + cl - 1].ObjectType ==
                      'playlist'
                    "
                  >
                    <playlist
                      :item="recommendations[(rw - 1) * 3 + cl - 1].Playlist"
                      :preview-enabled="true"
                      :ref="recommendations[(rw - 1) * 3 + cl - 1].Playlist.ID"
                      @preview="
                        startPreview(
                          recommendations[(rw - 1) * 3 + cl - 1].Playlist.ID,
                          recommendations[(rw - 1) * 3 + cl - 1].Playlist
                            ?.AudioSampleRefIDFirst,
                        )
                      "
                    />
                  </template>
                </template>
              </div>
            </div>

            <div class="row justify-center q-pt-lg q-pb-md">
              <span style="font-size: 1.5rem; font-weight: bold">
                {{ $t("-raw-discovery-statistics") }}
              </span>
            </div>

            <div class="q-py-sm row relative justify-center">
              <div
                class="bg-0 q-pb-md q-mb-md round-1"
                :style="$q.screen.gt.sm ? 'width: 600px' : 'width: 400px'"
              >
                <userWeekActivity />
              </div>
              <div class="row">
                <!-- <userLevel score="30" /> -->
                <template v-for="(obj, n1) in levels" :key="n1 + 'user_levels'">
                  <template v-if="obj.IsWorkLang === true">
                    <userLevel :score="obj.Value" :lang="obj.LanguageName" />
                  </template>
                </template>
              </div>
            </div>

            <!-- <div class="row items-center justify-center">
                <q-btn
                  :disable="recommendationLoaded === false"
                  flat
                  size="1rem"
                  class="bg-2 q-px-md q-py-sm"
                  style="border-radius: 1rem0.5"
                  :label="$t('-raw-load-more')"
                  @click="getUserRecommendations(true)"
                >
                </q-btn>
              </div> -->

            <!-- <div class="row">
                <div class="column col-6 items-end justify-end q-pa-sm">
                  <q-img
                    src="/content-types/channels.jpeg"
                    fit="contain"
                    style="
                      cursor: pointer;
                      height: calc(42.123vh - 8rem) !important;
                      width: calc(42.123vh - 8rem) !important;
                    "
                    @click="setTab('channels')"
                  />
                  <span
                    class="text-h5 text-center black-outline"
                    style="
                      cursor: pointer;
                      width: calc(42.123vh - 8rem) !important;
                      margin-top: -2.17152rem;
                      position: relative;
                    "
                    >{{ $t("-raw-tab-channels") }}</span
                  >
                </div>
                <div class="column col-6 items-start justify-start q-pa-sm">
                  <q-img
                    src="/content-types/playlists.jpeg"
                    fit="contain"
                    style="
                      cursor: pointer;
                      height: calc(42.123vh - 8rem) !important;
                      width: calc(42.123vh - 8rem) !important;
                    "
                    @click="setTab('playlists')"
                  />
                  <span
                    class="text-h5 text-center black-outline"
                    style="
                      cursor: pointer;
                      width: calc(42.123vh - 8rem) !important;
                      margin-top: -2.17152rem;
                      position: relative;
                    "
                    >{{ $t("-raw-tab-playlists") }}</span
                  >
                </div>
              </div>
              <div class="row items-start text-center justify-center">
                <div class="column col-6 items-end justify-end q-pa-sm">
                  <q-img
                    src="/content-types/stories.jpeg"
                    fit="contain"
                    style="
                      cursor: pointer;
                      height: calc(42.123vh - 8rem) !important;
                      width: calc(42.123vh - 8rem) !important;
                    "
                    @click="setTab('stories')"
                  />
                  <span
                    class="text-h5 text-center black-outline"
                    style="
                      cursor: pointer;
                      width: calc(42.123vh - 8rem) !important;
                      margin-top: -2.17152rem;
                      position: relative;
                    "
                    >{{ $t("-raw-tab-stories") }}</span
                  >
                </div>
                <div class="column col-6 items-start justify-start q-pa-sm">
                  <q-img
                    src="/content-types/challenges.jpeg"
                    fit="contain"
                    style="
                      cursor: pointer;
                      height: calc(42.123vh - 8rem) !important;
                      width: calc(42.123vh - 8rem) !important;
                    "
                    @click="setTab('challenges')"
                  />
                  <span
                    class="text-h5 text-center black-outline"
                    style="
                      cursor: pointer;
                      width: calc(42.123vh - 8rem) !important;
                      margin-top: -2.17152rem;
                      position: relative;
                    "
                    >{{ $t("-raw-tab-challenges") }}</span
                  >
                </div>
              </div> -->
          </q-tab-panel>

          <q-tab-panel name="classrooms" class="full-height">
            <q-btn
              style="height: 3rem; width: 3rem; position: absolute; right: 1rem"
              class="ubuntu q-mr-sm bg-v"
              :round="$q.screen.lt.lg"
              :rounded="$q.screen.gt.md"
              :style="
                $q.screen.gt.md ? 'height: 3rem;' : 'width: 2rem; height: 2rem;'
              "
              flat
              color="0"
              @click="
                classroomJoinByCode = null;
                classroomJoinByCodeDialog = true;
              "
            >
              <q-icon name="vpn_key" />
              <q-tooltip
                anchor="top middle"
                self="bottom middle"
                class="row mytooltip round-both ubuntu bg-0 text-v items-center justify-center q-px-md"
                transition-show="jump-up"
                transition-hide="jump-down"
              >
                {{ $t("-raw-discovery-classroom-auto-join-by-code") }}
              </q-tooltip>
            </q-btn>

            <div
              v-show="
                !isSearching &&
                itemsWorklangsWithoutEmpty('classrooms').length === 0
              "
              class="col-6 column items-center justify-center ubuntu"
            >
              <notfound :tab="tab" @newTab="setTab" />
            </div>
            <div
              v-for="(langItems, l) in itemsWorklangsWithoutEmpty('classrooms')"
              :key="l + 'classrm_worklanguages_list'"
              class="col-xs-10 col-sm-10 col-md-6 col-lg-4 col-xl-4 q-pa-sm"
            >
              <span style="font-size: 1.5rem; font-weight: bold">
                {{ h.translateLanguage(langItems.languageid) }}
              </span>

              <div
                v-if="foundItems.classrooms.get(langItems.languageid)"
                @load="getItemsByLangId('classrooms', langItems.languageid)"
                class="row"
                style="margin: 0.5rem -0.5rem 0.5rem -0.5rem"
              >
                <div
                  v-for="(item, n) in arrayDeduplicate(
                    foundItems.classrooms.get(langItems.languageid),
                  )"
                  :key="n + langItems.languageid + 'classrooms_list'"
                  class="home-item col-xs-10 col-sm-10 col-md-6 col-lg-4 col-xl-4 q-pa-sm"
                >
                  <classroom
                    :item="item"
                    :ref="'classroomcard_' + item.ID"
                    @choose="$router.push({ path: `/classroom/${item.ID}` })"
                  />
                </div>
              </div>
              <div
                class="row q-px-md q-pb-md column items-center justify-center"
              >
                <div class="col">
                  <q-btn
                    v-if="
                      !noMoreResultsToLoad.classrooms.has(langItems.languageid)
                    "
                    :disable="isSearching"
                    flat
                    size="1rem"
                    class="bg-2 q-px-md q-py-sm"
                    style="border-radius: 0.5rem"
                    :label="$t('-raw-load-more')"
                    @click="
                      getItemsByLangId('classrooms', langItems.languageid, 9)
                    "
                  />
                </div>
              </div>
              <q-separator color="3" style="margin: 0 -1.5rem" />
            </div>
            <!-- empty div to avoid overlapping last items and "load more" button with bottom border-->
            <div class="col" style="height: 10rem !important" />
          </q-tab-panel>

          <q-tab-panel name="challenges" class="full-height">
            <div
              v-show="
                !isSearching &&
                itemsWorklangsWithoutEmpty('challenges').length === 0
              "
              class="col-6 column items-center justify-center ubuntu"
            >
              <notfound :tab="tab" @newTab="setTab" />
            </div>
            <!-- <transition-group v-else appear name="home" tag="div" class="masonry"> -->
            <div
              v-for="(langItems, l) in itemsWorklangsWithoutEmpty('challenges')"
              :key="l + 'chal_worklanguages_list'"
              class="col-xs-10 col-sm-10 col-md-6 col-lg-4 col-xl-4 q-pa-sm"
            >
              <span style="font-size: 1.5rem; font-weight: bold">
                {{ h.translateLanguage(langItems.languageid) }}
              </span>

              <div
                v-if="foundItems.challenges.get(langItems.languageid)"
                @load="getItemsByLangId('challenges', langItems.languageid)"
                class="row"
                style="margin: 0.5rem -0.5rem 0.5rem -0.5rem"
              >
                <div
                  v-for="(item, n) in arrayDeduplicate(
                    foundItems.challenges.get(langItems.languageid),
                  )"
                  :key="n + langItems.languageid + 'challenges_list'"
                  class="home-item col-xs-10 col-sm-10 col-md-6 col-lg-4 col-xl-4 q-pa-sm"
                >
                  <challenge
                    :item="item"
                    :preview-enabled="true"
                    :ref="item.ID"
                    @preview="startPreview(item.ID, item.AudioSampleRefID)"
                  />
                </div>
              </div>
              <div
                class="row q-px-md q-pb-md column items-center justify-center"
              >
                <div class="col">
                  <q-btn
                    v-if="
                      !noMoreResultsToLoad.challenges.has(langItems.languageid)
                    "
                    :disable="isSearching"
                    flat
                    size="1rem"
                    class="bg-2 q-px-md q-py-sm"
                    style="border-radius: 0.5rem"
                    :label="$t('-raw-load-more')"
                    @click="
                      getItemsByLangId('challenges', langItems.languageid, 9)
                    "
                  />
                </div>
              </div>
              <q-separator color="3" style="margin: 0 -1.5rem" />
            </div>
            <!-- empty div to avoid overlapping last items and "load more" button with bottom border-->
            <div class="col" style="height: 10rem !important" />
            <!-- </transition-group> -->
          </q-tab-panel>

          <q-tab-panel name="channels" class="full-height">
            <div
              v-show="
                !isSearching &&
                itemsWorklangsWithoutEmpty('channels').length === 0
              "
              class="col-6 column items-center justify-center ubuntu"
            >
              <notfound :tab="tab" @newTab="setTab" />
            </div>
            <!-- <transition-group v-else appear name="home" tag="div" class="masonry"> -->
            <div
              v-for="(langItems, l) in itemsWorklangsWithoutEmpty('channels')"
              :key="l + 'chan_worklanguages_list'"
              class="col-xs-10 col-sm-10 col-md-6 col-lg-4 col-xl-4 q-pa-sm"
            >
              <span style="font-size: 1.5rem; font-weight: bold">
                {{ h.translateLanguage(langItems.languageid) }}
              </span>
              <div
                v-if="foundItems.channels.get(langItems.languageid)"
                @load="getItemsByLangId('channels', langItems.languageid)"
                class="row"
                style="margin: 0.5rem -0.5rem 0.5rem -0.5rem"
              >
                <div
                  v-for="(item, n) in arrayDeduplicate(
                    foundItems.channels.get(langItems.languageid),
                  )"
                  :key="n + langItems.languageid + 'channels_list'"
                  class="home-item col-xs-10 col-sm-10 col-md-6 col-lg-4 col-xl-4 q-pa-sm"
                  @click="$router.push({ path: `/c/${encodeURI(item.Name)}` })"
                >
                  <channel :item="item" />
                </div>
              </div>
              <div
                class="row q-px-md q-pb-md column items-center justify-center"
              >
                <div class="col">
                  <q-btn
                    v-if="
                      !noMoreResultsToLoad.channels.has(langItems.languageid)
                    "
                    :disable="isSearching"
                    flat
                    size="1rem"
                    class="bg-2 q-px-md q-py-sm"
                    style="border-radius: 0.5rem"
                    :label="$t('-raw-load-more')"
                    @click="
                      getItemsByLangId('channels', langItems.languageid, 9)
                    "
                  />
                </div>
              </div>
              <q-separator color="3" style="margin: 0 -1.5rem" />
            </div>
            <!-- </transition-group> -->
            <!-- empty div to avoid overlapping last items and "load more" button with bottom border-->
            <div class="col" style="height: 10rem !important" />
          </q-tab-panel>

          <q-tab-panel name="playlists" class="full-height">
            <div
              v-show="
                !isSearching &&
                itemsWorklangsWithoutEmpty('playlists').length === 0
              "
              class="col-6 column items-center justify-center ubuntu"
            >
              <notfound :tab="tab" @newTab="setTab" />
            </div>
            <div
              v-for="(langItems, l) in itemsWorklangsWithoutEmpty('playlists')"
              :key="l + 'plsts_worklanguages_list'"
              class="col-xs-10 col-sm-10 col-md-6 col-lg-4 col-xl-4 q-pa-sm"
            >
              <span style="font-size: 1.5rem; font-weight: bold">
                {{ h.translateLanguage(langItems.languageid) }}</span
              >
              <div
                v-if="foundItems.playlists.get(langItems.languageid)"
                @load="getItemsByLangId('playlists', langItems.languageid)"
                class="row"
                style="margin: 0.5rem -0.5rem 0.5rem -0.5rem"
              >
                <!-- NOTE - c8e5e672: path: `/playlist/${encodeURI(item.ID)}/0`-->
                <div
                  v-for="(item, n) in arrayDeduplicate(
                    foundItems.playlists.get(langItems.languageid),
                  )"
                  :key="n + langItems.languageid + 'channels_list'"
                  class="home-item col-xs-10 col-sm-10 col-md-6 col-lg-4 col-xl-4 q-pa-sm"
                  @click="
                    $router.push({ path: `/playlist/${encodeURI(item.ID)}` })
                  "
                >
                  <playlist
                    :item="item"
                    :preview-enabled="true"
                    :ref="item.ID"
                    @preview="
                      startPreview(item.ID, item?.AudioSampleRefIDFirst)
                    "
                  />
                </div>
              </div>
              <div
                class="row q-px-md q-pb-md column items-center justify-center"
              >
                <div class="col">
                  <q-btn
                    v-if="
                      !noMoreResultsToLoad.playlists.has(langItems.languageid)
                    "
                    :disable="isSearching"
                    flat
                    size="1rem"
                    class="bg-2 q-px-md q-py-sm"
                    style="border-radius: 0.5rem"
                    :label="$t('-raw-load-more')"
                    @click="
                      getItemsByLangId('playlists', langItems.languageid, 9)
                    "
                  />
                </div>
              </div>
              <q-separator color="3" style="margin: 0 -1.5rem" />
            </div>
            <!-- empty div to avoid overlapping last items and "load more" button with bottom border-->
            <div class="col" style="height: 10rem !important" />
          </q-tab-panel>

          <q-tab-panel name="stories" class="full-height">
            <div
              v-show="
                !isSearching &&
                itemsWorklangsWithoutEmpty('stories').length === 0
              "
              class="col-6 column items-center justify-center ubuntu"
            >
              <notfound :tab="tab" @newTab="setTab" />
            </div>
            <div
              v-for="(langItems, l) in itemsWorklangsWithoutEmpty('stories')"
              :key="l + 'strs_worklanguages_list'"
              class="q-pa-sm"
            >
              <span style="font-size: 1.5rem; font-weight: bold">
                {{ h.translateLanguage(langItems.languageid) }}
              </span>
              <div
                v-if="foundItems.stories.get(langItems.languageid)"
                @load="getItemsByLangId('stories', langItems.languageid)"
                class="row"
                style="margin: 0.5rem -0.5rem 0.5rem -0.5rem"
              >
                <div
                  v-for="(item, n) in arrayDeduplicate(
                    foundItems.stories.get(langItems.languageid),
                  )"
                  :key="n + langItems.languageid + 'stories_list'"
                  class="home-item q-pa-sm"
                >
                  <story
                    :item="item"
                    :ref="item.ID"
                    :showdelete="true"
                    @deleteStory="storyDeleted"
                  />
                </div>
              </div>
              <div
                class="row q-px-md q-pb-md column items-center justify-center"
              >
                <div class="col">
                  <q-btn
                    v-if="
                      !noMoreResultsToLoad.stories.has(langItems.languageid)
                    "
                    :disable="isSearching"
                    flat
                    size="1rem"
                    class="bg-2 q-px-md q-py-sm"
                    style="border-radius: 0.5rem"
                    :label="$t('-raw-load-more')"
                    @click="
                      getItemsByLangId('stories', langItems.languageid, 9)
                    "
                  />
                </div>
              </div>
              <q-separator color="3" style="margin: 0 -1.5rem" />
            </div>
            <!-- empty div to avoid overlapping last items and "load more" button with bottom border-->
            <div class="col" style="height: 10rem !important" />
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>

    <!-- SearchBar -->
    <div
      v-if="window.innerWidth > 1023 || searchbar"
      class="row full-width justify-center absolute z-max"
      :class="window.innerWidth > 1023 ? '' : 'bg-0'"
      style="
        top: -4rem;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
      "
    >
      <Search
        :searching="isSearching"
        :query-params="queryParams"
        :query="query"
        @set-query="(q) => setQuery(q)"
      />
    </div>

    <!-- SearchBar Toggle -->
    <q-btn
      v-if="$q.screen.lt.md"
      style="height: 2rem; width: 2rem; top: -4rem; right: 4.25rem"
      class="absolute z-max"
      :color="searchbar ? 'n' : '8'"
      :icon="searchbar ? 'close' : 'search'"
      @click="(searchbar = !searchbar), setQuery('')"
      flat
      round
    />

    <!-- Welcome Video -->
    <help-video
      v-show="false"
      :autoshow="false"
      style="position: abosolute; left: -10rem; top: 0rem"
    />

    <!-- can't set here class="z-max" because need show error as Notify -->
    <q-dialog
      v-model="classroomJoinByCodeDialog"
      @show="afterShowDialogEnterClassroomCode"
    >
      <q-card
        style="
          box-shadow: none;
          border-radius: 1rem;
          border: 1px solid var(--q-3);
        "
        class="bg-1"
      >
        <q-card-section class="row">
          <span style="color: var(--q-e); font-size: 1.5rem; font-weight: 1000">
            {{ $t("-raw-discovery-classroom-auto-join-by-code") }}
          </span>
        </q-card-section>
        <q-card-section class="row q-pt-none q-pb-sm">
          <q-input
            ref="inputCodeJoinClassroom"
            type="text"
            borderless
            autogrow
            style="border-radius: 0.75rem"
            v-model="classroomJoinByCode"
            :label="$t('-raw-classroom-auto-join-code')"
            class="bg-0 q-px-md full-width"
            input-class="ubuntu bg-transparent"
            input-style="color: var(--q-a); max-height: 2rem; min-height: 2rem; min-width: 25rem;"
          />
        </q-card-section>

        <q-card-actions align="right" class="q-px-md q-pb-md">
          <q-btn
            flat
            no-caps
            :label="$t('-raw-general-cancel')"
            class="bg-2 q-mr-sm"
            color="8"
            style="border-radius: 0.5rem"
            :loading="classroomJoinByCodeLoading"
            @click="classroomJoinByCodeDialog = false"
          />
          <q-btn
            flat
            no-caps
            :label="$t('-raw-general-enter')"
            class="bg-v text-0 q-px-lg"
            style="border-radius: 0.5rem"
            :loading="classroomJoinByCodeLoading"
            :disabled="
              classroomJoinByCode == null || classroomJoinByCode.length == 0
            "
            @click="enterClassroomByCode"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
