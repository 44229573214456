<template>
  <q-input
    v-model="queryModel"
    borderless
    type="text"
    :placeholder="$t('-raw-main-search')"
    debounce="500"
    q-input
    input-style="margin-top: -.5rem !important;"
    style="
      height: 3rem;
      font-size: 1.25rem;
      border-radius: 0.75rem;
      pointer-events: auto;
    "
    ref="search"
    class="bg-1 col-xl-4 col-lg-4 col-md-6 col-sm-7 col-xs-10"
  >
    <!-- :class="{ 'queried': query?.length > 3 }" -->
    <!-- by name : author : tag : lang -->
    <template #prepend>
      <q-icon
        name="search"
        style="scale: 0.95; margin-left: 0.75rem; margin-top: -0.5rem"
      />
    </template>
    <template #append>
      <div class="row justify-end q-gutter-x-sm" style="margin-top: -0.5rem">
        <!-- Searching Loading -->
        <q-spinner-grid
          v-if="searching && query?.length > 0"
          color="v"
          class="q-my-auto q-mr-md"
          size="16px"
        />
        <!-- Clear Query -->
        <q-btn
          v-if="!searching && query?.length > 0 && $q.screen.gt.sm"
          style="height: 2rem; width: 2.25rem; border-radius: 0.5rem"
          color="n"
          icon="close"
          @click="(queryModel = ''), (showForm = false)"
          flat
        />
        <!-- Search params button-->
        <q-btn
          style="
            height: 2rem;
            width: 2.25rem;
            border-radius: 0.5rem;
            margin-right: 0.5rem;
          "
          color="v"
          icon="tune"
          flat
          @click="toggleShowForm"
        >
        </q-btn>
      </div>
    </template>
    <!-- Search params menu-->
    <q-menu
      fit
      no-parent-event
      v-model="showForm"
      transition-show="jump-up"
      transition-hide="jump-down"
      class="bg-1"
      style="
        border-radius: 0.8rem;
        border: 1px solid var(--q-3);
        box-shadow: none !important;
      "
    >
      <form @submit.prevent="">
        <div class="column no-wrap q-pa-md">
          <div class="row no-wrap">
            <div class="col-4 q-my-auto ubuntu q-pr-sm">
              {{ $t("-raw-author") }}:
            </div>
            <q-input
              v-model="author"
              borderless
              style="height: 3rem; border-radius: 0.5rem"
              input-style="margin-top: -.5rem;"
              input-class="text-e"
              class="col bg-3 q-px-md"
              :rules="[
                (author) =>
                  !(author || '').match(/\s/g) || $t('-raw-check-no-spaces'),
              ]"
            />
          </div>

          <div class="row no-wrap q-pt-md">
            <div class="col-4 q-my-auto ubuntu q-pr-sm">
              {{ $t("-raw-period") }}:
            </div>
            <q-select
              v-model="period"
              borderless
              style="height: 3rem; border-radius: 0.5rem"
              input-style="margin-top: -0.5rem;"
              input-class="text-e"
              class="col bg-3 q-px-md"
              :options="periodOptions"
            />
          </div>

          <div v-show="customPeriod" class="row no-wrap q-pt-md">
            <div class="col-4 q-my-auto ubuntu q-pr-sm" />
            <q-input
              v-model="queryPeriodStr"
              borderless
              style="height: 3rem; border-radius: 0.5rem"
              input-style="margin-top: -.5rem;"
              input-class="text-e"
              class="col bg-3 q-px-md"
              readonly
            >
              <template #append>
                <q-icon
                  name="event"
                  class="cursor-pointer"
                  style="margin-top: -0.5rem"
                >
                  <q-popup-proxy
                    ref="qDateProxy"
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="queryPeriod" class="z-max" range today-btn>
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="v" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="row no-wrap q-pt-md">
            <div
              class="col-4 q-my-auto ubuntu q-pr-sm no-wrap row justify-between"
            >
              <div>{{ $t("-raw-lens-level") }}:</div>
              <q-icon flat rounded size="sm" name="o_info" color="3">
                <q-tooltip class="bg-2 ubuntu text-8 z-max">
                  {{ $t("-raw-level-applies-to-stories-and-challenges") }}
                </q-tooltip>
              </q-icon>
            </div>
            <q-select
              v-model="level"
              borderless
              style="height: 3rem; border-radius: 0.5rem"
              input-style="margin-top: -.5rem;"
              input-class="text-e"
              class="col bg-3 q-px-md"
              :options="levelOptions"
            />
          </div>
          <div v-show="customLevel" class="row no-wrap q-pt-md">
            <div class="col-4 q-my-auto ubuntu q-pr-sm" />
            <div
              class="col bg-3 q-px-md q-my-auto"
              style="height: 3rem; border-radius: 0.5rem"
            >
              <q-range
                v-model="queryLevel"
                :min="0"
                :max="100"
                :left-label-value="`${queryLevel.min || 0}(${$h.levelToCEFR(
                  queryLevel.min || 0,
                )})`"
                :right-label-value="`${queryLevel.max || 100}(${$h.levelToCEFR(
                  queryLevel.max || 100,
                )})`"
                label-always
                color="v"
              />
            </div>
          </div>
          <div class="row no-wrap q-pt-md">
            <div
              class="col-4 q-my-auto ubuntu q-pr-sm no-wrap row justify-between"
            >
              {{ $t("-raw-story-mode") }}
            </div>
            <q-select
              v-model="storyMode"
              borderless
              style="height: 3rem; border-radius: 0.5rem"
              input-style="margin-top: -.5rem;"
              input-class="text-e"
              class="col bg-3 q-px-md"
              emit-value
              map-options
              :options="storyModeOptions"
            />
          </div>
          <div class="row no-wrap q-pt-md">
            <div
              class="col q-my-auto ubuntu q-pr-sm no-wrap row justify-between"
            >
              <q-checkbox
                left-label
                checked-icon="task_alt"
                v-model="sourceLanguages"
              >
                <span class="q-pr-sm">{{
                  $t("-raw-search-my-source-languages")
                }}</span>
              </q-checkbox>
            </div>
          </div>

          <div class="row no-wrap q-pt-md" v-if="isSystem">
            <div
              class="col q-my-auto ubuntu q-pr-sm no-wrap row justify-between"
            >
              <q-checkbox
                left-label
                checked-icon="task_alt"
                v-model="ignoreSystemRole"
              >
                <span class="q-pr-sm">{{
                  $t("-raw-search-ignore-system-role")
                }}</span>
              </q-checkbox>
            </div>
          </div>

          <div
            class="row justify-end no-wrap q-pt-md"
            style="margin-top: -3.5rem"
          >
            <q-btn
              class="text-e bg-3 q-mr-md"
              style="border-radius: 0.5rem"
              no-caps
              flat
              :label="$t('-raw-reset')"
              @click="resetForm"
            />
            <q-btn
              class="text-0"
              style="border-radius: 0.5rem; background: var(--q-v)"
              no-caps
              flat
              type="submit"
              :disabled="(author || '').match(/\s/g)"
              @click="applyForm"
              :label="$t('-raw-main-search')"
            />
          </div>
        </div>
      </form>
    </q-menu>
  </q-input>
</template>

<script>
import { i18n } from "src/boot/i18n.js";
import { date } from "quasar";
import { mapGetters } from "vuex";
import Lib from "../h/helpers";

export default {
  props: {
    query: {
      type: String,
      default: "",
    },
    queryParams: {
      type: Object,
    },
    searching: {
      type: Boolean,
    },
  },
  emits: ["setQuery"],
  data: () => ({
    showForm: false,
    period: i18n.global.t("-raw-period-whenever"),
    customPeriod: false,
    periodOptions: [
      i18n.global.t("-raw-period-whenever"),
      i18n.global.t("-raw-period-today"),
      i18n.global.t("-raw-period-yesterday"),
      i18n.global.t("-raw-period-last-7-days"),
      i18n.global.t("-raw-period-last-30-days"),
      i18n.global.t("-raw-period-custom"),
    ],
    queryPeriod: null,
    level: i18n.global.t("-raw-lens-level-any"),
    customLevel: false,
    levelOptions: [
      i18n.global.t("-raw-lens-level-any"),
      i18n.global.t("-raw-lens-level-a1-b"),
      i18n.global.t("-raw-lens-level-a2-e"),
      i18n.global.t("-raw-lens-level-b1-i"),
      i18n.global.t("-raw-lens-level-b2-ui"),
      i18n.global.t("-raw-lens-level-c1-a"),
      i18n.global.t("-raw-lens-level-c2-p"),
      i18n.global.t("-raw-lens-level-custom"),
    ],
    queryLevel: {
      min: null,
      max: null,
    },
    author: null,
    storyMode: null,
    storyModeOptions: [
      { label: i18n.global.t("-raw-story-pass-any-mode"), value: null },
      { label: i18n.global.t("-raw-story-pass-play-by-roles-mode"), value: 0 },
      { label: i18n.global.t("-raw-story-pass-certification-mode"), value: 2 },
    ],
    sourceLanguages: false,
    ignoreSystemRole: false,
  }),
  computed: {
    queryModel: {
      get() {
        return this.$props.query;
      },
      set(payload) {
        this.$emit("setQuery", payload);
      },
    },
    queryPeriodStr() {
      if (this.queryPeriod?.from) {
        return this.queryPeriod.from + " - " + this.queryPeriod.to;
      } else {
        return this.queryPeriod;
      }
    },
    isSystem() {
      return !!Lib.getItem("VOC_USER_ROLE_IS_SYSTEM");
    },
  },
  methods: {
    toggleShowForm() {
      this.showForm = !this.showForm;
      gtag("event", "toggleSearchFilterForm", {
        event_category: "Search",
        event_label: "Search filter " + this.$route.meta.title + " toggled",
      });
      if (this.showForm) {
        // fill form fields
        this.author = this.queryParams.author;

        if (this.queryParams.after || this.queryParams.before) {
          this.period = i18n.global.t("-raw-period-custom");
          if (!this.queryParams.before) {
            this.queryPeriod = this.queryParams.after;
          } else {
            this.queryPeriod = {
              from: this.queryParams.after,
              to: this.queryParams.before,
            };
          }
        } else {
          this.period = i18n.global.t("-raw-period-whenever");
        }

        if (this.queryParams.levelMin || this.queryParams.levelMax) {
          this.level = i18n.global.t("-raw-lens-level-custom");
          this.queryLevel.min = this.queryParams.levelMin;
          this.queryLevel.max = this.queryParams.levelMax;
        } else {
          this.level = i18n.global.t("-raw-lens-level-any");
        }
        if (parseInt(this.queryParams.modeType) >= 0) {
          this.storyMode = parseInt(this.queryParams.modeType);
        } else {
          this.storyMode = null;
        }
        this.sourceLanguages = !!this.queryParams.sourceLanguages;
        this.ignoreSystemRole = !!this.queryParams.ignoreSystemRole;
      }
    },
    removeQueryParam(query, param) {
      if (!query || query === "") {
        return "";
      }
      let result = query;
      query.split(" ").forEach((word) => {
        if (word.substring(0, param.length + 1) === param + ":") {
          result = result.replace(word, "");
        }
      });
      return result;
    },
    setQueryParam(query, param, value) {
      if (!value) return query;
      return this.removeQueryParam(query, param) + " " + param + ":" + value;
    },
    resetForm() {
      this.author = null;
      this.period = i18n.global.t("-raw-period-whenever");
      this.level = i18n.global.t("-raw-lens-level-any");
      this.storyMode = null;
      this.sourceLanguages = false;
      this.ignoreSystemRole = false;
    },
    applyForm() {
      let resultQuery = this.query;
      for (const key of Object.keys(this.queryParams)) {
        if (key !== "q") {
          resultQuery = this.removeQueryParam(resultQuery, key);
        }
      }
      if (this.author) {
        gtag("event", "searchByAuthor", {
          event_category: "Search",
          event_label: "Search filter by author " + this.author,
        });
        resultQuery = this.setQueryParam(resultQuery, "author", this.author);
      }

      if (this.queryPeriod && typeof this.queryPeriod === "string") {
        gtag("event", "searchByPeriodDate", {
          event_category: "Search",
          event_label: "Search filter by period date " + this.queryPeriod,
        });

        resultQuery = this.setQueryParam(
          resultQuery,
          "after",
          this.queryPeriod,
        );
      }

      if (this.queryPeriod && typeof this.queryPeriod === "object") {
        gtag("event", "searchByPeriodRange", {
          event_category: "Search",
          event_label: "Search filter by period range " + this.queryPeriod,
        });

        resultQuery = this.setQueryParam(
          resultQuery,
          "after",
          this.queryPeriod.from,
        );
        resultQuery = this.setQueryParam(
          resultQuery,
          "before",
          this.queryPeriod.to,
        );
      }

      if (this.queryLevel) {
        gtag("event", "searchByLevel", {
          event_category: "Search",
          event_label: "Search filter by level " + this.queryLevel,
        });

        resultQuery = this.setQueryParam(
          resultQuery,
          "levelMin",
          this.queryLevel.min,
        );
        resultQuery = this.setQueryParam(
          resultQuery,
          "levelMax",
          this.queryLevel.max,
        );
      }
      if (parseInt(this.storyMode) >= 0) {
        gtag("event", "searchByStoryMode", {
          event_category: "Search",
          event_label: "Search filter by mode " + this.storyMode,
        });

        resultQuery = this.setQueryParam(
          resultQuery,
          "modeType",
          this.storyMode.toString(),
        );
      }

      if (this.sourceLanguages) {
        gtag("event", "searchSourceLanguages", {
          event_category: "Search",
          event_label:
            "Search filter with sourceLanguages " + this.sourceLanguages,
        });

        resultQuery = this.setQueryParam(
          resultQuery,
          "sourceLanguages",
          "true",
        );
      }

      if (this.ignoreSystemRole) {
        gtag("event", "searchIgnoreSystemRole", {
          event_category: "Search",
          event_label:
            "Search filter with IgnoreSystemRole " + this.ignoreSystemRole,
        });

        resultQuery = this.setQueryParam(
          resultQuery,
          "ignoreSystemRole",
          "true",
        );
      }

      this.queryModel = resultQuery;
      this.showForm = false;
    },
  },
  watch: {
    period: {
      handler(newVal) {
        const today = new Date();
        switch (newVal) {
          case i18n.global.t("-raw-period-whenever"):
            this.queryPeriod = null;
            this.customPeriod = false;
            break;
          case i18n.global.t("-raw-period-today"):
            this.queryPeriod = today
              .toISOString()
              .slice(0, 10)
              .replace(/-/g, "/");
            this.customPeriod = false;
            break;
          case i18n.global.t("-raw-period-yesterday"):
            this.queryPeriod = date
              .addToDate(today, { days: -1 })
              .toISOString()
              .slice(0, 10)
              .replace(/-/g, "/");
            this.customPeriod = false;
            break;
          case i18n.global.t("-raw-period-last-7-days"):
            this.queryPeriod = date
              .addToDate(today, { days: -7 })
              .toISOString()
              .slice(0, 10)
              .replace(/-/g, "/");
            this.customPeriod = false;
            break;
          case i18n.global.t("-raw-period-last-30-days"):
            this.queryPeriod = date
              .addToDate(today, { days: -30 })
              .toISOString()
              .slice(0, 10)
              .replace(/-/g, "/");
            this.customPeriod = false;
            break;
          case i18n.global.t("-raw-period-custom"):
            this.customPeriod = true;
            break;
        }
      },
      immediate: false,
      deep: false,
    },
    level: {
      handler(newVal) {
        switch (newVal) {
          case i18n.global.t("-raw-lens-level-any"):
            this.queryLevel.min = null;
            this.queryLevel.max = null;
            this.customLevel = false;
            break;
          case i18n.global.t("-raw-lens-level-a1-b"):
            this.queryLevel.min = null;
            this.queryLevel.max = 30;
            this.customLevel = false;
            break;
          case i18n.global.t("-raw-lens-level-a2-e"):
            this.queryLevel.min = 31;
            this.queryLevel.max = 40;
            this.customLevel = false;
            break;
          case i18n.global.t("-raw-lens-level-b1-i"):
            this.queryLevel.min = 41;
            this.queryLevel.max = 50;
            this.customLevel = false;
            break;
          case i18n.global.t("-raw-lens-level-b2-ui"):
            this.queryLevel.min = 51;
            this.queryLevel.max = 60;
            this.customLevel = false;
            break;
          case i18n.global.t("-raw-lens-level-c1-a"):
            this.queryLevel.min = 61;
            this.queryLevel.max = 70;
            this.customLevel = false;
            break;
          case i18n.global.t("-raw-lens-level-c2-p"):
            this.queryLevel.min = 71;
            this.queryLevel.max = null;
            this.customLevel = false;
            break;
          case i18n.global.t("-raw-lens-level-custom"):
            this.customLevel = true;
            break;
        }
      },
      immediate: false,
      deep: false,
    },
  },
};
</script>

<style scoped>
/*.queried {
  transition-duration: 0.2s;
}
.queried *{
  cursor: default !important;
  pointer-events: none !important;
  opacity: 0.75;
}
.queried *:active{
  opacity: 0.75;
  pointer-events: none !important;
}
.queried *:hover{
  opacity: 0.75;
} */
</style>
