export default {
  state: {
    editLesson: false,
    lessonName: null,
  },
  mutations: {
    setLessonName(state, name) {
      state.lessonName = name;
    },
    setEditLesson(state, bool) {
      state.editLesson = bool;
    },
    resetLessonStudio(state) {
      state.lessonName = null;
    },
  },
  getters: {
    editLesson: (state) => state.editLesson,
    lessonName: (state) => state.lessonName,
  },
};
