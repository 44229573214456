<template>
  <div
    class="q-pa-sm full-width column no-wrap bg-0 challengecard"
    style="
      border-radius: 1rem;
      display: inline-block;
      position: relative;
      cursor: pointer;
    "
    @click.stop="
      isInEditingMode
        ? $emit('toggleItem', item.ID)
        : openChallenge(item, false)
    "
  >
    <!-- purple challenge icon top right -->
    <q-icon
      color="v"
      size="2rem"
      class="bg-0 round-1"
      style="position: absolute; top: 0.5rem; right: 0.5rem; z-index: 1"
      name="model_training"
    />
    <!-- purple public icon top right -->
    <q-icon
      v-if="!pplanpasselementid"
      color="v"
      size="1rem"
      class="bg-0 round-1 q-pa-xs"
      style="position: absolute; top: 2.5rem; right: 0.5rem"
      :name="
        item.Privacy === 'public'
          ? 'public'
          : item.Privacy === 'unlisted'
            ? 'link'
            : 'public_off'
      "
    >
      <q-tooltip
        anchor="top left"
        self="bottom left"
        class="bg-0 text-8 round-both"
        style="border: 1px solid var(--q-2)"
        transition-show="jump-up"
        transition-hide="jump-down"
      >
        <b>{{
          item.Privacy === "public"
            ? $t("-raw-challenge-privacy-public")
            : item.Privacy === "unlisted"
              ? $t("-raw-challenge-privacy-unlisted")
              : $t("-raw-challenge-privacy-private")
        }}</b>
      </q-tooltip>
    </q-icon>
    <!-- rating stars bottom right -->
    <div
      v-if="!pplanpasselementid"
      style="
        font-weight: bold;
        position: absolute;
        max-width: fit-content;
        border-radius: 0.75rem;
        font-size: 0.9rem;
        right: 1rem;
        bottom: 3.5rem;
        z-index: 1;
      "
    >
      <div v-if="item.Rating">
        <q-icon name="star" />
        {{ item.Rating.toFixed(1)
        }}<span style="font-size: 0.6rem">/{{ item.CountRating }}</span>
        <q-tooltip
          anchor="top middle"
          self="bottom middle"
          class="bg-2 text-8 round-both"
          transition-show="jump-up"
          transition-hide="jump-down"
        >
          <b
            >{{ $t("-raw-challenge-rated-by") }} {{ item.CountRating }}
            {{ $t("-raw-challenge-rated-by-users") }}</b
          >
        </q-tooltip>
      </div>
    </div>
    <!-- green play button bottom right -->
    <q-icon
      v-show="
        !opening && !isInEditingMode && previewEnabled && item.AudioSampleRefID
      "
      color="p"
      size="2rem"
      name="volume_up"
      class="bg-0 round-1 q-pa-xs"
      style="
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        border: 1px solid var(--q-2);
      "
      @click.stop="!previewLoading && $emit('preview')"
    >
      <q-tooltip
        anchor="top middle"
        self="bottom middle"
        class="bg-2 text-8 round-both"
        transition-show="jump-up"
        transition-hide="jump-down"
      >
        <b>{{
          $t("-raw-preview") !== "-raw-preview" ? $t("-raw-preview") : "Preview"
        }}</b>
      </q-tooltip>
      <q-inner-loading :showing="previewLoading" class="bg-0" size="1.5rem" />
    </q-icon>
    <!-- image and language -->
    <div class="row no-wrap" style="max-width: 90%">
      <div
        class="round-1 bg-2"
        :style="{
          width: '10rem',
          'min-width': '10rem',
          position: 'relative',
          border: '1px solid var(--q-2)',
          'background-image': 'url(' + handleChallengePicture(item.ID) + ')',
          'background-repeat': 'no-repeat',
          'background-position': 'center',
          'background-size': 'contain',
          'aspect-ratio': 1 / 1,
        }"
      >
        <q-icon
          name="model_training"
          class="text-0"
          size="6.9rem"
          style="
            top: 50%;
            left: 50%;
            opacity: 0.5;
            position: absolute;
            transform: translate(-50%, -50%);
          "
        />
      </div>
      <div class="column q-pl-md" v-if="!pplanpasselementid">
        <!-- author stuff -->
        <div
          class="row"
          style="
            font-size: 1.3rem;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
          "
        >
          <div
            v-if="item.maxPercent"
            style="font-size: 1.5rem"
            class="col-1"
            :style="
              item.maxPercent > 50 ? 'color: var(--q-p)' : 'color: var(--q-v)'
            "
          >
            {{ item.maxPercent.toFixed(0) }}%
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-2 text-8 round-both"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              <b>
                {{ $t("-raw-challenge-your-best-result") }}
              </b>
            </q-tooltip>
          </div>
          <q-spinner size="16px" v-show="opening" />
          <div
            v-if="item.Duration"
            style="
              font-size: 0.9rem;
              font-weight: bold;
              position: relative;
              right: 1rem;
              bottom: -3rem;
            "
          >
            <q-icon color="8" class="round-both" name="timer" />
            {{ (item.Duration / 1000).toFixed(1) }} s
          </div>

          <q-icon
            v-show="!opening && isInEditingMode"
            color="8"
            class="col-1 bg-2 round-both q-pa-xs"
            style="width: 1rem; height: 1rem"
            name="drag_indicator"
          >
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-2 text-8 round-both"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              <b>Drag to include, exclude or reorder</b>
            </q-tooltip>
          </q-icon>
        </div>
        <div
          v-if="
            !isInEditingMode &&
            lessonID &&
            indexInPlaylist >= 0 &&
            (item.createdby === user.ID || item.CreatedBy === user.ID)
          "
          class="row q-gutter-x-md text-8"
        >
          <q-btn
            @click.stop="editTransitions()"
            flat
            color="8"
            class="bg-2 q-my-auto"
            icon="sync_alt"
            style="
              border-radius: 0.75rem;
              width: 1rem;
              height: 1rem;
              position: relative;
            "
          >
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-2 text-8 round-both"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              <b>{{ $t("-raw-challengecard-edit-transitions") }}</b>
            </q-tooltip>
          </q-btn>
        </div>
        <div
          v-if="
            isMyChallenge ||
            isMyChannel ||
            item.Access?.delete ||
            item.Access?.update
          "
          class="row q-gutter-x-sm q-mb-sm text-8"
        >
          <q-icon
            @click.stop="$store.dispatch('getChannels'), (prompt = true)"
            v-if="!isInPlaylistOrLesson && (isMyChallenge || isMyChannel)"
            flat
            color="8"
            size="1.25rem"
            class="bg-2 q-my-auto"
            name="content_copy"
            style="
              width: 2.5rem;
              height: 2.5rem;
              position: relative;
              border-radius: 0.75rem;
            "
          >
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-2 text-8 round-both"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              <b>{{ $t("-raw-challengecard-copy") }}</b>
            </q-tooltip>
          </q-icon>
          <q-dialog v-model="prompt">
            <q-card style="min-width: 350px">
              <q-card-section>
                <div class="text-h6">
                  {{ $t("-raw-challengecard-select-channel") }}
                </div>
              </q-card-section>

              <q-card-section class="q-pt-none row q-gutter-md">
                <mychannels
                  @select="
                    (c) => {
                      channelCopyTo = c;
                    }
                  "
                  :selected="channelCopyTo"
                  :options="
                    $store.state.channels.channels.filter(
                      (c) => c.ID !== $store.getters.currentchannel.ID,
                    )
                  "
                />
              </q-card-section>

              <q-card-actions align="right" class="text-primary">
                <q-btn flat :label="$t('-raw-general-cancel')" v-close-popup />
                <q-btn
                  flat
                  :label="$t('-raw-general-copy')"
                  v-if="channelCopyTo"
                  :loading="copying"
                  @click="copyToChannel(item.ID, channelCopyTo.ID)"
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
          <q-icon
            @click.stop="deleteChallenge(item.ID)"
            v-if="!isInPlaylistOrLesson && item.Access?.delete"
            :loading="deleting"
            flat
            color="8"
            size="1.25rem"
            class="bg-2 q-my-auto"
            name="delete"
            style="
              border-radius: 0.75rem;
              width: 2.5rem;
              height: 2.5rem;
              position: relative;
            "
          >
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-2 text-8 round-both"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              <b>{{ $t("-raw-challengecard-delete") }}</b>
            </q-tooltip>
            <challenge-refs
              ref="challengeRefs"
              :playlists="refPlaylists"
              :lessons="refLessons"
              :message="$t('-raw-challenge-is-being-used-delete')"
              @refresh="deleteChallenge(item.ID)"
            />
          </q-icon>
          <q-icon
            @click.stop="edit()"
            v-if="item.Access?.update"
            :loading="editing"
            flat
            color="8"
            size="1.25rem"
            class="bg-2 q-my-auto"
            name="drive_file_rename_outline"
            style="
              width: 2.5rem;
              height: 2.5rem;
              position: relative;
              border-radius: 0.75rem;
            "
          >
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-2 text-8 round-both"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              <b>{{ $t("-raw-challengecard-edit") }}</b>
            </q-tooltip>
          </q-icon>
        </div>
        <!-- non-author stuff -->
        <div class="q-mt-sm" v-if="item.LanguageID">
          <span style="font-weight: bold"
            >{{
              $t("-raw-challenge-language") === "-raw-challenge-language"
                ? "Language"
                : $t("-raw-challenge-language")
            }}:
          </span>
          <template
            v-for="(lng, n) in $store.getters.languages"
            :key="n + 'challenge_language_list'"
          >
            <template v-if="item.LanguageID === lng.ID">
              {{ lng.name }}
            </template>
          </template>
        </div>
        <div class="text-4" style="white-space: nowrap">
          {{ handleDate(item.createdat) }}
        </div>
      </div>
      <div class="column q-pl-md" v-else>
        <div class="q-mt-sm" v-if="item.LanguageID">
          <span style="font-weight: bold"
            >{{
              $t("-raw-challenge-language") === "-raw-challenge-language"
                ? "Language"
                : $t("-raw-challenge-language")
            }}:
          </span>
          <template
            v-for="(lng, n) in $store.getters.languages"
            :key="n + 'challenge_language_list'"
          >
            <template v-if="item.LanguageID === lng.ID">
              {{ lng.name }}
            </template>
          </template>
        </div>
        <div class="text-4" style="white-space: nowrap">
          {{ handleDate(item.createdat) }}
        </div>
        <div
          v-if="item.CurrentAttemptPercent"
          style="font-size: 1.5rem"
          class="col-1"
          :style="
            item.CurrentAttemptPercent > 50
              ? 'color: var(--q-p)'
              : 'color: var(--q-v)'
          "
        >
          {{ item.CurrentAttemptPercent.toFixed(0) }}%
          <q-tooltip
            anchor="top middle"
            self="bottom middle"
            class="bg-2 text-8 round-both"
            transition-show="jump-up"
            transition-hide="jump-down"
          >
            <b>
              {{ $t("-raw-challenge-your-current-result") }}
            </b>
          </q-tooltip>
        </div>
      </div>
    </div>
    <div class="row text-9 q-pt-md q-pb-sm q-pl-sm">
      <q-avatar
        style="
          width: 3rem;
          height: 3rem;
          overflow: hidden;
          border: 1px solid var(--q-2);
        "
        class="round-1 bg-1"
        size="3rem"
        color="1"
      >
        <pickerimage
          v-if="item && item.ID"
          :data="{
            uid: 'channel_img_' + item.ID,
            objectid: item.ChannelID,
            objecttype: 'channel_avatar',
          }"
          :view="{
            onlyshow: true,
          }"
          class="bg-transparent full-width"
        />
      </q-avatar>
      <div class="col column no-wrap text-9 q-pl-md">
        <div
          class="q-mb-xs"
          style="
            font-size: 1.3rem;
            font-weight: bold;
            line-height: 1.5rem;
            width: 90%;
          "
        >
          {{ item.Name }}
        </div>
        <div style="white-space: nowrap">
          <!-- <span style="font-weight: bold">{{ $t("-raw-channel") }}: </span> -->
          {{ item.ChannelName?.substring(0, 30) }}
          <b>&middot;</b>
          {{ item?.UserName }}
        </div>
      </div>
      <div
        class="col column no-wrap col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1"
        style="
          font-size: 0.5rem;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
        "
      >
        <q-icon
          v-show="!opening && !isInEditingMode && !previewEnabled"
          class="text-s bg-4 q-pa-xs round-both"
          name="play_arrow"
          style="
            height: 1rem;
            width: 1rem;
            position: relative;
            right: 0rem;
            top: 0rem;
          "
          @click.stop="openChallenge(item, true)"
        >
          <q-tooltip
            anchor="top middle"
            self="bottom middle"
            class="bg-2 text-8 round-both"
            transition-show="jump-up"
            transition-hide="jump-down"
          >
            <b>{{ $t("-raw-tooltip-play") }}</b>
          </q-tooltip>
        </q-icon>
      </div>
    </div>
  </div>
</template>

<style scoped>
.challengecard {
  transition: all 0.2s ease;
  border: 1px solid var(--q-2);
}
.challengecard:hover {
  scale: 1.025;
  border: 1px solid var(--q-5);
  box-shadow: 0 1rem 2rem -0.5rem #0008;
}
.challengecard:active {
  transition: all 0.075s ease;
  scale: 0.975;
}
.challenge {
  cursor: pointer;
  border: 2px solid var(--q-1);
  user-select: none;
}
.challenge:hover {
  border: 2px dashed var(--q-v);
}
.challengeBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-size: cover;
  filter: grayscale(100%) brightness(10%);
  opacity: 0.1;
}
</style>

<script>
import mychannels from "src/components/mychannels.vue";
import api from "src/h/api.js";
import challengeRefs from "src/components/challenge-refs.vue";
import pickerimage from "src/components/picker-picture/picker.vue";
import Lib from "src/h/helpers.js";

export default {
  components: { mychannels, challengeRefs, pickerimage },
  props: [
    "item",
    "indexInPlaylist",
    "lessonID",
    "previewEnabled",
    "pplanpasselementid",
  ],
  emits: ["deleteChallenge", "toggleItem", "preview"],
  data: () => ({
    editing: false,
    deleting: false,
    opening: false,
    copying: false,
    permission: null,
    prompt: false,
    channelCopyTo: null,
    previewLoading: false,
    showImagePreview: true,
    theme: null,
    refPlaylists: null,
    refLessons: null,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isInEditingMode() {
      return this.$store.getters.editPlaylist || this.$store.getters.editLesson;
    },
    isInPlaylistOrLesson() {
      return (
        !!this.$store.getters.playlistID ||
        !!this.$store.state.lessonStudio.lessonName
      );
    },
    isMyChallenge() {
      return (
        this.item.createdby === this.user.ID ||
        this.item.CreatedBy === this.user.ID
      );
    },
    isMyChannel() {
      return this.item.ChannelCreatedBy === this.user.ID;
    },
    isReferenced() {
      return (
        Array.isArray(this.refPlaylists) &&
        Array.isArray(this.refLessons) &&
        (this.refPlaylists.length > 0 || this.refLessons.length > 0)
      );
    },
    isSystem() {
      if (Lib.getItem("VOC_USER_ROLE_IS_SYSTEM")) {
        return true;
      }
      return false;
    },
  },
  created() {
    let th = this.$store.getters.getTheme;
    // this.theme = th[23]; // NOTE: lighter grey dark darker
  },
  methods: {
    setShowImagePreview(x) {
      this.showImagePreview = x;
    },
    getShowImagePreview() {
      return this.showImagePreview;
    },
    copyToChannel(challengeID, channelID) {
      this.copying = true;

      api
        .Call({
          url: "/api/v1/challenge/clone",
          method: "post",
          data: {
            DestinationChannelID: channelID,
            ChallengeID: challengeID,
          },
          show_error: true,
        })
        .then(
          (response) => {
            this.copying = false;
            this.prompt = false;
            this.$router.push("/challenge-studio/" + response.ID);
          },
          (e) => {
            this.copying = false;
            this.prompt = false;
          },
        );
    },
    handleChallengePicture(id) {
      if (!id) {
        return "";
      }
      // here for recommendation don't select assets, so it's is empty, can select by challenge_id, not by  asset_id
      // return (
      //   process.env.PUBLIC_API_URL +
      //   "/api/v1/asset/file/challenge_picture/" +
      //   id
      // );
      return (
        process.env.PUBLIC_API_URL +
        "/api/v1/asset/object/challenge_picture/" +
        id
      );
    },
    editTransitions() {
      this.$router.push(
        "/edit/lesson-item/" + this.lessonID + "/" + this.indexInPlaylist,
      );
    },
    // edit() {
    //   this.editing = true;

    //   this.getPermission(this.item.ID)
    //     .then((r) => {
    //       if (r?.update) {
    //         this.$router.push("/edit/" + this.item.ID);
    //       } else {
    //         this.$q.notify({
    //           type: "negative",
    //           message:
    //             "You don't have enough permissions to edit the challenge",
    //           classes: "round-both q-mb-sm no-shadow ubuntu",
    //         });
    //       }

    //       this.editing = false;
    //     })
    //     .catch((e) => {
    //       this.editing = false;
    //     });
    // },
    edit() {
      this.editing = true;

      this.getPermission(this.item.ID)
        .then((r) => {
          if (r?.update) {
            this.$router.push("/challenge-studio/" + this.item.ID);
          } else {
            this.$q.notify({
              type: "negative",
              message:
                "You don't have enough permissions to edit the challenge",
              classes: "round-both q-mb-sm no-shadow ubuntu",
            });
          }

          this.editing = false;
        })
        .catch((e) => {
          this.editing = false;
        });
    },
    async deleteChallenge(challengeID) {
      this.deleting = true;

      const promises = [];
      promises.push(this.loadRefPlaylists(challengeID));
      promises.push(this.loadRefLessons(challengeID));
      await Promise.all(promises).catch((e) => {
        console.error("Error loading challenge references: ", e);
      });

      if (this.isReferenced) {
        this.$refs.challengeRefs.showDialog = true;
        this.deleting = false;
        return;
      } else {
        this.$refs.challengeRefs.showDialog = false;
      }

      this.getPermission(challengeID)
        .then((r) => {
          if (r?.delete) {
            this.$emit("deleteChallenge", challengeID);
          } else {
            this.$q.notify({
              type: "negative",
              message:
                "You don't have enough permissions to delete the challenge",
              classes: "round-both q-mb-sm no-shadow ubuntu",
            });
          }

          this.deleting = false;
        })
        .catch((e) => {
          this.deleting = false;
        });
    },
    openChallenge(challenge, autoplay = false) {
      this.opening = true;

      this.$store.commit("setCurrentChannel", { Name: challenge.ChannelName });
      // it's actual for playlist (choose attempt, when click in challenge))
      if (this.$store.getters.createdChallenge?.ID !== challenge.ID) {
        this.$store.commit("setCompareResult", {});
      }
      this.getPermission(challenge.ID)
        .then((r) => {
          if (r?.read) {
            if (challenge.PlaylistID && challenge.ChallengeIndex >= 0) {
              this.$router.push({
                // path: `/playlist/${challenge.PlaylistID}/${challenge.ChallengeIndex}`,
                path: this.$route.path, //`/playlist/` + challenge.PlaylistID,
                query: {
                  autoplay: autoplay ? "true" : "",
                  n: challenge.ChallengeIndex,
                },
              });
            } else if (challenge.LessonV2ID && challenge.ChallengeIndex >= 0) {
              this.$router.push({
                path: `/lesson-studio/${challenge.LessonV2ID}/${challenge.ChallengeIndex}`,
              });
            } else {
              this.$router.push({ path: `/challenge/${challenge.ID}` });
            }
          } else {
            this.$q.notify({
              type: "negative",
              message: `You don't have enough permissions to open the challenge`,
              classes: "round-both q-mb-sm no-shadow ubuntu",
            });
          }

          this.opening = false;
        })
        .catch((e) => {
          this.opening = false;
        });
    },
    getPermission(challengeID) {
      if (this.permission) {
        const permission = this.permission;
        return new Promise((resolve, reject) => {
          resolve(permission);
        });
      }

      return api
        .Call({
          url: "/api/v1/share/access/challenge/" + challengeID,
          method: "get",
        })
        .then(
          (response) => {
            this.permission = response;
            return response;
          },
          (e) => {
            this.$store.dispatch("setError", e.message);
            throw e;
          },
        );
    },
    handleDate(datesrc) {
      const date = new Date(datesrc);
      return date.toDateString();
    },
    handleChannelAvatar(channelID) {
      return channelID
        ? process.env.PUBLIC_API_URL +
            "/api/v1/asset/object/channel_avatar/" +
            channelID
        : "Cc.svg";
    },
    loadRefPlaylists(challengeID) {
      return api
        .Call({
          url: "/api/v1/challenge/" + challengeID + "/playlists",
          method: "get",
        })
        .then(
          (response) => {
            this.refPlaylists = response;
            return response;
          },
          (e) => {
            this.$store.dispatch("setError", e.message);
            throw e;
          },
        );
    },
    loadRefLessons(challengeID) {
      return api
        .Call({
          url: "/api/v1/challenge/" + challengeID + "/lessons",
          method: "get",
        })
        .then(
          (response) => {
            this.refLessons = response;
            return response;
          },
          (e) => {
            this.$store.dispatch("setError", e.message);
            throw e;
          },
        );
    },
  },
};
</script>
