<template>
  <q-fab
    flat
    depressed
    :class="thisstate ? 'bg-2' : 'bg-1'"
    label-style="font-size: 0.6rem !important;"
    style="width: 3rem; height: 3rem; border-radius: 0.75rem"
    :disable="$store.state.audio.devices?.length < 1"
    :color="$store.state.audio.devices?.length > 0 ? '8' : 'n'"
    :icon="
      $store.state.audio.devices?.length > 0
        ? 'mic_external_on'
        : 'mic_external_off'
    "
    :hide-icon="$store.state.audio.inputDevice?.label.length > 0"
    :label="$store.state.audio.inputDevice?.label?.slice(0, 8)"
    active-icon="mic_external_on"
    :direction="direction"
    v-model="thisstate"
    padding="none"
  >
    <div
      v-if="$store.state.audio.devices?.length > 0"
      style="gap: 0.75rem"
      class="z-top bg-0"
      :class="
        direction === 'right' || direction === 'left'
          ? 'row no-wrap'
          : 'column no-wrap'
      "
    >
      <q-fab-action
        v-for="(device, n) in $store.state.audio.devices"
        :key="device.deviceId"
        no-caps
        flat
        tile
        :class="
          $store.state.audio.inputDevice?.deviceId === device.deviceId
            ? 'bg-v text-0'
            : 'bg-1 text-9'
        "
        @click="$store.commit('setSaveInputDevice', device)"
        :label="device.label?.slice(0, 8) || `Device-${n}`"
        class="q-pa-none"
        style="
          width: 3rem;
          height: 3rem;
          border-radius: 1rem;
          font-size: 0.6rem !important;
          margin: 0 !important;
        "
        unelevated
      >
        <q-tooltip
          anchor="center right"
          self="center left"
          :offset="[32, 0]"
          :class="
            $store.state.audio.inputDevice?.deviceId === device.deviceId
              ? 'bg-v text-0'
              : 'bg-2 text-9'
          "
          style="text-wrap: nowrap"
          class="mytooltip ubuntu text-0 row round-1 q-px-md items-center"
          transition-show="jump-right"
          transition-hide="jump-left"
        >
          {{ device.label || `Device-${n}` }}
        </q-tooltip>
      </q-fab-action>
      <!-- todo .onupdate (in App.vue maybe) -->
      <q-btn
        flat
        round
        style="
          height: 3rem;
          width: 3rem;
          border-radius: 1rem;
          margin: 0 !important;
        "
        icon="refresh"
        class="bg-1 text-8"
        @click="$store.dispatch('getDevices')"
      >
        <q-tooltip
          anchor="center right"
          self="center left"
          :offset="[32, 0]"
          style="text-wrap: nowrap"
          class="bg-2 text-9 mytooltip ubuntu text-0 row round-1 q-px-md items-center"
          transition-show="jump-right"
          transition-hide="jump-left"
        >
          {{ $t("-raw-tooltip-refresh-devices") }}
        </q-tooltip>
      </q-btn>
    </div>
  </q-fab>
</template>

<style>
/* the trigger button "inside" the fab */
.q-fab .q-btn--fab {
  margin: 0 !important;
}
</style>

<script>
export default {
  props: ["direction", "parentstate"],
  data: () => ({
    thisstate: false,
  }),
  watch: {
    parentstate(val) {
      if (!val) {
        this.thisstate = false;
      }
    },
  },
};
</script>
