import ga from "src/h/analytics.js";
import VueGtag from "vue-gtag-next";

export default ({ app, router }) => {
  // console.log('%cboot/google-analytics.js', 'background: black; font-weight: bold; color: white;')

  app.use(VueGtag, {
    property: {
      id: "G-BYSSSHPG8W",
      params: {
        send_page_view: true,
      },
    },
  });

  router.afterEach((to, from) => {
    ga.logPage(to.path, to.name /*, sessionId */);
  });
};
