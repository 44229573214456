<template>
  <section
    class="row justify-center items-center wrapper"
    :style="{ height: window.innerHeight / 1.2 + 'px' }"
    style="position: relative"
  >
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 column justify-center items-center text-center mont-900"
    >
      <div class="xs q-my-none">
        <!-- <q-img src="doodles/halloween/voccent-trick-to-speak-pumpkin.png" /> -->
        <!-- <q-img
             style="width: 50%; filter: drop-shadow(0 1rem 2rem #77f4)"
             src="doodles/christmas/voccent-the-snowman.png"
             /> -->
        <div class="text-h3 mont-800 q-mb-sm q-mt-xs" style="color: var(--l9)">
          {{ $t("-raw-bigger-than-languages")
          }}<span style="color: #ff8800">.</span>
        </div>
      </div>
      <div class="gt-xs q-my-none">
        <!-- <q-img src="doodles/halloween/voccent-trick-to-speak-pumpkin.png" /> -->
        <!-- <q-img
             src="doodles/christmas/voccent-the-snowman.png"
             style="filter: drop-shadow(0 1rem 2rem #77f4)"
             /> -->
        <div class="text-h2 mont-800 q-mb-sm q-mt-xs" style="color: var(--l9)">
          {{ $t("-raw-bigger-than-languages")
          }}<span style="color: #ff8800">.</span>
        </div>
      </div>

      <!--  -->
      <!-- VIDEO IN THE CENTER PLACEHOLDER -->
      <!-- <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/dgRIzum_Z_A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="q-my-lg" style="border-radius: 1.75rem !important;"></iframe> -->

      <!-- <img
           class="img-landing-screenshots-1"
           src="landing/screenshots/mob3-2022-11-30-23-48-41-a.jpeg"
           />
           <img
           class="img-landing-screenshots-2"
           src="landing/screenshots/mob1-2022-11-30-23-49-10-a.jpeg"
           />
           <img
           class="img-landing-screenshots-3"
           src="landing/screenshots/mob2-2022-11-30-23-50-19-a.jpeg"
           />
           <img
           class="img-landing-screenshots-4"
           src="landing/screenshots/web1-2022-11-30-23-52-17-a.jpeg"
           /> -->

      <a
        v-if="!iphone && $q.screen.lt.sm"
        class="q-mt-lg"
        style="text-decoration: none"
        href="https://play.google.com/store/apps/details?id=com.voccent.voccent&amp;utm_source=landingpage&amp;utm_campaign=landing_page&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-2"
      >
        <!-- style="height: 2.5rem !important" -->
        <q-img
          style="max-width: 60vw !important; max-height: 5rem"
          src="/webapp-badge.png"
        />
        <div
          class="mont-400 q-mt-lg"
          style="font-size: 1.25rem; color: var(--lc)"
        >
          {{ $t("-raw-landing-text4aa") }}
        </div>
      </a>
      <a
        v-if="iphone && $q.screen.lt.sm"
        class="q-mt-lg"
        style="text-decoration: none"
        href="https://apps.apple.com/us/app/voccent-learning-languages/id1608462833"
      >
        <q-img
          style="max-width: 60vw !important; max-height: 5rem"
          src="/app-store-badge.png"
        />
        <div
          class="mont-400 q-mt-lg"
          style="font-size: 1.25rem; color: var(--l9)"
        >
          {{ $t("-raw-landing-text4aa") }}
        </div>
      </a>
      <div class="row q-ma-sm" v-if="!android && !iphone && $q.screen.gt.xs">
        <LoginLink text="Let's Go" link="/login" />
      </div>
    </div>

    <!--  -->
    <!-- VIDEO ON THE SIDE PLACEHOLDER -->
    <!-- <div class="col-6 row justify-center">
         <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/dgRIzum_Z_A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="q-my-lg" style="border-radius: 1.75rem !important;"></iframe>
         </div> -->

    <!--  -->
    <!-- GLOBE -->

    <!-- <div class="col-6 row justify-center">
         <svg height="30vh" id="globus" viewBox="0 0 1176.60617 873.97852">
         <use xlink:href="landing/undrawglobe.svg#main" />
         </svg>
         </div> -->

    <!--  -->
    <!-- STATISTICS -->

    <!-- <div
         class="row justify-between col-10 q-py-md absolute q-px-xl"
         style="
         left: 50%;
         border-radius: 2rem;
         bottom: 0;
         transform: translateX(-50%);
         "
         >
         <transition-group
         appear
         name="stat"
         tag="div"
         class="column stat"
         :style="{
         '--delay': i,
         }"
         v-for="(stat, i) in stats"
         :key="i"
         >
         <div key="init" class="mont-800 text-center" style="font-size: 1.5rem">
         {{ stat.init }}
         </div>
         <div key="name" class="mont-600" style="color: #999aaa">
         {{ $t(stat.name) }}
         </div>
         </transition-group>
         </div> -->
  </section>
</template>

<script setup>
import LoginLink from "./LoginLink.vue";

//
// FOR STATISTICS
// import anime from "animejs";
// import { ref } from "vue";

let android = navigator.userAgent.toLowerCase().indexOf("android") > -1;
let iphone = navigator.userAgent.toLowerCase().indexOf("iphone") > -1;
function scrl() {
  document.getElementById("who").scrollIntoView({
    behavior: "smooth",
    top:
      document.getElementById("who").getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      400,
  });
}

//
// FOR STATISTICS

// let stats = ref([
//   {
//     name: "-raw-tab-channels",
//     init: 0,
//     value: 156,
//   },
//   {
//     name: "-raw-tab-playlists",
//     init: 0,
//     value: 850,
//   },
//   {
//     name: "-raw-landing-stat-audio",
//     init: 0,
//     value: 12328795 / 1000,
//   },
//   {
//     name: "-raw-tab-challenges",
//     init: 0,
//     value: 3200,
//   },
//   {
//     name: "-raw-tab-stories",
//     init: 0,
//     value: 107,
//   },
// ]);
// for (let i = 0; i < stats.value.length; i++) {
//   const obj = { n: stats.value[i].init };
//   anime({
//     targets: obj,
//     n: stats.value[i].value,
//     round: 1,
//     delay: i * 250,
//     duration: 1000,
//     easing: "easeInOutQuad",
//     update: () => {
//       stats.value[i].init = obj.n;
//     },
//   });
// }
</script>

<style scoped>
#globus {
  transition: filter 0.5s ease-in-out;
  filter: drop-shadow(0 0 2rem #97918d27) drop-shadow(2rem 2rem 0 var(--l4))
    drop-shadow(2rem 2rem 0 var(--l1));
}
#globus:hover {
  filter: drop-shadow(0 0 0 #97918d27) drop-shadow(0 0 0 var(--l4))
    drop-shadow(0 0 0 var(--l1));
}
.stat-enter-active,
.stat-leave-active {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-delay: calc(0.25s * var(--delay)) !important;
}
.stat-enter-from,
.stat-leave-to {
  opacity: 0;
  transform: scale(0.5) translateY(150%);
}
.stat-leave-from,
.stat-enter-to {
  opacity: 1;
  transform: scale(1) translateY(0);
}
.img-landing-screenshots-1 {
  width: 60%;
  position: absolute;
  rotate: 4deg;
  right: -30%;
  bottom: -18rem;
  z-index: -1;
  float: center;
  opacity: 0.512343;
}
.img-landing-screenshots-2 {
  width: 40%;
  position: absolute;
  rotate: -12deg;
  right: -12%;
  top: 70rem;
  z-index: -1;
  float: center;
  opacity: 0.512343;
}
.img-landing-screenshots-3 {
  width: 40%;
  position: absolute;
  rotate: 8deg;
  left: -8%;
  top: 140rem;
  z-index: -1;
  float: center;
  opacity: 0.512343;
}
.img-landing-screenshots-4 {
  width: 100%;
  position: absolute;
  rotate: -8deg;
  right: -8%;
  top: 225rem;
  z-index: -1;
  float: center;
  opacity: 0.512343;
}

/* Highlight the text */
.container {
  line-height: 2.4;
  text-align: center;
  padding: 44px;
}

.highlight-container,
.highlight {
  position: relative;
}

.highlight-container {
  display: inline-block;
}
.highlight-container:before {
  content: " ";
  display: block;
  height: 130%;
  width: 110%;
  margin-left: -3px;
  margin-right: -3px;
  position: absolute;
  background: var(--q-9);
  transform: rotate(1deg);
  top: -1px;
  left: -1px;
  border-radius: 30% 25% 20% 34%;
  padding: 10px 3px 3px 10px;
  z-index: -100;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.wrapper::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/background-dark-galaxy.png") no-repeat center center;
  background-attachment: fixed;
  overflow: hidden; /* Prevent children from causing overflow */
  background-size: cover;
  opacity: 0.6;
  z-index: 1; /* Ensure it is behind the content */
  pointer-events: none; /* Ensure the background is not interactive */
}
</style>
