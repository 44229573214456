<template>
  <div class="row fit justify-center items-end q-py-md">
    <q-img
      class="col-xl-2 col-lg-2 col-md-3 col-sm-5 col-xs-5"
      fit="contain"
      src="not_found.svg"
    />
  </div>
  <span class="text-8" style="font-size: 1.2rem">
    {{ $t("-raw-discovery-nothing-found-v2") }}
  </span>
  <q-btn
    v-if="tab != 'classrooms'"
    style="cursor: pointer; text-decoration: underline"
    @click="$emit('newTab','classrooms')"
  >
    classrooms
  </q-btn>
  <q-btn
    v-if="tab != 'playlists'"
    style="cursor: pointer; text-decoration: underline"
    @click="$emit('newTab','playlists')"
  >
    playlists
  </q-btn>
  <q-btn
    v-if="tab != 'channels'"
    style="cursor: pointer; text-decoration: underline"
    @click="$emit('newTab','channels')"
  >channels
  </q-btn
  >
  <q-btn
    v-if="tab != 'challenges'"
    style="cursor: pointer; text-decoration: underline"
    @click="$emit('newTab','challenges')"
  >
    challenges
  </q-btn>
  <q-btn
    v-if="tab != 'stories'"
    style="cursor: pointer; text-decoration: underline"
    @click="$emit('newTab','stories')"
  >
    stories
  </q-btn>
</template>
<script>
export default {
  props: ["tab"],
  emits: ["newTab"],
}
</script>
