<template>
  <div style="font-size: 1.1rem" class="col column items-center justify-center">
    <span
      >{{ $t("-raw-billing-popup-have-no-access") }}
      <q-icon size="sm" round color="4" name="info">
        <q-tooltip
          anchor="top middle"
          self="bottom middle"
          class="mytooltip round-both ubuntu bg-1 text-v items-center q-px-md z-max"
          transition-show="jump-up"
          transition-hide="jump-down"
        >
          {{ CapabilityName }}
        </q-tooltip>
      </q-icon>
    </span>
    <span class="q-mt-lg">{{ $t("-raw-billing-popup-please-upgrade") }} </span>
    <span class="q-mt-lg" v-if="PreviewAvailable"
      >{{ $t("-raw-billing-popup-you-can-preview").split("Voccent")[0] }}
      <span
        class="gotobilling text-weight-bold"
        @click="
          $router.push({ path: `/c/Voccent` }), $store.dispatch('hideNoAccessTariff')
        "
      >
        Voccent </span
      >{{
        $t("-raw-billing-popup-you-can-preview").split("Voccent").length > 1
          ? $t("-raw-billing-popup-you-can-preview").split("Voccent")[1]
          : ""
      }}
    </span>
  </div>
</template>

<style scoped>
.gotobilling {
  color: var(--q-v);
  opacity: 0.8;
}
.gotobilling:hover {
  cursor: pointer;
  opacity: 1;
}
</style>

<script>
import h from "src/h/helpers.js";
export default {
  props: {
    onRouteBilling: Function,
    capabilityid: String,
  },
  data() {
    return {};
  },
  mounted() {
    gtag("event", "billingHaveNoAccessByTariffGuard", {
      event_category: "billing",
      event_label: "Billing access guard triggered at: [" + this.$route.name + "]",
    });
  },
  computed: {
    PreviewAvailable() {
      const capID = this.capabilityid || this.$store.state.noaccesstariff.capabilityID;
      if (!h.isValidUuid(capID)) {
        return false;
      }
      return (
        this.$store.getters.userTariff?.capabilities instanceof Object &&
        ((this.$store.getters.userTariff?.capabilities[capID].Value?.String || "").split(
          /\r?\n/
        )[0] === "Preview" ||
          this.$store.getters.userTariff?.capabilities[capID].Value?.Preview)
      );
    },
    CapabilityName() {
      const capID = this.capabilityid || this.$store.state.noaccesstariff.capabilityID;
      if (!h.isValidUuid(capID)) return "";
      const origName = this.$store.getters.userTariff?.capabilities[capID]?.Name.trim();
      let localName = this.$t("-raw-billing-capability-" + origName);
      if (!localName || localName.slice(0, 4) === "-raw") localName = origName;
      if (localName.slice(0, 2) === "- ") localName = localName.slice(2);
      return (
        localName +
        ": " +
        (this.$store.getters.userTariff?.capabilities[capID]?.Value?.Int ??
          this.$store.getters.userTariff?.capabilities[capID]?.Value?.Bool ??
          this.$store.getters.userTariff?.capabilities[capID]?.Value?.String)
      );
    },
  },
  methods: {
    RouteBilling() {
      if (this.onRouteBilling !== undefined) {
        this.onRouteBilling();
      }
      this.$router.push({ path: `/settings/billing` });
    },
  },
};
</script>
