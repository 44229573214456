import api from "src/h/api.js";

export default {
  namespaced: true,
  state: {
    challenge: {
      name: "",
      share: [],
      privacy: "public",
      picture: false,
      SourceLanguageID: null,
      languageID: null,
      Categories: [],
      mode: "interactive",
      level: null,
    },
    annotation: null,
    annotations: [],
    audiosampleLabel: "",
    editedOggBlob: null,
    needSavePicture: null, // save id new challenge here
    pictureStatus: null,
  },
  mutations: {
    setChallengeMode: (state, payload) => (state.challenge.mode = payload),
    setChallengeName: (state, payload) => (state.challenge.name = payload),
    setChallengeSpelling: (state, payload) => {
      state.challenge.Spelling = payload;
    },
    setChallengeLanguage: (state, payload) =>
      (state.challenge.languageID = payload),
    setChallengeLevel: (state, payload) => (state.challenge.level = payload),
    setChallengeSourceLanguage: (state, payload) => {
      state.challenge.SourceLanguageID = payload;
    },
    setChallengeCategories: (state, payload) => {
      if (!state.challenge.Categories) {
        state.challenge.Categories = [];
      }
      state.challenge.Categories.splice(
        0,
        state.challenge.Categories.length,
        ...payload,
      );
    },
    setChallengePrivacy: (state, payload) =>
      (state.challenge.privacy = payload),
    resetChallenge: (state) => {
      state.challenge = {
        name: null,
        share: [],
        privacy: "public",
        picture: false,
        mode: "interactive",
      };
    },
    setAnnotation: (state, payload) => (state.annotation = payload),
    setAnnotations: (state, payload) => (state.annotations = payload),
    setTranscription: (state, payload) =>
      (state.annotation.Transcription = payload),
    setDescription: (state, payload) =>
      (state.annotation.Description = payload),
    deleteAnnotation: (state) => (state.annotation = null),
    clearAnnotations: (state) => {
      state.annotation = null;
      state.annotations = [];
    },
    setAudiosampleLabel: (state, payload) => (state.audiosampleLabel = payload),
    setEditedOggBlob: (state, payload) => (state.editedOggBlob = payload),
    needSavePicture: (state, payload) => (state.needSavePicture = payload),
    setPictureStatus: (state, payload) => (state.pictureStatus = payload),
  },
  actions: {
    // don't use any more (use id and push into challenge studio)
    createChallenge({ dispatch, commit, state, rootGetters }) {
      const quotaCheck = [];
      if (state.challenge.privacy === "public") {
        quotaCheck.push(
          dispatch(
            "checkTariffQuota",
            { capabilityID: "00000000-0000-0000-0000-000000000040" },
            { root: true },
          ),
        );
      }
      const lnk = this;
      Promise.all(quotaCheck).then(
        () => {
          commit("setLoading", true, { root: true });
          commit("needSavePicture", null);

          rootGetters.currentchannel?.ID
            ? {}
            : commit("setCurrentChannel", rootGetters.channels?.[0], {
                root: true,
              });
          api
            .Call({
              url: "/api/v1/challenge",
              method: "post",
              show_error: true,
              data: {
                Name: state.challenge.name,
                Mode: state.challenge.mode,
                AudioSampleRefID: rootGetters.getAudioSampleID,
                ChannelID:
                  rootGetters.currentchannel?.ID ||
                  rootGetters.channels?.[0]?.ID, // create on first channel in case goes by URL
                Privacy: state.challenge.privacy,
                Share: state.challenge.share,
                SourceLanguageID: state.challenge.SourceLanguageID,
                LanguageID: state.challenge.languageID,
                Tags: state.challenge.Tags,
                Categories: state.challenge.Categories,
                Level: parseInt(state.challenge.level),
                Spelling: state.challenge.Spelling,
              },
            })
            .then(
              (challengeResponse) => {
                lnk.$router.push({
                  name: "-raw-route-challenge",
                  params: { challengeid: challengeResponse.ID },
                });
                dispatch("sendPictureAsset", challengeResponse.ID);
                commit("setLoading", false, { root: true });
                commit("resetChallenge");
              },
              (e) => {
                commit("setLoading", false, { root: true });
                commit(
                  "setError",
                  { code: "error-4998b249: postChallenge error" },
                  { root: true },
                );
                console.error("error-4998b249", "postChallenge", e);
              },
            );
        },
        (e) => {},
      );
    },
    sendPictureAsset({ commit }, payload) {
      commit("setLoading", true, { root: true });
      commit("needSavePicture", payload);
    },
    updateAudiosample({ state, commit, rootGetters, dispatch, rootState }) {
      commit("setLoading", true, { root: true });
      api
        .Call({
          url: "/api/v1/audiosample/" + rootGetters.getAudioSampleID,
          method: "patch",
          data: {
            Label: rootState.audiosampleLabel,
            Duration: Math.round(rootGetters.durationref * 1000),
            Annotations: state.annotations,
          },
        })
        .then(
          () => {
            if (state.editedOggBlob) {
              dispatch("updateAsset", {
                Blob: state.editedOggBlob,
                ObjectID: rootGetters.getAudioSampleID,
                ObjectType: "audiosample",
                holes: rootGetters.getAudioSampleData.holes,
                chris: rootGetters.getAudioSampleData.chris,
                worklet: rootGetters.getAudioSampleData.worklet,
                client: rootGetters.getAudioSampleData.client,
                isRef: true,
              });
            } else {
              dispatch("createChallenge");
            }
          },
          (e) => {
            commit("setLoading", false, { root: true });
            commit(
              "setError",
              { code: "error-b8376e03: updateAudiosample error" },
              { root: true },
            );
            console.log("error-b8376e03: updateAudiosample", e);
          },
        );
    },
    updateAsset({ commit, dispatch }, asset) {
      let meta = `holes=${asset.holes},chris=${asset.chris},worklet=${asset.worklet},client=${asset.client}`;
      const formData = new FormData();
      formData.append("object_id", asset.ObjectID);
      formData.append("file_type", asset.ObjectType);
      formData.append("file", asset.Blob, { knownLength: asset.Blob.size });
      formData.append("meta", meta);
      commit("setEditedOggBlob", null);
      api
        .Call({
          url: "/api/v1/asset",
          data: formData,
          method: "post",
        })
        .then(
          (postAssetResponse) => {
            console.log(postAssetResponse);
            dispatch("createChallenge");
          },
          (e) => {
            commit("setLoading", false, { root: true });
            commit(
              "setError",
              { code: "error-f4e6a314: updateAsset error" },
              { root: true },
            );
            console.error("error-f4e6a314: updateAsset", e);
          },
        );
    },
  },
};
