import api from "src/h/api.js";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { Notify } from "quasar";

export default {
  name: "Chat",
  // user chatid (exists chat) or chattype/chatobjectid (need create new chat)
  // chatvar: type chat (channel_author, system_personal)
  props: [
    "chatid",
    "chattype",
    "chatobjectid",
    "chatsubject",
    "chatusers",
    "chatvar",
  ],
  emits: ["creatednew"],
  data() {
    return {
      messageList: [],
      loading: false,
      newMessage: null,
      streamerChatMessages: null,
      sessionID: uuidv4(),
    };
  },
  watch: {
    "$streamer.isConnect.value": {
      handler(newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          // this.StreamerChatMessagesConnect();
          this.LoadChat();
        }
      },
      immediate: false,
    },
  },
  mounted() {
    if (this.chatid) {
      this.LoadChat();
    }
  },
  beforeUnmount() {
    this.StreamerChatTicketRemove();
  },
  updated() {
    this.$nextTick(function () {
      var elem = document.getElementById("scrollMessages");
      if (elem) {
        elem.scrollTop = elem.scrollHeight;
      }
    });
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    LoadChat() {
      if (this.$streamer.isConnect.value === false) {
        return;
      }
      this.loading = true;
      api
        .Call({
          url: "/api/v1/chat/messages/" + this.chatid,
        })
        .then(
          (result) => {
            if (result !== null) {
              this.messageList = result;
            } else {
              this.messageList.splice(0, this.messageList.length, ...[]);
            }
            this.loading = false;
          },
          (e) => {
            console.error("error /api/v1/chat/messages/" + this.chatid, e);
            this.loading = false;
          },
        )
        .then(() => {
          this.StreamerChatMessagesConnect();
        });
    },
    StreamerChatMessagesConnect() {
      var lnk = this;
      lnk.streamerChatMessages = lnk.$streamer.addTicket(
        "inbox_chat_messages",
        lnk.chatid,
        function (obj, sessionID) {
          switch (obj.Operation) {
            case "new_message":
              lnk.messageList.push(obj.Object);
              break;
            case "delete_message_meta":
              for (let i = 0; i < lnk.messageList.length; i++) {
                if (lnk.messageList[i].ID === obj.Object.MessageID) {
                  if (lnk.messageList[i].Meta) {
                    for (let j = 0; j < lnk.messageList[i].Meta.length; j++) {
                      if (
                        lnk.messageList[i].Meta[j].ID ===
                        obj.Object.MessageMetaID
                      ) {
                        lnk.messageList[i].Meta.splice(j, 1);
                      }
                    }
                  }
                  break;
                }
              }
              break;
          }
        },
        null,
        lnk.sessionID,
      );
    },
    StreamerChatTicketRemove() {
      if (this.streamerChatMessages !== null) {
        this.streamerChatMessages.removeTicket();
      }
    },
    isItMeTheGreatUser(x) {
      return this.$store.getters.user.username === x;
    },
    SendMessage() {
      if (this.newMessage == "" || this.newMessage == null) {
        return;
      }
      if (!this.$streamer.isConnect.value) {
        return;
      }
      if (this.chatid) {
        // use socket send new message in exist chat
        this.streamerChatMessages.send({
          Operation: "new_message",
          Object: { Meta: [{ Body: this.newMessage }] },
        });

        this.newMessage = null;
      } else {
        //create new chat (send first message too)
        this.loading = true;
        api
          .Call({
            url: "/api/v1/chat/" + this.chattype + "/" + this.chatobjectid,
            method: "post",
            data: {
              Meta: [
                {
                  Body: this.newMessage,
                },
              ],
            },
          })
          .then(
            (result) => {
              if (result !== null) {
                this.$emit("creatednew", result);
                this.newMessage = null;
              }
              this.loading = false;
            },
            (e) => {
              console.log(
                "error /api/v1/chat/" + this.chattype + "/" + this.chatobjectid,
                e,
              );
              this.loading = false;
            },
          );
      }
      gtag("event", "chatMessageSent", {
        event_category: "Inbox",
        event_label: "Chat message sent",
      });
    },
    handleDate(datesrc) {
      const date = new Date(datesrc);
      return date.toDateString();
    },
    joinConfirmOrCancel(obj, confirm) {
      let objName = "campus";
      if (obj.Type == "invitation_campus") {
        objName = "campus";
      } else if (obj.Type == "invitation_classroom") {
        objName = "classroom";
      }
      api
        .Call({
          url:
            "/api/v1/" +
            objName +
            "/" +
            (confirm ? "confirm" : "cancel") +
            "_join/" +
            obj.ObjectID,
          method: "post",
          data: {},
        })
        .then(
          (result) => {},
          (e) => {
            Notify.create({
              group: true,
              timeout: 10000,
              message: "Invitation has expired",
              color: "1",
              textColor: "n",
              classes: "round-both q-ml-lg q-mb-sm",
              position: "bottom",
            });
          },
        );
    },
    joinStory(obj) {
      const payload = JSON.parse(obj.Payload);
      this.$router.push(
        "/story-pass/" + payload.StoryParentID + "?l=" + payload.Link,
      );
    },
    openCampus(obj) {
      this.$router.push("/campus/" + obj.ObjectID + "?tp=current&t=classrooms");
    },
    openMixer() {
      this.$router.push("/mixer");
    },
  },
};
