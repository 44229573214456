<template>
  <q-btn
    class="gradient-btn rounded-16 q-ml-md q-px-lg"
    size="lg"
    no-caps
    :to="link"
  >
    <div class="row no-wrap items-center">
      <div class="mont-600 text-0" style="white-space: nowrap">
        {{ text }}
      </div>
      <q-icon
        v-if="icon"
        class="q-ml-sm q-pl-xs"
        :class="{ icn: anim }"
        style="font-size: 1.5rem"
        :name="icon"
      />
    </div>
  </q-btn>
</template>

<script setup>
defineProps({
  text: String,
  link: String,
  icon: String,
  anim: Boolean,
});
</script>

<style scoped>
.gradient-btn {
  height: 6rem;
  width: 14rem;
  border-radius: 1rem;
  background: linear-gradient(47deg, black, purple, black);
  color: black;
  position: relative;
  overflow: hidden;
  transition:
    background 0.8s ease-in-out,
    color 0.8s ease-in-out,
    border-color 0.8s ease-in-out;
  border: 2px solid black;
}

.gradient-btn:hover {
  background: linear-gradient(44deg, black, purple, black);
  color: black;
  border-color: purple;
}
</style>
