<template>
  <q-dialog v-model="miccheck" class="z-max">
    <q-card
      style="
        border-radius: 1rem;
        border: 1px solid var(--q-3);
        box-shadow: none !important;
      "
      class="bg-0 column ubuntu"
    >
      <q-card-section class="row">
        <q-icon name="mic" size="1.5rem" class="q-mr-md q-pt-xs" color="v" />
        <div class="text-h6 text-v">Mic Check</div>
      </q-card-section>

      <div class="q-px-md column">
        <span class="text-8 text-body1">{{ $t("-raw-mic-check") }}</span>
        <span>
          You should be seeing a system dialog asking to allow Microphone
          Permissions. Please click "Allow" to proceed with our services. If you
          don't see a system dialog, check your browser / OS settings to enable
          microphone access 👂👀
          <br /><br />
          You can also ask Support for help :)
        </span>
      </div>

      <q-card-actions align="between">
        <q-btn flat rounded no-caps color="8" label="Terms & Conditions" />
        <q-btn
          flat
          label="OK"
          icon-right="done_all"
          color="0"
          class="bg-v"
          style="border-radius: 0.5rem"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <!-- :class="stream ? stream.active ? 'text-p' : 'text-8' : 'text-n'" -->
  <!-- :icon="stream ? stream.active ? 'settings_voice' : 'mic' : 'mic_off'" -->
  <!-- // NOTE: stream.active does not update through vuex.
    It's not due to reactivity, Vue3 can detect new props on an object.
    stream.active does not have a setter, it updates automatically when tracks on the stream are stopped (aka MediaStreamTrack.ready === "ended").
    One option is to delete the stream (literally stream = false) and create a new one each time recorder stops,
    but that defeats the whole purpose of a global stream and might not work on iOS
    Another option is to just create a separate variable and update it from recorder ¯\_(ツ)_/¯
    This is futile, don't bother.
    Hours Spent: 4.20
  -->
  <q-btn
    flat
    round
    no-caps
    color="0"
    icon="mic_off"
    v-if="!stream"
    style="
      width: 3rem;
      height: 3rem;
      margin: 0 !important;
      background: var(--q-n);
    "
    @click="(miccheck = true), micPopup()"
  />
</template>
<script>
export default {
  data: () => ({
    miccheck: false,
  }),
  computed: {
    stream: {
      get() {
        return this.$store.state.audio.stream;
      },
      set(stream) {
        this.$store.commit("setStream", stream);
      },
    },
  },
  methods: {
    micPopup() {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          channelCount: 1,
          deviceId: this.$store.state.audio.inputDevice?.deviceId
            ? // ? { exact: this.$store.state.audio.inputDevice?.deviceId } // NOTE: forces input device even if unavailable
              this.$store.state.audio.inputDevice?.deviceId
            : undefined,
        })
        .then((stream) => {
          // TODO: vuex
          stream.getTracks().forEach((t) => t.stop());
          this.stream = stream;
          this.miccheck = false;
        })
        .catch((err) => {
          console.error("Mic Access Denied", err);
        });
    },
  },
};
</script>
