import api from "src/h/api.js";
let P = "surfer.js";

export default {
  state: {
    inputDevice: null,
    devices: [],
    stream: null,
    blobs: {},
    vawes: {},
    aids: {},
    chart: false,
    durationtst: false,
    durationref: false,
    features: false,
    diffloading: false,
    diffs: false,
    goo: true,
    audioSampleID: null,
    audioSampleData: {},
    comparing: false,
    waves: [
      // todo // todo[2] !!!
      {
        duration: null,
        blob: null,
        aid: null,
        id: null,
      },
    ],
    pitch: [],
    energy: [],
    breath: [],
    emotion: [],
    fingerprint: [],
    highlight: [],
  },
  getters: {
    aid: (state) => (id) => {
      return state.aids[id];
    },
    blob: (state) => (id) => {
      return state.blobs[id];
    },
    vawe: (state) => (id) => {
      return state.vawes[id];
    },
    durationtst: (state) => state.durationtst,
    durationref: (state) => state.durationref,
    features: (state) => state.features,
    diffLoading: (state) => state.diffloading,
    diffs: (state) => state.diffs,
    goo: (state) => state.goo,
    getAudioSampleID: (state) => state.audioSampleID,
    getAudioSampleData: (state) => state.audioSampleData,
    comparing: (state) => state.comparing,
    getPitch: (state) => state.pitch,
    getEnergy: (state) => state.energy,
    getBreath: (state) => state.breath,
    getEmotion: (state) => state.emotion,
    // getFingerprint: (state) => state.fingerprint,
  },
  mutations: {
    setInputDevice: (state, payload) => (state.inputDevice = payload), // todo: getUserMedia() to update stream on change
    setSaveInputDevice: (state, payload) => {
      state.inputDevice = payload;
      localStoragePolyFill.setItem("mic", JSON.stringify(payload));
    }, // todo: getUserMedia() to update stream on change
    setDevices: (state, payload) => (state.devices = payload),
    stopStream: (state) => state.stream.getTracks().forEach((t) => t.stop()),
    setStream: (state, payload) => (state.stream = payload),
    switChart: (state) => (state.chart = !state.chart),
    loadBlob: (state, payload) => (state.blobs[payload.id] = payload.blob),
    setDummyAID: (state, payload) => (state.aids[payload.id] = payload.aid),
    setVawe: (state, payload) => (state.vawes[payload.id] = payload.vawe),
    setDurationtst: (state, payload) => (state.durationtst = payload),
    setDurationref: (state, payload) => (state.durationref = payload),
    setFeatures: (state, payload) => (state.features = payload),
    setDiffLoading: (state, payload) => (state.diffloading = payload),
    setDiffs: (state, payload) => (state.diffs = payload),
    switchGoo: (state) => (state.goo = !state.goo),
    setAudioSampleID: (state, payload) => (state.audioSampleID = payload),
    setAudioSampleData: (state, payload) => (state.audioSampleData = payload),
    setComparing: (state, payload) => (state.comparing = payload),
    deleteBlob: (state, payload) => delete state.blobs[payload],
    resetFeatures: (state) => (state.features = false),
    resetDiffs: (state) => (state.diffs = false),
    resetPitch: (state) => (state.pitch = false),
    resetEnergy: (state) => (state.energy = false),
    resetBreath: (state) => (state.breath = false),
    resetEmotion: (state) => (state.emotion = false),
    resetFingerprint: (state) => (state.fingerprint = false),
    setPitch: (state, payload) => (state.pitch = payload),
    setEnergy: (state, payload) => (state.energy = payload),
    setBreath: (state, payload) => (state.breath = payload),
    setEmotion: (state, payload) => (state.emotion = payload),
    setFingerprint: (state, payload) => (state.fingerprint = payload),
    setHighlight: (state, payload) => (state.highlight = payload),
  },
  actions: {
    getDevices({ commit }) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          let inputDevices = devices.filter((d) => d.kind === "audioinput");
          console.log("Devices: ", devices);
          console.log("Mics: ", inputDevices);
          commit("setDevices", inputDevices);

          // if (inputDevices[0].deviceId !== '') {
          //   this.$store.commit('setSaveInputDevice', inputDevices[0])
          // } // sets default, need to check if ID is not empty, because Firefox & maybe some other browsers fill IDs with empty string on first request
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sendAudioSample({ dispatch, commit }, payload) {
      api
        .Call({
          url: "/api/v1/audiosample",
          method: "post",
          data: payload.audioSample,
        })
        .then(
          (postAudiosampleResponse) => {
            // console.log(P + '   info-5033b69c', '   postAudiosampleResponse', postAudiosampleResponse)
            // console.table(postAudiosampleResponse)
            // CAN't do resolve here (need file/file_object too)
            // resolve({
            //   ID: postAudiosampleResponse.ID,
            //   // blob: payload.blob,
            // })
            commit("setAudioSampleID", postAudiosampleResponse.ID); // NOTE: for voc2me
            commit("setAudioSampleData", {
              blob: payload.blob,
              holes: payload.holes,
              chris: payload.chris,
              worklet: payload.worklet,
              client: payload.client,
            });
            return dispatch("sendAsset", {
              Blob: payload.blob,
              ObjectID: postAudiosampleResponse.ID,
              ObjectType: "audiosample",
              holes: payload.holes,
              chris: payload.chris,
              worklet: payload.worklet,
              client: payload.client,
              isRef: payload.audioSample.IsRef,
            }).then(() => postAudiosampleResponse);
          },
          (e) => {
            commit("setLoading", false);
            console.error(P + "error-cf9e213a", "postAudiosample", e);
          },
        );
    },
    sendAsset({ commit }, asset) {
      let meta = `holes=${asset.holes},chris=${asset.chris},worklet=${asset.worklet},client=${asset.client}`;
      const formData = new FormData();
      formData.append("object_id", asset.ObjectID);
      formData.append("file_type", asset.ObjectType);
      formData.append("file", asset.Blob, { knownLength: asset.Blob.size });
      formData.append("meta", meta);

      return api
        .Call({
          url: "/api/v1/asset",
          data: formData,
          method: "post",
        })
        .then(
          (postAssetResponse) => {
            console.log(P + "   info-396d76b6", "   postAssetResponse");
            // console.table(postAssetResponse)
            commit("setDummyAID", { id: "tst", aid: asset.ObjectID });
            commit("setLoading", false);
            // console.warn('asset', asset)
            // we don't need features for now (meeting 07/13)
            // if (!asset.isRef && process.env.PUBLIC_API_URL !== 'https://voccent.com') {
            //   dispatch('saveFeatures', {
            //     AudioSampleID: asset.ObjectID
            //   })
            // }
            // TODO: separate features
          },
          (e) => {
            commit("setLoading", false);
            console.error(P + "error-0af8a988", "postAssetCompare", e);
          },
        );
    },
    // saveFeatures ({ commit }, payload) {
    //   api.Call({
    //     url: '/api/v1/audiosample/features',
    //     method: 'post',
    //     data: payload
    //   }).then(
    //     postAudiosampleFeaturesResponse => {
    //       console.log(P + '   info-1e4b25f0   ', 'postAudiosampleFeaturesResponse   ')
    //       // console.warn('postAudiosampleFeaturesResponse', postAudiosampleFeaturesResponse)
    //       // console.table(postAudiosampleFeaturesResponse.frames)
    //       // console.warn('setFeatures')
    //       // !!! for challenge we need features exists (so we need audiosample + features),
    //       // we can't setDummyAID after audiosample save (sendAsset)
    //       commit('setDummyAID', { id: 'tst', aid: payload.AudioSampleID })
    //       commit('setFeatures', postAudiosampleFeaturesResponse.frames)
    //       commit('setDiffs', postAudiosampleFeaturesResponse.frames)
    //     },
    //     e => {
    //       console.error(P + 'error-7c36b24b', 'postAudiosampleFeatures', e)
    //     }
    //   )
    // },
    // getCompare ({ commit, getters }, request) { // TODO: call from challenge.js, add setComparing loader
    //   commit('setDiffLoading', true) // TODO: delme, make proper loader
    //   api.Call({
    //     url: '/api/v1/audiosample/compare/pronunciation',
    //     method: 'post',
    //     data: request
    //   }).then(
    //     postAudiosampleCompareResponse => {
    //       log(P + '   info-20d35de0', '   postAudiosampleCompareResponse', postAudiosampleCompareResponse)
    //       // if (this.$refs.progressBarTop) { this.$refs.progressBarTop.stop() } // TODO: delme (after making a proper loader for features & compare)
    //       commit('setDiffLoading', false) // TODO: delme, make proper loader
    //       // console.table(postAudiosampleCompareResponse.frames)
    //       commit('setDiffs', postAudiosampleCompareResponse.frames)
    //       let percent = this.comparror(postAudiosampleCompareResponse.frames) // TODO: make mixin for compare percent
    //       console.table(getters.attempts)
    //       commit('addAttempt', { id: newVal, attempt: { text: percent.toFixed(2) + '%', color: percent > 50 ? 'p' : 'v' } })
    //       // TODO: to save audio, in recorder audio ID should be the audioID from DB, not just 'tst' or 'ref'. Need to figure out how to retrieve by that ID in other components :/
    //     },
    //     e => {
    //       commit('addAttempt', { id: newVal, attempt: { text: 'fail :(', color: 'n' } })
    //       loge(P + 'error-0968d0a1', 'postAudiosampleCompare', e)
    //     }
    //   )
    // },
  },
};
