<template>
  <div class="full-width">
    <canvas id="activityChart" class="full-width"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import api from "src/h/api.js";

export default {
  data: () => ({
    activityChart: null,
    emojis: ["😞", "😐", "😊", "😀", "😁"], // Emojis from sad to happy
    emojisInterval: [3, 10, 50, 100], // first emoji if > emojisInterval[0], ...
    dayColors: [
      "#FFA07A", // Light Salmon
      "#FF7F50", // Coral
      "#FF6347", // Tomato
      "#FF4500", // Orange Red
      "#777DDD", // Variation of #777ddd
      "#997DDD", // Slightly lighter
      "#BB7DDD", // Even lighter
    ],
    activityData: [0, 0, 0, 0, 0, 0, 0],
    activityEmojis: [0, 0, 0, 0, 0, 0, 0], // index from emojis
  }),
  mounted() {
    this.get();
  },
  methods: {
    get() {
      api
        .Call({
          url: "/api/v1/statistics/user/user_count_compare_by_date",
          show_error: false,
        })
        .then(
          (result) => {
            if (result?.List) {
              for (let i = 0; i < result.List.length; i++) {
                this.activityData[i] = result.List[i].Count;
              }
            }
            // this.activityData = [0, 40, 66, 77, 56, 100, 150]; // test
            for (let i = 0; i < this.activityData.length; i++) {
              for (let j = 0; j < this.emojisInterval.length; j++) {
                if (this.activityData[i] <= this.emojisInterval[j]) {
                  this.activityEmojis[i] = j;
                  break;
                }
              }
              if (
                this.activityData[i] >
                this.emojisInterval[this.emojisInterval.length - 1]
              ) {
                this.activityEmojis[i] = this.emojisInterval.length; // it's right, we have no interval for big value
              }
            }

            this.draw();
          },
          (e) => {
            console.error(
              "error-b9b7b753: error get user_count_compare_by_date",
              err,
            );
          },
        );
    },
    draw() {
      let pnt = this;
      this.activityChart = new Chart(
        document.getElementById("activityChart").getContext("2d"),
        {
          type: "bar",
          data: {
            labels: [
              "6d ago",
              "5d ago",
              "4d ago",
              "3d ago",
              "2d ago",
              "1d ago",
              "Today",
            ],
            datasets: [
              {
                label: "Daily Activity",
                data: this.activityData,
                backgroundColor: this.dayColors.map((color) => color),
                borderColor: this.dayColors.map((color) => color),
                borderWidth: 1,
                borderRadius: 10, // Rounded corners for bars
              },
            ],
          },
          options: {
            scales: {
              y: {
                display: false,
                beginAtZero: true,
                grid: {
                  display: false,
                },
              },
              x: {
                grid: {
                  display: false,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || "";
                    if (label) {
                      label += ": ";
                    }
                    label +=
                      context.parsed.y <= pnt.emojisInterval[2]
                        ? "Good"
                        : "Excellent";
                    return label;
                  },
                },
              },
            },
            onHover: (event, chartElement) => {
              event.native.target.style.cursor = chartElement[0]
                ? "pointer"
                : "default";
            },
          },
          plugins: [
            {
              afterDraw: (chart) => {
                var ctx = chart.ctx;
                chart.data.datasets.forEach((dataset, i) => {
                  var meta = chart.getDatasetMeta(i);
                  if (!meta.hidden) {
                    meta.data.forEach((element, index) => {
                      ctx.fillStyle = "black";
                      const fontSize = 16;
                      ctx.font = fontSize + "px serif";
                      const dataString = pnt.emojis[pnt.activityEmojis[index]];
                      ctx.textAlign = "center";
                      // let y = element.y - 10;
                      // if (y < 16) {
                      //   // if emoji out from chart
                      //   y += 16;
                      // }
                      // let y = chart.height - 20;
                      let y = (chart.height - 20 - element.y) / 2 + element.y;
                      ctx.fillText(dataString, element.x, y);
                    });
                  }
                });
              },
            },
          ],
        },
      );
    },
  },
};
</script>
