export default {
  state: {
    currentOrganization: {
      ID: null,
      Name: null,
    },
    currentOrganizationAccess: {},
    currentOrganizationOperation: null,
    organization: {},
  },
  getters: {
    currentOrganization: (state) => state.currentOrganization,
    currentOrganizationAccess: (state) => state.currentOrganizationAccess,
    currentOrganizationOperation: (state) => state.currentOrganizationOperation,
  },
  mutations: {
    setOrganization: (state, payload) => (state.organization = payload),
    setCurrentOrganization: (state, payload) =>
      (state.currentOrganization = payload),
    setCurrentOrganizationAccess: (state, payload) =>
      (state.currentOrganizationAccess = payload),
    setCurrentOrganizationOperation: (state, payload) =>
      (state.currentOrganizationOperation = payload),
  },
};
