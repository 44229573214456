<template>
  <q-btn
    id="intercom"
    :class="open ? 'bg-2 text-n' : 'bg-1 text-5'"
    flat
    color="5"
    @click="updateOptions"
    style="
      height: 3rem;
      width: 3rem;
      border-radius: 0.75rem;
      margin: 0 !important;
    "
  >
    <q-tooltip
      anchor="center right"
      self="center left"
      :offset="[32, 0]"
      style="text-wrap: nowrap"
      class="bg-2 text-9 mytooltip ubuntu text-0 row round-1 q-px-md items-center"
      transition-show="jump-right"
      transition-hide="jump-left"
    >
      {{ open ? $t("-raw-main-support-close") : $t("-raw-main-chat") }}
    </q-tooltip>
    <q-badge rounded v-show="unreadCount" color="n" floating>
      {{ unreadCount }}
    </q-badge>
    <q-icon :name="open ? 'close' : 'support_agent'" size="2.0rem" />
  </q-btn>
</template>

<script>
// import HelpVideo from "src/components/help-video.vue";
export default {
  // components: { HelpVideo },
  props: {
    direction: {
      type: String,
      default: "down",
    },
  },
  data: () => ({
    open: false,
    unreadCount: 0,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    locale() {
      if (
        JSON.parse(localStoragePolyFill.getItem("interface language"))?.locale
      ) {
        return JSON.parse(localStoragePolyFill.getItem("interface language"))
          .locale;
      } else {
        return this.$i18n?.locale || navigator.language;
      }
    },
  },
  mounted() {
    Intercom("boot", {
      app_id: "edpyhr5x",
      language_override: this.locale,
      custom_launcher_selector: "#intercom",
      hide_default_launcher: true,
    });
    Intercom("onShow", () => {
      this.open = true;
    });
    Intercom("onHide", () => {
      this.open = false;
    });
    Intercom("onUnreadCountChange", (unreadCount) => {
      this.unreadCount = unreadCount;
    });
  },
  methods: {
    onClickOpen() {
      this.open = true;
    },
    updateOptions() {
      console.log(this.locale);
      Intercom("update", {
        language_override: this.locale,
        app_id: "edpyhr5x",
        custom_launcher_selector: "#intercom",
        hide_default_launcher: true,
        email: this.user?.cred_id,
        name: this.user?.username,
        horizontal_padding: this.$q.screen.gt.xs ? 80 : 16,
        vertical_padding: 80,
        alignment: "right",
      });
    },
  },
};
</script>
