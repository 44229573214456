import axios from "axios";
// import https from 'https'

const api = axios.create({ baseURL: "https://x.voccent.net" });

export default ({ app }) => {
  window.axios = require("axios"); // sets axios globally to avoid imports
  // console.log('%cboot/axios.js', 'background: black; font-weight: bold; color: white;')
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  // axios.create({
  //   agent: new https.Agent({
  //     rejectUnauthorized: false
  //   })
  // })

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
};

export { axios, api };
