<template>
  <metainfo>
    <!-- need this for meta-vue-3, here can create templates for meta if need -->
  </metainfo>
  <div v-if="!user">
    <div
      v-if="this.$q.platform.is.mobile"
      class="landing-background-20dd0442-mob"
    ></div>
    <div v-else class="landing-background-20dd0442-web"></div>
  </div>
  <router-view />
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    // created caused issues in Chrome iPad
    // NOTE: set defaults (lang, mic, theme)
    this.$i18n.locale = navigator.language;
    // console.log("ref-29690b85", "locale", this.$i18n.locale);
    // this.setMicPreference();
    // NOTE: in FF we need to ask mic permission to get labels for i/o devices
    // setTimeout(() => {
    //   navigator.mediaDevices
    //     .getUserMedia({
    //       audio: true,
    //       channelCount: 1,
    //       deviceId: this.$store.state.audio.inputDevice?.deviceId
    //         ? // ? { exact: this.$store.state.audio.inputDevice?.deviceId } // NOTE: forces input device even if unavailable
    //           this.$store.state.audio.inputDevice?.deviceId
    //         : undefined,
    //     })
    //     .then((stream) => {
    //       this.$store.commit("setStream", stream);
    //       this.$store.commit("stopStream");
    //       console.log(stream.getTracks());
    //       this.setMicPreference(); // literally for labels in FF
    //       // TODO: check if FF via navigator.client
    //     });
    // }, 3000);
    if (!is_webview()) {
      setTimeout(() => {
        this.setMicPreference();
      }, 1000);
    }
    setTimeout(() => {
      this.$h.version();
    }, 1000);
    this.setThemePreference();

    console.log(
      "%c🎉 Hi, dear hacker...",
      "background-color: #34495E; color: #41B883;",
    );
    console.groupEnd();
    if (process.env.PUBLIC_API_URL === "https://app.voccent.com") {
      console.clear(); // clear boot logs in prod
      // setInterval(() => {
      //   const t0 = Date.now();
      //   eval("debugger");
      //   const t1 = Date.now();
      //   // console.log(`DevTools ${t0 === t1 ? "is not" : "is"} open.`);
      // }, 2000);
    }

    // TODO: make helper funcs, or add to boot/helpers.js, also, $q.localStorage
    // Storage.prototype.setObj = function(key, obj) {
    // return this.setItem(key, JSON.stringify(obj))
    // }
    // Storage.prototype.getObj = function(key) {
    //     return JSON.parse(this.getItem(key))
    // }

    // NOTE: silly console.logging
    // this.svgtoblob('Voc-3000.svg').then(blobURL => {
    //   const cat1 = [
    //     'background-image: url("https://media.giphy.com/media/q6RoNkLlFNjaw/giphy.gif")',
    //     'background-size: cover',
    //     'border-radius: 30px',
    //     'padding: 30px 30px',
    //     'line-height: 0px',
    //     'font-size: 0px',
    //     'display: inline-block',
    //     'margin: 20px 0 20px 0'
    //   ].join(';')
    //   const cat2 = [
    //     'background-image: url("https://media.giphy.com/media/7NoNw4pMNTvgc/giphy-downsized.gif")',
    //     'background-size: cover',
    //     'border-radius: 30px',
    //     'padding: 30px 30px',
    //     'line-height: 0px',
    //     'font-size: 0px',
    //     'display: inline-block',
    //     'margin: 20px 0px 20px 20px'
    //   ].join(';')
    //   const voc3000 = `
    //     font-weight: 1000; color: #777FFF;
    //     background-image: url(${blobURL});
    //     padding-left: 150px;
    //     line-height: 40px;
    //     background-size: contain;
    //     background-position: 45% center;
    //     background-repeat: no-repeat;
    //   `
    //   console.log('%c %c Ready! %c ', cat1, voc3000, cat2)
    // })
  },
  methods: {
    async svgtoblob(url) {
      const file = await fetch(url);
      const text = await file.text();
      const data = btoa(text);
      return `data:image/svg+xml;base64,${data}`;
    },
    setMicPreference() {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          let inputDevices = devices.filter((d) => d.kind === "audioinput"); // mics only
          console.debug("debug-d55193a5: devices: ", devices);
          console.debug("debug-c1477bba: mics: ", inputDevices);
          this.$store.commit("setDevices", inputDevices); // for inputselector.vue

          let micPreference = JSON.parse(localStoragePolyFill.getItem("mic"));
          if (micPreference) {
            if (
              this.$store.state.audio.devices.filter(
                (d) => d.deviceId === micPreference.deviceId,
              ).length < 1
            ) {
              this.$store.commit("setInputDevice", null); // unsets default input device if it's not connected
            } else {
              this.$store.commit("setInputDevice", micPreference);
            }
          }

          // if (inputDevices[0].deviceId !== '') {
          //   this.$store.commit('setSaveInputDevice', inputDevices[0])
          // } // sets default, need to check if ID is not empty, because Firefox & maybe some other browsers fill IDs with empty string on first request
        })
        .catch((error) => {
          console.error(
            `%cDevices: ${error}`,
            "background: #FF7777; border-radius: 1rem; padding: 0.5rem;",
          );
        });
    },
    setThemePreference() {
      let theme = this.$store.getters.getTheme;
      if (theme) this.$store.dispatch("setTheme", theme);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.google-font-montserrat {
  font-family: "Montserrat", "sans-serif";
} */

.debug * {
  outline: 2px solid var(--q-6) !important;
}

.ubuntu {
  font-family: "Ubuntu", Arial;
}
.comfortaa {
  font-family: "Comfortaa", cursive;
}

.searchtip {
  font-size: 1rem;
  border-radius: 2rem;
  border: 2px solid var(--q-2);
}

.hole {
  /* NOTE: for welcome walkthrough
      box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      /* height: 500px;
      width: 500px;
      position: fixed;
      top: -150px;
      right: -150px;
      font-weight: 1000;
      font-size: 1.25rem; */
}

/* html, body {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    max-height: 100vh;
    overflow: hidden !important;
    } */
svg {
  cursor: inherit !important;
  /*
      The row below hide checked checkbox!
      background: inherit !important;
    */
}

#gui {
  position: fixed;
  top: 5rem;
  right: 4rem;
  z-index: 99999999999999999999;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein05 {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

/* mobile native scrolling */ /* + Firefox scrollbar styles */
.scrollie {
  scrollbar-color: var(--q-1) var(--q-0);
  scrollbar-width: none;
  overflow: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
::selection {
  border-radius: 1rem !important;
  background: var(--q-v);
  color: var(--q-0);
}

.glassy-8 {
  box-shadow: inset 0 0 8rem 0 var(--q-0);
  /* backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px); */
}
.glassy-4 {
  box-shadow: inset 0 0 4rem 0 var(--q-0);
  /* backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px); */
}
.glassy-2 {
  box-shadow: inset 0 0 2rem 0 var(--q-0);
  /* backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px); */
}

/* 0-9 are color layers, 0 being the bottom one and 9 the top one (like in z-index)
    they go from darkest to lightest for dark themes and vise versa for light theme
    for exact colors check either theminator.vue or quasar.conf.js */

.bg-0 {
  background: var(--q-0);
}
.bg-1 {
  background: var(--q-1);
}
.bg-2 {
  background: var(--q-2);
}
.bg-3 {
  background: var(--q-3);
}
.bg-4 {
  background: var(--q-4);
}
.bg-5 {
  background: var(--q-5);
}
.bg-6 {
  background: var(--q-6);
}
.bg-7 {
  background: var(--q-7);
}
.bg-8 {
  background: var(--q-8);
}
.bg-9 {
  background: var(--q-9);
}
.bg-a {
  background: var(--q-a);
}
.bg-b {
  background: var(--q-b);
}
.bg-c {
  background: var(--q-c);
}
.bg-d {
  background: var(--q-d);
}
.bg-e {
  background: var(--q-e);
}

.bg-f {
  background: var(--q-f);
} /* slightly lighter than 0 */
.bg-t {
  background: var(--q-t);
} /* slightly darker than 0 */
.bg-h {
  background: var(--q-h);
} /* pink */
.bg-n {
  background: var(--q-n);
} /* negative */
.bg-p {
  background: var(--q-p);
} /* positive */
.bg-w {
  background: var(--q-w);
} /* accent */
.bg-v {
  background: var(--q-v);
} /* accent */

.text-0 {
  color: var(--q-0);
}
.text-1 {
  color: var(--q-1);
}
.text-2 {
  color: var(--q-2);
}
.text-3 {
  color: var(--q-3);
}
.text-4 {
  color: var(--q-4);
}
.text-5 {
  color: var(--q-5);
}
.text-6 {
  color: var(--q-6);
}
.text-7 {
  color: var(--q-7);
}
.text-8 {
  color: var(--q-8);
}
.text-9 {
  color: var(--q-9);
}
.text-a {
  color: var(--q-a);
}
.text-b {
  color: var(--q-b);
}
.text-c {
  color: var(--q-c);
}
.text-d {
  color: var(--q-d);
}
.text-e {
  color: var(--q-e);
}

.text-f {
  color: var(--q-f);
}
.text-t {
  color: var(--q-t);
}
.text-n {
  color: var(--q-n);
}
.text-h {
  color: var(--q-h);
}
.text-p {
  color: var(--q-p);
}
.text-w {
  color: var(--q-w);
}
.text-v {
  color: var(--q-v);
}

.z-1 {
  z-index: 1 !important;
}

.mytooltip {
  height1: 3rem;
  font-size: 1rem;
  font-weight: 1000;
}

.borderoutline {
  border: 4px solid red;
}
.borderoutlinetopbottom {
  border-top: 4px solid var(--q-e);
  /* border-bottom: 4px solid var(--q-e); */
}
.borderoutlinebottomleft {
  border-top: 2px solid var(--q-v);
  border-right: 2px solid var(--q-v);
  border-bottom: 2px solid var(--q-0);
  border-left: 2px solid var(--q-0);
}
.borderoutlinetopleft {
  border-top: 2px solid var(--q-0);
  border-right: 2px solid var(--q-v);
  border-bottom: 2px solid var(--q-v);
  border-left: 2px solid var(--q-0);
}
.borderoutlineleft {
  border-top: 2px solid var(--q-v);
  border-right: 2px solid var(--q-v);
  border-bottom: 2px solid var(--q-v);
  border-left: 2px solid var(--q-0);
}
.borderneubottomleft {
  border: 2px solid var(--q-0);
  box-shadow: 0.8rem -0.7rem 1rem -0.3rem var(--q-t) !important;
}
.boxshadow {
  /* border: 2px solid var(--q-0); */
  box-shadow:
    inset 1rem 1rem 2rem var(--q-f),
    inset -1rem -1rem 2rem var(--q-t);
}
.shadowbox {
  /* border: 2px solid var(--q-0); */
  box-shadow:
    inset -1rem -1rem 2rem var(--q-f),
    inset 1rem 1rem 2rem var(--q-t);
}

.round-br-1 {
  border-radius: 0rem;
  border-bottom-right-radius: 1rem;
}
.round-right {
  border-radius: 0 5rem 5rem 0;
}
.round-left {
  border-radius: 5rem 0 0 5rem;
}
.round-both {
  border-radius: 5rem;
}
.round-1 {
  border-radius: 0.75rem;
}
.br-1 {
  border-radius: 1rem;
}
.text-vertical {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  /* background: var(--q-1); border-radius: 1rem; */
  width: 50px;
  padding: 15px;
}
.q-drawer {
  background: transparent !important;
}

.zoom-enter-active,
.zoom-leave-active {
  transition: all 0.15s ease-in-out;
}
.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
  /* transform: scale(0.99); */
}
.zoom-leave-from,
.zoom-enter-to {
  opacity: 1;
  /* transform: scale(1); */
}
.list-complete {
  backface-visibility: hidden;
}
.list-complete-item {
  transition: all 1s ease;
}
.list-complete-move {
  transition: all 0.5s;
  /* transform: translateX(30px); */
}
.list-complete-leave-active {
  transition: all 0.5s ease;
  position: fixed;
}
.list-complete-enter-active {
  transition: all 0.5s ease;
}
.list-complete-enter-from,
.list-complete-leave-to {
  opacity: 0;
  transform: scale(0);
}
.list-complete-leave-from,
.list-complete-enter-to {
  opacity: 1;
  transform: scale(1);
}

.home {
  z-index: 1;
  backface-visibility: hidden;
}
.home-item {
  transform-origin: center left;
  /* transition: all 1s ease; */
}
.home-move {
  transition: all 0.5s;
  /* position: fixed; */
  /* transform: translateX(30px); */
}
.home-leave-active {
  /* position: absolute;
      bottom: 0; right: 0; */
  /* top: 50%; left: 50%; */
  /* position: absolute; opacity: 0; */
  transition-delay: 0s;
  transition: all 0.2s ease;
}
.home-enter-active {
  transition-delay: calc(0.05s * var(--delay)) !important;
  transition: all 0.5s ease;
}
.home-enter-from,
.home-leave-to {
  opacity: 0;
  /* position: sticky; */
  transform: scale(0.5);
}
.home-leave-from,
.home-enter-to {
  opacity: 1;
  transform: scale(1);
}

.masonry {
  column-width: 220px;
  column-gap: 0;
}
.masonry-bigger {
  column-width: 200px;
  column-gap: 0;
}
.card {
  display: inline-block;
}
@media screen and (max-width: 600px) {
  .masonry {
    column-width: 500px;
    column-gap: 0;
  }
  .masonry-bigger {
    column-width: 190px;
    column-gap: 0;
  }
}

.commit:hover {
  color: var(--q-5);
}
.commit:hover .commit-sub {
  color: var(--q-3);
}
.commit:hover::before {
  background: var(--q-3);
}
.commit-sub {
  transition-duration: 0.6s;
  color: var(--q-0);
}
.commit {
  color: var(--q-0);
  transition-duration: 0.4s;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  /* background: var(--q-1); border-radius: 2em; */
  width: 50px;
  margin: 0 auto;
  padding: 8px 15px;
}
.commit::before {
  transition-duration: 0.2s;
  content: "#";
  background: var(--q-0);
  color: var(--q-0);
  font-weight: 1000;
  border-radius: 1em;
  padding: 0.6rem 0.3rem;
}
.glassy-8 {
  box-shadow: inset 0 0 8rem 0 var(--q-0);
  /* backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px); */
}

.glassy-qf-8 {
  box-shadow: inset 0 0 8rem 0 var(--q-f);
  /* backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px); */
}

/* If body empty for some items in tree it's big padding between elements (see library page)*/
.q-tree__node-body {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.landing-background-20dd0442-web {
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  height: 100vh;
  width: 100vw;
  /* background: url("/landing-2022-08-01-18-10-13.jpeg") 4% 30% no-repeat; */
  transform: scale(1.3) rotate(-3deg);
  opacity: 0.4135;
}

.landing-background-20dd0442-mob {
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  height: 100vh;
  width: 100vw;
  /* background: url("/landing-2022-08-01-18-10-13.jpeg") 4% 60%; */
  transform: scale(1.2) rotate(-3deg);
  opacity: 0.4135;
}
</style>
