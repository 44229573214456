<template>
  <div class="row no-wrap">
    <q-btn
      dense
      unelevated
      no-caps
      aria-label="Voccent"
      class="text-v round-both q-py-none"
      :class="$q.screen.gt.sm ? 'q-px-md' : 'q-px-sm'"
      style="cursor: url('Cc.svg'), auto !important; height: 3rem"
      to="/"
      @mouseleave="hit = false"
    >
      <!-- <q-img 
        :height='$q.screen.gt.sm ? "2rem" : "1.4rem"'
        :width='$q.screen.gt.sm ? "95px" : "65px"'
        :class='$q.screen.gt.sm ? "q-mb-xs" : ""'
        class="text-v"
        src=Voccent.svg 
        no-spinner
      /> -->
      <svg
        version="1.1"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 1250 400"
        :height="$q.screen.gt.sm ? '2rem' : '1.4rem'"
        :width="$q.screen.gt.sm ? '95px' : '65px'"
        :class="$q.screen.gt.sm ? '' : ''"
        class="bg-transparent"
        style="stroke: currentColor"
      >
        <symbol id="voccent3000">
          <path d="M355.87 98.92C352.91 122.85 383.74 130.24 375.1 155" />
          <path
            d="M305.85 122.5C188.02 148.38 138.24 231.27 156.51 371.17L120.01 371.25L41.89 140"
          />
          <path
            d="M380.88 243.3C352.88 214.71 303.51 215.37 275.23 243.5C246.94 271.63 246.28 320.31 274.51 348.66C302.75 377 351.43 377.99 380.35 349.28C409.26 320.56 404.9 268.35 380.88 243.3Z"
          />
          <path
            d="M514.87 387.09C465.07 242.99 540.53 87.41 685.36 37.54"
            @mousemove="hit = true"
            :class="hit ? 'text-h' : ''"
          />
          <path
            d="M608.96 353.46C577.28 261.76 625.29 162.76 717.45 131.02"
            :class="hit ? 'text-h' : ''"
          />
          <path
            d="M727.84 341.4C795.32 276.55 828.56 243.78 827.55 243.09C799.55 214.49 750.18 215.16 721.89 243.29C693.61 271.42 692.94 320.1 721.18 348.44C749.42 376.79 798.1 377.78 827.01 349.06"
          />
          <path
            d="M1007.25 385.75C1028.15 332.83 1024.07 234.95 1008.44 215C998.02 201.7 968.38 219.86 919.5 269.5C926.14 307.99 919.23 346.07 898.8 383.75"
          />
          <path
            d="M1225.61 364.33C1020.07 421.65 1171.98 166.24 1141.11 82.7"
          />
          <path
            d="M1076.82 174.78C1106.82 163.25 1155.66 214.22 1197.82 206.03"
          />
        </symbol>
        <use xlink:href="#voccent3000" stroke-width="50" fill="none" />
      </svg>
    </q-btn>
    <div
      class="nice row no-wrap q-my-auto q-pl-sm q-pt-xs"
      style="white-space: nowrap"
      :class="$q.screen.gt.sm ? 'q-gutter-x-lg' : 'q-gutter-x-md'"
    >
      <div class="text-h round-both q-px-sm">Report</div>
    </div>
  </div>
</template>

<style scoped>
.bloglink {
  cursor: pointer;
}
</style>

<script>
export default {
  data() {
    return {
      hit: false,
    };
  },
};
</script>

<style scoped>
.nice {
  user-select: none;
  background: linear-gradient(90deg, var(--q-v), var(--q-h));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
</style>
