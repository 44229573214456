import api from "src/h/api.js";
import h from "src/h/helpers.js";
import Library from "src/pages/library/index.vue";

import { Notify } from "quasar";

export default {
  name: "PickerPicture",
  components: {
    Library,
  },
  emits: ["changed", "deleted", "saved", "statusChanged", "errorSaved"],
  props: {
    data: {
      // {uid, objectid, objecttype, params, parentid, clearaftersave, ordernum}
      type: Object,
      default: function () {
        return {};
      },
    },
    view: {
      // {width, height, fit, onlyshow, showifexists, bgcolor, borderradius, border, alwaysget, hidedelete,
      // showdefault, showdefaultsrc, bgdefaultcolor, showerrorsaved}
      // showifexists - actual for onlyshow = true (if showifexists=true don't show default value if null)
      // set width for auto-height
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      incomeData: {
        uid: null,
        objectid: null,
        objecttype: null,
        clearaftersave: true, // by default
        ordernum: null,
      },
      showdefault: true,
      status: null, // empty (no link in database), loaded (have link in database), changed (choose new image)
      statusExt: false, // true if saved in database
      uploadCreated: false,
      fileType: {},
      newFile: null,
      saving: false,
      link: null, // need save this link from http or from loaded by q-upload
      showFullImage: false,
      showLibraryDialog: false,
      showLibraryDialogMax: true,
      libraryData: {
        type: null,
        objectid: null,
        fileType: "library_img",
      },
      campuses: [],
      campusesLoaded: false,
      fileChosen: null,
    };
  },
  beforeMount() {
    h.CopyObject(this.data, this.incomeData);
    if (this.view.showdefault !== null && this.view.showdefault !== undefined) {
      this.showdefault = this.view.showdefault;
    }
  },
  mounted() {
    if (this.incomeData.objectid) {
      // get from source
      this.link =
        process.env.PUBLIC_API_URL +
        "/api/v1/asset/object/" +
        this.incomeData.objecttype +
        "/" +
        this.incomeData.objectid +
        (this.view?.onlyshow === true && !this.view?.alwaysget
          ? ""
          : "?t=" + h.GetNanoSecTime());
    } else {
      // need upload image (now it's empty)
      //actual if objectid is empty (choose image before create challenge for example)
      this.link = null;
      this.ImageLoadError();
    }
  },
  computed: {
    defaultObjectLibrary() {
      return this.$store.getters.getObjectLibrary(this.data.parentid);
    },
  },
  watch: {
    status: {
      handler() {
        this.$emit("statusChanged", {
          obj_id: this.incomeData.uid,
          status: this.status,
        });
      },
    },
  },
  methods: {
    ImageLoadError() {
      this.status = "empty";
      this.statusExt = false;
    },
    ImageLoadSuccess() {
      this.status = "loaded";
      this.statusExt = true;
    },
    CreateUpload() {
      // create upload component once, in other case only show
      let lnk = this;
      let fnc = function () {
        if (!lnk.uploadCreated) {
          lnk.uploadCreated = true;
        }
        setTimeout(() => {
          lnk.$refs["qUploadFilePicker_" + lnk.incomeData.uid].reset();
          setTimeout(() => {
            lnk.$refs["qUploadFilePicker_" + lnk.incomeData.uid].pickFiles();
          }, 10);
        }, 10);
      };
      this.GetFileType().then(fnc);
    },
    GetFileType() {
      if (this.fileType.id) {
        // get once
        return new Promise((resolve, reject) => {
          resolve();
        });
      }
      return new Promise((resolve, reject) => {
        api
          .Call({
            url: "/api/v1/dictionary/filetype/" + this.incomeData.objecttype,
            show_error: true,
          })
          .then((response) => {
            if (response.extension_mask) {
              let res = response.extension_mask.split(",");
              res.forEach(function (sbitem, inx) {
                res[inx] = "." + sbitem;
              });
              response.extension_mask_ = res.join(",");
            }
            this.fileType = response;
            resolve();
          });
      });
    },
    AddFile(files) {
      if (files.length > 0) {
        this.newFile = files[0];
      } else {
        this.newFile = {};
      }
      this.fileChosen = null;
      this.status = "changed";
      this.$emit("changed");
    },
    CancelChange() {
      if (this.statusExt === true) {
        this.status = "loaded";
      } else {
        this.status = "empty";
      }
      this.newFile = {};
      this.fileChosen = null;
    },
    DeleteFile() {
      this.status = "empty";
      this.$emit("deleted");
    },
    FilterUploader(files) {
      // if we use 'max-size' in q-uploader param, we can't check and show message here (so we delete files below if max_size)
      if (this.fileType.max_size && files.length > 0) {
        if (this.fileType.max_size < files[0].size) {
          Notify.create({
            group: true,
            timeout: 10000,
            message:
              "The maximum file size is " +
              Math.round(this.fileType.max_size / 1000, 0) +
              " kB",
            color: "1",
            textColor: "n",
            classes: "round-both q-ml-lg q-mb-sm",
            position: "bottom",
          });
          files.splice(0, files.length);
        }
      }

      // if we change file extension the mime type maybe replaced too (so the check below can be useless)
      if (this.fileType.mime_type && files.length > 0) {
        if (this.fileType.mime_type !== files[0].type.split("/")[0]) {
          // main mime-type
          Notify.create({
            group: true,
            timeout: 10000,
            message:
              "The signature of the file are incorrect. Need " +
              this.fileType.mime_type +
              " file",
            color: "1",
            textColor: "n",
            classes: "round-both q-ml-lg q-mb-sm",
            position: "bottom",
          });
          files.splice(0, files.length);
        }
      }
      return files;
    },
    setObjectID(obj) {
      this.incomeData.objectid = obj;
    },
    setObjectType(obj) {
      this.incomeData.objecttype = obj;
    },
    Save() {
      //save current status
      if (this.newFile?.__img || this.fileChosen !== null) {
        let isNew = false;
        if (this.newFile?.__img) {
          isNew = true;
        }
        return new Promise((resolve, reject) => {
          // if new file was choose (auto-delete in backend if need)
          this.saving = true;

          const formData = new FormData();
          formData.append("object_id", this.incomeData.objectid);
          formData.append("file_type", this.incomeData.objecttype);

          if (isNew) {
            formData.append("file", this.newFile);
          } else {
            formData.append("file_object_id", this.fileChosen);
          }
          if (this.data.params) {
            formData.append("params", JSON.stringify(this.data.params));
          }
          if (this.data.sessionid) {
            formData.append("sessionid", this.data.sessionid);
          }
          // actual for multiple upload (genai logic)
          if (this.incomeData.ordernum) {
            formData.append("ordernum", this.incomeData.ordernum);
          }
          api
            .Call({
              url: "/api/v1/asset",
              data: formData,
              method: "post",
              show_error: this.view.showerrorsaved === false ? false : true, // by default true
            })
            .then(
              (assetresp) => {
                var lnk = this;
                if (isNew) {
                  this.link = this.newFile.__img.src;
                } else {
                  this.link =
                    "/api/v1/asset/file/library_img/" + this.fileChosen;
                }
                this.statusExt = true;
                // 'link' above can't show instantly. So if set status = "loaded" without timeout
                // the picture will show old value, and after that show new value (by new link) in UI
                setTimeout(() => {
                  lnk.status = "loaded";
                  // we need false if save from same picker for other object (authoring - classroom/campus)
                  if (lnk.incomeData.clearaftersave === true) {
                    lnk.newFile = {};
                    lnk.fileChosen = null;
                  }
                  lnk.saving = false;
                  lnk.$emit("saved");
                }, 10);
                resolve();
              },
              (e) => {
                this.saving = false;
                this.$emit("errorSaved", e);
                reject();
              },
            );
        });
      } else if (this.statusExt === true && this.status === "empty") {
        // if file was loaded, but we cleared in UI
        return new Promise((resolve, reject) => {
          // need only delete
          this.saving = true;
          let data = {};
          if (this.data.params) {
            data = this.data.params;
            data.SessionID = this.data.sessionid;
          }
          api
            .Call({
              url:
                "/api/v1/asset/object/" +
                this.incomeData.objecttype +
                "/" +
                this.incomeData.objectid,
              data: data,
              method: "delete",
              show_error: true,
            })
            .then(
              (assetresp) => {
                this.link = null;
                this.statusExt = false;
                this.status = "empty";
                this.newFile = {};
                this.fileChosen = null;
                this.saving = false;
                resolve();
              },
              (e) => {
                this.saving = false;
                reject();
              },
            );
        });
      } else {
        return new Promise((resolve, reject) => {
          resolve();
        });
      }
    },
    ShowFullImageDialog() {
      this.showFullImage = true;
    },
    OnShowChooseLibrary() {
      if (this.campusesLoaded === false) {
        api
          .Call({
            url: "/api/v1/campus/user?status=current",
            method: "get",
            show_error: true,
          })
          .then(
            (assetresp) => {
              this.campuses = assetresp;
              this.campusesLoaded = true;
            },
            (e) => {},
          );
      }
    },
    OnChooseLibrary(libraryType, objectID, objectName) {
      switch (libraryType) {
        case "system":
          this.libraryData.type = null;
          this.libraryData.objectid = null;
          this.showLibraryDialog = true;
          break;
        case "personal":
          this.libraryData.type = "user";
          this.libraryData.objectid = this.$store.getters.user.ID;
          this.showLibraryDialog = true;
          break;
        case "campus":
          this.libraryData.type = "campus";
          this.libraryData.objectid = objectID;
          if (this.data.parentid) {
            this.$store.commit("setObjectLibrary", {
              key: this.data.parentid,
              value: {
                CampusID: objectID,
                CampusName: objectName,
              },
            });
          }
          this.showLibraryDialog = true;
          break;
      }
      this.fileChosen = null;
    },
    chooseFile(fileObjectID) {
      this.fileChosen = fileObjectID;
    },
    chooseFileConfirm() {
      this.newFile = {};
      this.status = "changed";
      this.$emit("changed");
    },
  },
};
