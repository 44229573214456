import api from "src/h/api.js";
import h from "src/h/helpers.js";
export default {
  state: {
    attempts: [],
    attempttab: "info", // users, current
    attemptsShared: [],
    attemptID: null, // selected attempt (from attempts or attemptsShared)
    currentAttempt: {}, // last attempt created
    accept: {
      AttemptCreatedAt: null,
      AttemptID: null, // best attempt
      AudioSampleTstID: null,
      AudioSampleRefID: null,
      ChallengeID: null,
      TotalPercent: 0,
      PronunciationCompResultID: null,
      CountAttempts: 0,
      DifficultyMatch: 0,
      Rating: 0, // user's rating
      ShareAll: null, // ['voice', 'percent']
      ShareAuthor: null, // ['voice', 'percent']
      createdat: null,
      createdby: null,
      updatedat: null,
    },
    createdChallenge: {
      Rating: 0, // overall rating
      CountRating: 0, // number of users that rated the challenge
      Difficulty: 0,
    },
    createdChallengeSpellings: {},
    isPlaylistEnded: null,
    challengeLastViewID: false,
    refAnnotations: null,
    tstAnnotations: null,
    segmentMapping: null,
    attemptAudiosampleRefId: null,
    compareResult: {}, // percent result (emotions, breath, ...)
    currentChallenge: {
      ID: null,
      Name: null,
    },
    currentChallengeAccess: {},
    challengeStudioOperation: null,
  },
  mutations: {
    tabInfo(state) {
      state.attempttab = "info";
    },
    tabCurrent(state) {
      state.attempttab = "current";
    },
    tabUsers(state) {
      state.attempttab = "users";
    },
    createChallenge: (state, payload) => {
      if (!payload) {
        payload = {
          Rating: 0,
          CountRating: 0,
          Difficulty: 0,
        };
      }
      if (!payload.Rating) {
        payload.Rating = 0;
      }
      state.createdChallenge = payload;
      state.attempttab = "info";
    },
    setCreatedChallengeSpellings: (state, payload) => {
      state.createdChallengeSpellings = payload;
    },
    setCreatedChallengeSpellingsCurrentLang: (state, payload) => {
      if (!state.createdChallengeSpellings) {
        state.createdChallengeSpellings = {};
      }
      state.createdChallengeSpellings.currentTranslationLang = payload;
    },
    isPlaylistEnded: (state, payload) => {
      state.isPlaylistEnded = payload;
    },
    setAcceptRating: (state, payload) => {
      state.accept.Rating = payload;
    },
    setChallengeRating: (state, payload) => {
      state.createdChallenge.Rating = payload.Rating ? payload.Rating : 0;
      state.createdChallenge.CountRating = payload.CountRating;
    },
    setChallengeLastViewID: (state, payload) =>
      (state.challengeLastViewID = payload),
    addAttempts: (state, payload) => {
      state.attempts = payload.attempts
        .concat(state.attempts)
        .sort((a, b) => b.TotalPercent - a.TotalPercent);
    },
    setAttempt: (state, payload) => {
      h.CopyObject(payload.attempt, state.attempts[payload.num]);
    },
    setAttemptShared: (state, payload) => {
      h.CopyObject(payload.attempt, state.attemptsShared[payload.num]);
    },
    addAttemptsShared: (state, payload) => {
      if (payload.clear === true) {
        state.attemptsShared.splice(0, state.attemptsShared.length);
      }
      state.attemptsShared = payload.attemptsShared
        .concat(state.attemptsShared)
        .sort((a, b) => b.TotalPercent - a.TotalPercent);
    },
    deleteAttemptShared: (state, payload) => {
      // payload - index
      state.attemptsShared.splice(payload, 1);
    },
    deleteAttempt: (state, payload) => {
      // payload - index
      state.attempts.splice(payload, 1);
    },
    resetAttempts: (state) => {
      state.attempts = [];
    },
    resetAnnotations: (state) => {
      state.refAnnotations = [];
      state.tstAnnotations = [];
    },
    resetAttemptsShared: (state) => {
      state.attemptsShared = [];
    },
    resetAccept: (state, payload) => {
      if (payload === undefined) {
        payload = null;
      }
      state.accept = payload;
    },
    resetCreatedChallenge: (state, payload) => {
      state.createdChallenge = {
        Rating: 0,
        CountRating: 0,
        Difficulty: 0,
      };
    },
    resetCreatedChallengeSpellings: (state, payload) => {
      state.createdChallengeSpellings = {};
    },
    setAttemptID: (state, payload) => {
      state.attemptID = payload;
    },
    setCurrentAttempt: (state, payload) => {
      state.currentAttempt = payload;
    },
    setAccept: (state, payload) => {
      state.accept = payload;
    },
    setRefAnnotations: (state, payload) => {
      // state.refAnnotations.splice(0, state.refAnnotations.length, ...payload);
      state.refAnnotations = payload;
    },
    setTstAnnotations: (state, payload) => {
      state.tstAnnotations = payload;
      // debugger;
    },
    setCompareResult: (state, payload) => {
      state.compareResult = payload;
    },
    setSegmentMapping: (state, payload) => {
      state.segmentMapping = payload;
    },
    setAttemptAudiosampleRefId: (state, payload) => {
      state.attemptAudiosampleRefId = payload;
    },
    setCurrentChallenge: (state, payload) => (state.currentChallenge = payload),
    setCurrentChallengeAccess: (state, payload) =>
      (state.currentChallengeAccess = payload),
    setChallengeStudioOperation: (state, payload) =>
      (state.challengeStudioOperation = payload),
  },
  actions: {
    resetCreatedChallenge: ({ commit }) => {
      commit("resetCreatedChallenge");
    },
    resetCreatedChallengeSpellings: ({ commit }) => {
      commit("resetCreatedChallengeSpellings");
    },
    resetAttempts: ({ commit }) => {
      commit("resetAttempts");
    },
    resetAnnotations: ({ commit }) => {
      commit("resetAnnotations");
    },
    resetAttemptsShared: ({ commit }) => {
      commit("resetAttemptsShared");
    },
    resetSample: ({ commit }) => {
      commit("setAudioSampleID", null);
      commit("deleteBlob", "tst");
      commit("resetFeatures");
      commit("resetDiffs");
      commit("resetPitch");
      commit("resetEnergy");
      commit("resetBreath");
      commit("resetEmotion");
    },
    resetAccept: ({ commit }) => {
      commit("resetAccept");
    },
    resetAll: ({ dispatch, commit }) => {
      dispatch("resetCreatedChallenge");
      dispatch("resetCreatedChallengeSpellings");
      dispatch("resetAttempts");
      dispatch("resetAnnotations");
      dispatch("resetAttemptsShared");
      dispatch("resetSample");
      commit("deleteBlob", "ref");
      commit("setAttemptID", null);
      commit("setCurrentAttempt", {});
      commit("isPlaylistEnded", null);
      commit("setAttemptAudiosampleRefId", null);
    },
    upChallengeView({ commit, state }) {
      if (
        !state.createdChallenge ||
        !state.createdChallenge.ID ||
        state.createdChallenge?.ID === state.challengeLastViewID
      ) {
        return;
      }
      commit("setChallengeLastViewID", state.createdChallenge?.ID);
      api
        .Call({
          url: "/api/v1/challenge/upview/" + state.createdChallenge?.ID,
          method: "post",
        })
        .then(
          (challengeResponse) => {
            // count: challengeResponse
          },
          (e) => {
            console.error("error-71d7538d:", "/api/v1/challenge/upview/", e);
          },
        );
    },
  },
  getters: {
    attempts: (state) => state.attempts,
    attemptsShared: (state) => state.attemptsShared,
    attemptID: (state) => state.attemptID,
    currentAttempt: (state) => state.currentAttempt,
    accept: (state) => state.accept,
    createdChallenge: (state) => state.createdChallenge,
    createdChallengeSpellings: (state) => state.createdChallengeSpellings,
    getAttemptNumByID: (state) => (ID) => {
      // TODO: avoid getters with attributes, use methods/mixins instead
      for (var i = 0; i < state.attempts.length; i++) {
        if (ID === state.attempts[i].ID) {
          return i;
        }
      }
      return null;
    },
    getAttemptNumBySampleID: (state) => (ID) => {
      // TODO: avoid getters with attributes, use methods/mixins instead
      for (var i = 0; i < state.attempts.length; i++) {
        if (ID === state.attempts[i].AudioSampleTstID) {
          return i;
        }
      }
      return null;
    },
    getAttemptSharedNumByID: (state) => (ID) => {
      // TODO: avoid getters with attributes, use methods/mixins instead
      for (var i = 0; i < state.attemptsShared.length; i++) {
        if (ID === state.attemptsShared[i].ID) {
          return i;
        }
      }
      return null;
    },
    getAttemptSharedNumBySampleID: (state) => (ID) => {
      // TODO: avoid getters with attributes, use methods/mixins instead
      for (var i = 0; i < state.attemptsShared.length; i++) {
        if (ID === state.attemptsShared[i].AudioSampleTstID) {
          return i;
        }
      }
      return null;
    },
    getAttemptSharedNumByUser: (state) => (UserName) => {
      // TODO: avoid getters with attributes, use methods/mixins instead
      for (var i = 0; i < state.attemptsShared.length; i++) {
        if (UserName === state.attemptsShared[i].UserName) {
          return i;
        }
      }
      return null;
    },
    getRefAnnotations: (state) => state.refAnnotations,
    getTstAnnotations: (state) => state.tstAnnotations,
    getCompareResult: (state) => state.compareResult,
    getSegmentMapping: (state) => state.segmentMapping,
    getAttemptAudiosampleRefId: (state) => state.attemptAudiosampleRefId,
    currentChallenge: (state) => state.currentChallenge,
    currentChallengeAccess: (state) => state.currentChallengeAccess,
    challengeStudioOperation: (state) => state.challengeStudioOperation,
  },
};
