export default {
  state: {
    currentLibrary: {},
  },
  getters: {
    currentLibrary: (state) => state.currentLibrary,
  },
  mutations: {
    setCurrentLibrary: (state, payload) => {
      state.currentLibrary = payload;
    },
  },
};
