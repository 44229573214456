<template>
  <div>
    <q-dialog v-model="showVideoDialog" class="z-max">
      <div
        class="full-width column items-end"
        style="box-shadow: none; max-width: 70vw"
      >
        <q-btn round flat icon="close" color="n" v-close-popup />
        <!-- use for static video -->
        <!-- <vue-plyr>
        <video
          disableContextMenu
          hideControls
          crossorigin
          playsinline
          clickToPlay
          data-poster="/video/intro/eeeeee-background.png"
        >
          <source :src="videoStaticUrl()" type="video/mp4" />
        </video>
      </vue-plyr> -->

        <!-- use for youtube video -->
        <vue-plyr style="border: 0.2rem solid #343536; border-radius: 2rem">
          <div class="plyr__video-embed" style="width: 100vw">
            <iframe
              :src="
                'https://www.youtube.com/embed/' +
                  videoYoutubeUrl() +
                  '?vq=hd1080&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1'
              "
              allowfullscreen
              allowtransparency
              allow="autoplay"
            ></iframe>
          </div>
        </vue-plyr>
      </div>
    </q-dialog>

    <q-btn
      round
      flat
      class="pulse text-e"
      style="height: 3rem; width: 3rem; border-radius: 1rem"
      @click="showVideo()"
    >
      <q-tooltip
        anchor="center right"
        self="center left"
        :offset="[32, 0]"
        class="bg-2 text-9 mytooltip ubuntu text-0 row round-1 q-px-md items-center"
        transition-show="jump-right"
        transition-hide="jump-left"
      >
        {{ $t("-raw-main-video") }}
      </q-tooltip>
      <q-icon size="3rem" name="videocam" />
    </q-btn>
  </div>
</template>

<style scoped>
.pulse {
  border-radius: 1rem;
  background: var(--q-0);
  cursor: pointer;
  transition: 1s ease all;
  animation: pulsebetter 1s ease-in infinite alternate;
}
.pulse:hover {
  background: var(--q-1);
  animation: none;
}
@keyframes pulsebetter {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--q-0);
    background: var(--q-0);
  }
  50% {
    box-shadow: 0 0 0 5px var(--q-2);
    background: var(--q-2);
  }

  100% {
    box-shadow: 0 0 0 0 var(--q-0);
    background: var(--q-0);
  }
}
</style>

<script>
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import Lib from "src/h/helpers.js";

export default {
  props: ["autoshow"],
  components: { VuePlyr },
  data: () => ({
    showVideoDialog: false,
    welcomeVideo: false
  }),
  mounted: function() {
    console.debug("this.autoshow", this.autoshow);
    if (this.autoshow) {
      setTimeout(() => {
        this.showWelcomeVideo();
      }, 1000);
    }
  },
  methods: {
    showWelcomeVideo() {
      if (!Lib.getItem("VOC_WELCOME_VIDEO_SHOWN")) {
        setTimeout(() => {
          // NOTE: We use delay because discovery being created twice: 6cda6389
          Lib.setItem("VOC_WELCOME_VIDEO_SHOWN", true);
        }, 30000);
        this.welcomeVideo = true;
        this.showVideo();
      } else {
        this.welcomeVideo = false;
      }
      console.debug("debug-bd5b1131: show welcomeVideo: ", this.welcomeVideo);
    },
    showVideo() {
      this.showVideoDialog = !this.showVideoDialog;
      // register event at Google Analytics https://analytics.google.com/analytics/ -> Behavior -> Events
      gtag("event", "showHelpVideo", {
        event_category: "Video",
        event_label: "Help video " + this.$route.meta.title + " opened"
      });
    },
    videoYoutubeUrl() {
      switch (this.$route.name) {
        case "-raw-route-channel":
          return "AMfs5k4h6WM";
        case "-raw-route-challenge":
          return "gZId6XIEbTE";
        default:
          return "TpQBaGh__s0";
      }
    },
    videoStaticUrl() {
      switch (this.$route.name) {
        case "-raw-route-channel":
          return "/video/help-video/channel.mp4";
        case "-raw-route-challenge":
          return "/video/help-video/challenge.mp4";
        default:
          return "/video/help-video/discovery.mp4";
      }
    }
  }
};
</script>
