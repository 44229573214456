import script from "./layout.vue?vue&type=script&setup=true&lang=js"
export * from "./layout.vue?vue&type=script&setup=true&lang=js"

import "./layout.vue?vue&type=style&index=0&id=578f1199&lang=css"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QBtn,QSpace,QImg});
