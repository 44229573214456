import { createI18n } from "vue-i18n";
import messages from "src/i18n.json";

const i18n = createI18n({
  locale: "en-US",
  messages,
});

export default ({ app }) => {
  // console.log('%cboot/i18n.js', 'background: black; font-weight: bold; color: white;')

  // Set i18n instance on app
  app.use(i18n);
};

export { i18n };
