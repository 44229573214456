<script src="./layout.js"></script>

<template>
  <q-layout 
    class="bg-0"
    view="hHh LpR fFr"
  >

    <q-header 
      class="bg-0 justify-center row no-wrap text-5 q-pa-md" :class="$q.screen.gt.sm ? '' : 'justify-between'"
      style="font-weight: 1000; font-size: 1.75rem; z-index: 69 !important; height: calc(5rem + 2px) !important; border-bottom: 2px solid var(--q-2);"
      :style="$q.screen.gt.sm ? 'font-size: 1.75rem' : 'font-size: 1.25rem'"
    >
      <div class="col">
        <finish-sign-up-title-bar key="titlebar"/>
      </div>
    </q-header>

    <!-- MAIN -->
    <q-page-container class="row wrap">
      <q-page class="row col q-ma-none">
        <router-view v-slot="{ Component }"  >
          <transition name="zoom" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </q-page>
    </q-page-container>

  </q-layout>
</template>
