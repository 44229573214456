<template>
  <div v-for="c in options" :key="c.ID">
    <q-img
      :src="channelAvatarURL(c)"
      fit="contain"
      class="bg-1 channel"
      style="border-radius: 1rem; height: 3rem; width: 3rem"
      :style="
        c.ID == selected?.ID
          ? 'border: 4px solid var(--q-v);'
          : 'border: 4px solid var(--q-0);'
      "
      @click="$emit('select', c)"
    ></q-img>
    <q-tooltip
      anchor="top middle"
      self="bottom middle"
      :offset="[0, 24]"
      :class="c.ID == selected?.ID ? 'bg-v' : 'bg-1 text-9'"
      class="mytooltip ubuntu text-0 row round-1 items-center q-px-lg"
      transition-show="jump-up"
      transition-hide="jump-down"
    >
      {{ c.Name }}
    </q-tooltip>
  </div>
</template>

<style scoped>
.channel {
  transition: 0.3s ease;
}
.channel:hover {
  transform: scale(1.25) translateY(-0.25rem);
}
</style>

<script>
export default {
  emits: ["select"],
  props: ["selected", "options"],
  methods: {
    channelAvatarURL(channel) {
      const assetID = channel.Asset?.channel_avatar;
      return channel.avatar
        ? URL.createObjectURL(channel.avatar, { oneTimeOnly: true })
        : assetID
        ? process.env.PUBLIC_API_URL +
          "/api/v1/asset/file/channel_avatar/" +
          assetID[0]
        : "";
    },
  },
};
</script>
