<template>
  <div class="col-3">
    <q-btn
      flat
      rounded
      :label="$i18n.locale.slice(0, 2)"
      class="text-8 bg-1"
      style="
        width: 3rem;
        height: 3rem;
        margin: 0 !important;
        font-weight: 1000;
        font-size: 1.15rem;
      "
    >
      <q-menu
        transition-show="jump-left"
        transition-hide="jump-right"
        auto-close
        id="localizator"
        :offset="direction ? [12, 80] : [0, 16]"
        :anchor="direction ? 'bottom right' : 'top right'"
        :self="direction ? 'bottom left' : 'bottom right'"
        class="no-shadow z-max"
        style="border-left: 1px solid var(--q-2); scroll-behavior: smooth"
      >
        <!-- :class="{'borderneubottomleft': neu, 'borderoutlinebottomleft': !neu }" -->
        <div class="bg-0">
          <q-btn
            flat
            fit
            class="z-top full-width"
            style="position: sticky; top: 0"
            icon="keyboard_arrow_up"
            @mouseover="scrollToFirst()"
            @click.stop=""
          />
          <!-- <q-select
        borderless
        color="v"
        v-model="language"
        :options="langs"
        option-value="locale"
        option-label="name"
        :menu-offset="[0, 16]"
        popup-content-style="border-radius: 1rem !important;"
        style="border-radius: 1rem !important; width: 100%"
        behavior="menu"
      >
        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey">{{ $t('-raw-setting-no-reults') }}</q-item-section>
          </q-item>
        </template>
      </q-select> -->
          <q-item
            v-for="item in [...languages].sort((a,b)=>a.localName.localeCompare(b.localName))"
            :key="item.name"
            :id="item.name"
            @click="language = item"
            clickable
            v-ripple
          >
            <q-item-section>
              <q-tooltip
                :offset="[24, 0]"
                :anchor="direction ? 'center start' : 'center end'"
                :self="direction ? 'center end' : 'center start'"
                class="mytooltip round-both ubuntu bg-0 text-8 row items-center q-px-md"
                :transition-show="direction ? 'jump-left' : 'jump-right'"
                :transition-hide="direction ? 'jump-right' : 'jump-left'"
                :delay="500"
              >
                {{ h.capitalize(item.name) }}
              </q-tooltip>
              {{ h.capitalize(item.localName || item.name) }}
            </q-item-section>
          </q-item>
          <q-btn
            flat
            fit
            style="position: sticky; bottom: 0"
            class="z-top full-width"
            icon="keyboard_arrow_down"
            @mouseover="scrollToLast()"
            @click.stop=""
          />
        </div>
      </q-menu>
    </q-btn>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import h from "src/h/helpers.js";
export default {
  props: ["direction"],
  data() {
    return {
      language: null,
    };
  },
  methods: {
    // NOTE: Safari doesn't support smooth scrolling
    scrollToFirst() {
      // const target = document.querySelector(`#${this.languages[0].name}`);
      // target.scrollIntoView({ behavior: "smooth", block: "end" });
      const container = document.querySelector("#localizator");
      container.scrollTo({ top: 0, behavior: "smooth" });
    },
    scrollToLast() {
      // const target = document.querySelector(
      //   `#${this.languages[this.languages.length - 1].name}`
      // );
      // target.scrollIntoView({ behavior: "smooth", block: "start" });
      const container = document.querySelector("#localizator");
      container.scrollTo({ top: container.scrollHeight, behavior: "smooth" });
    },
  },
  computed: {
    ...mapGetters(["languages"]),
    h() {
      return h;
    },
  },
  mounted() {
    this.language = JSON.parse(
      localStoragePolyFill.getItem("interface language"),
    );
  },
  watch: {
    language: function (val) {
      if (val) {
        this.$store.commit("setLanguageUI", val);
        this.$i18n.locale = val.locale;
        localStoragePolyFill.setItem("interface language", JSON.stringify(val));
        this.$store.commit("updateLanguagesTranslation");
      }
    },
  },
};
</script>
