<template>
  <q-dialog class="z-max" v-model="showDialog">
    <q-card
      style="max-width: 500px; border-radius: 1rem"
      class="no-shadow bg-1"
    >
      <q-card-section class="column items-center">
        <span class="q-mx-md no-wrap" style="font-size: 1rem"
          >{{ message }}:</span
        >
        <q-tabs
          v-model="activeTab"
          dense
          indicator-color="v"
          align="justify"
          narrow-indicator
          class="text-9 bg-0 ubuntu text-grey full-width"
          active-color="v"
          active-bg-color="2"
          style="min-height: 3rem"
        >
          <q-tab
            class="tab"
            name="Playlists"
            :label="
              $t('-raw-tab-playlists') !== '-raw-tab-playlists'
                ? $t('-raw-tab-playlists')
                : 'Playlists'
            "
          />
          <!-- <q-tab
               class="tab"
               name="Lessons"
               :label="
               $t('-raw-tab-lessons') !== '-raw-tab-lessons'
               ? $t('-raw-tab-lessons')
               : 'Lessons'
               "
               /> -->
        </q-tabs>

        <q-separator />

        <q-tab-panels v-model="activeTab" class="bg-1 full-width">
          <q-tab-panel
            name="Playlists"
            style="max-height: 2048px; height: 50vh"
            class="q-pa-none"
          >
            <q-infinite-scroll
              v-show="playlists && playlists?.length > 0"
              :offset="200"
              class="row col justify-center q-py-md"
            >
              <div
                v-for="playlist in playlists"
                :key="playlist.ID"
                class="full-width q-pa-sm"
                ref="playlist"
                style="display: inline-block"
              >
                <playlist
                  :item="playlist"
                  :ref="playlist.ID"
                  show-info="true"
                  @deletePlaylist="
                    (ID) => {
                      deletePlaylist(ID);
                    }
                  "
                  @openPlaylist="openPlaylist(playlist)"
                />
              </div>
            </q-infinite-scroll>
          </q-tab-panel>

          <!-- <q-tab-panel name="Lessons" style="max-height: 2048px; height: 70vh">
               <div
               v-show="!myLessons || myLessons?.length == 0"
               class="row col full-height justify-center items-end q-pb-md"
               >
               <q-img
               class="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-xs-10"
               fit="contain"
               src="not_found.svg"
               />
               </div>
               <q-infinite-scroll
               v-show="myLessons && myLessons?.length > 0"
               @load="getLessonsScroll"
               :offset="200"
               class="row q-pa-md q-pb-xl"
               >
               <div
               v-for="(lesson, n) in myLessons"
               :key="lesson.ID"
               class="full-width q-pa-sm"
               ref="lesson"
               :class="dragID === 'lesson' ? 'z-max' : 'home-item'"
               :style="{
               '--delay': myPlaylists?.length + n,
               }"
               style="display: inline-block; max-width: 420px"
               >
               <lesson
               :item="lesson"
               :preview-enabled="true"
               :ref="lesson.ID"
               @deleteLesson="
               (ID) => {
               deleteLesson(ID);
               }
               "
               @openLesson="openLesson(lesson)"
               @preview="startPreview(lesson.ID, lesson.Items[0].Challenge?.AudioSampleRefID)"
               :style="dragID === 'lesson' ? 'background: var(--q-0) !important;' : ''"
               />
               </div>
               </q-infinite-scroll>
               </q-tab-panel> -->
        </q-tab-panels>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import api from "../h/api";
import Playlist from "./playlistcard";

export default {
  emits: ["refresh"],
  name: "ChallengeRefs",
  props: ["playlists", "lessons", "message"],
  components: { Playlist },
  data: () => ({
    activeTab: "Playlists",
    loading: false,
    showDialog: false,
    blobRef: null,
  }),
  methods: {
    openPlaylist(playlist) {
      this.$router.push("/playlist/" + playlist.ID); // NOTE - c8e5e672: /0
    },
    deletePlaylist(ID) {
      const that = this;

      api.CallConfirmation(
        {
          method: "delete",
        },
        () => {
          return new Promise((resolve, reject) => {
            api.CallUndo(
              {
                message: this.$t("-raw-message-del-playlist"),
                ID: "channel_" + ID,
              },
              () => {
                return api
                  .Call({
                    url: "/api/v1/playlist/" + ID,
                    method: "DELETE",
                    show_error: true,
                    show_success: true,
                  })
                  .then(
                    () => {
                      this.$emit("refresh");
                    },
                    (e) => {
                      console.error("playlist delete error", e);
                    }
                  );
              }
            );
            reject();
          });
        }
      );
    },
    deleteLesson(ID) {
      const that = this;

      api.CallConfirmation(
        {
          method: "delete",
        },
        () => {
          return new Promise((resolve, reject) => {
            api.CallUndo(
              {
                message: "Deleting Lesson",
                ID: "channel_" + ID,
              },
              () => {
                return api
                  .Call({
                    url: "/api/v1/lesson_v2/" + ID,
                    method: "DELETE",
                    show_error: true,
                    show_success: true,
                  })
                  .then(
                    () => {
                      this.$emit("refresh");
                    },
                    (e) => {
                      console.error("lesson delete error", e);
                    }
                  );
              }
            );
            reject();
          });
        }
      );
    },
  },
};
</script>

<style scoped></style>
