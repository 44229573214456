export default {
  state: {
    currentStory: {
      ID: null,
      Name: null,
    },
    currentStoryAccess: {},
    storyStudioEditMode: {},
    storyStudioEditAct: null,
    storyStudioOperation: null,
    storyPassOperation: {},
    storyPass: {},
    storyPassAllReady: false,
    storyPassHistory: {},
  },
  getters: {
    currentStory: (state) => state.currentStory,
    currentStoryAccess: (state) => state.currentStoryAccess,
    storyStudioEditMode: (state) => state.storyStudioEditMode,
    storyStudioEditAct: (state) => state.storyStudioEditAct,
    storyStudioOperation: (state) => state.storyStudioOperation,
    storyPassOperation: (state) => state.storyPassOperation,
    storyPass: (state) => state.storyPass,
    storyPassAllReady: (state) => state.storyPassAllReady,
    storyPassHistory: (state) => state.storyPassHistory,
  },
  mutations: {
    setCurrentStory: (state, payload) => (state.currentStory = payload),
    setCurrentStoryAccess: (state, payload) =>
      (state.currentStoryAccess = payload),
    setStoryStudioEditMode: (state, payload) =>
      (state.storyStudioEditMode = payload),
    setStoryStudioEditAct: (state, payload) =>
      (state.storyStudioEditAct = payload),
    setStoryStudioOperation: (state, payload) =>
      (state.storyStudioOperation = payload),
    setStoryPassOperation: (state, payload) =>
      (state.storyPassOperation = payload),
    setStoryPass: (state, payload) => (state.storyPass = payload),
    setStoryPassAllReady: (state, payload) =>
      (state.storyPassAllReady = payload),
    setStoryPassHistory: (state, payload) => (state.storyPassHistory = payload),
  },
};
