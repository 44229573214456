export default {
  state: {
    isConnect: false,
  },
  mutations: {
    setIsConnect: (state, payload) => (state.isConnect = payload),
  },
  getters: {
    isConnect: (state) => state.isConnect,
  },
};
