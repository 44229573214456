import UnsubscribeMailTitleBar from "./title.vue";

export default {
  components: {
    UnsubscribeMailTitleBar,
  },
  data() {
    return {
      neu: true,
      split: true,
      rightmini: true,
      leftmini: true,
      bottom: true,
      right: true,
      left: true,
      top: true,
    };
  },
  mounted() {
    // if (this.$q.screen.lt.sm) { this.right = false, this.left = false }
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    error() {
      return this.$store.getters.error;
    },
    confirmation() {
      return this.$store.getters.confirmation;
    },
  },
};
