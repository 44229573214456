<script src="./index.js"></script>
<template>
  <q-page
    class="boxshadow no-wrap"
    style="overflow-y: hidden"
    :class="$q.screen.gt.sm ? 'column full-width' : 'row'"
  >
    <q-scroll-area
      class="q-pa-xs col-12"
      :thumb-style="{
        width: '0px',
      }"
    >
      <div
        class="column full-width row items-center q-pa-md"
        v-if="!isloaded || !isreadyRead || !isreadyUpdate"
      >
        <q-spinner-ball color="primary" size="lg" />
      </div>

      <div
        v-else
        :key="'reloadlibrary_' + keyreload"
        :class="$q.screen.gt.sm ? 'row' : 'column full-width'"
      >
        <div
          :class="
            $q.screen.gt.sm
              ? 'col-xs-2 col-sm-4 col-md-4 col-lg-3 col-xl-3'
              : ''
          "
          :style="{
            height: $q.screen.gt.sm
              ? ``
              : 'calc(${window.innerHeight}px - ' +
                (view?.isFit === true ? '3' : '11') +
                'rem)',
          }"
        >
          <info
            class="fit"
            :plibrary="library"
            @save="save"
            @read="read"
            @groupchanged="groupChanged"
            @treechanged="treeChanged"
            :ref="'infoRef_' + sessionID"
            :pid="sessionID"
            :pcanupdate="canUpdate"
          />
        </div>
        <div :class="$q.screen.gt.sm ? 'col' : ''">
          <content
            class="fit"
            :plibrary="library"
            :pid="sessionID"
            @read="read"
            @save="save"
            @choose="chooseFile"
            @startdrag="startdrag"
            :ref="'contentRef_' + sessionID"
            :pcanupdate="canUpdate"
            :pfilterfiletype="filetype"
            :pcanchangetype="view.canChangeType"
            :pcanchoose="view.canChoose"
            :pisfit="view?.isFit"
          />
        </div>
      </div>
    </q-scroll-area>
  </q-page>
</template>
