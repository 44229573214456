export default {
  state: {
    currentClassroom: {
      ID: null,
      Name: null,
    },
    currentClassroomAccess: {},
    currentClassroomTab: null,
    currentClassroomOperation: null,
  },
  getters: {
    currentClassroom: (state) => state.currentClassroom,
    currentClassroomAccess: (state) => state.currentClassroomAccess,
    currentClassroomTab: (state) => state.currentClassroomTab,
    currentClassroomOperation: (state) => state.currentClassroomOperation,
  },
  mutations: {
    setCurrentClassroom: (state, payload) => (state.currentClassroom = payload),
    setCurrentClassroomAccess: (state, payload) =>
      (state.currentClassroomAccess = payload),
    setCurrentClassroomTab: (state, payload) =>
      (state.currentClassroomTab = payload),
    setCurrentClassroomOperation: (state, payload) =>
      (state.currentClassroomOperation = payload),
  },
};
