export default {
  state: {
    currentCampus: {
      ID: null,
      Name: null,
    },
    currentCampusPlan: {
      ID: null,
      Name: null,
      Description: null,
      ClassroomID: null,
      CampusID: null,
      PlanPassID: null,
    },
    currentCampusPlanElements: [],
    currentCampusPlanElementID: null,
    currentCampusAccess: {},
    currentCampusTab: null,
    currentCampusOperation: null,
    editingPlanID: "", // it's right, need '' (not null) because of url params
    watchingPlanID: "",
    planSettingsClassroomID: "",
  },
  getters: {
    currentCampus: (state) => state.currentCampus,
    currentCampusAccess: (state) => state.currentCampusAccess,
    currentCampusTab: (state) => state.currentCampusTab,
    currentCampusOperation: (state) => state.currentCampusOperation,
    editingPlanID: (state) => state.editingPlanID,
    watchingPlanID: (state) => state.watchingPlanID,
    planSettingsClassroomID: (state) => state.planSettingsClassroomID,
    currentCampusPlan: (state) => state.currentCampusPlan,
    currentCampusPlanElements: (state) => state.currentCampusPlanElements,
    currentCampusPlanElementID: (state) => state.currentCampusPlanElementID,
  },
  mutations: {
    setCurrentCampus: (state, payload) => (state.currentCampus = payload),
    setCurrentCampusAccess: (state, payload) =>
      (state.currentCampusAccess = payload),
    setCurrentCampusTab: (state, payload) => (state.currentCampusTab = payload),
    setCurrentCampusOperation: (state, payload) =>
      (state.currentCampusOperation = payload),
    setEditingPlanID: (state, payload) => (state.editingPlanID = payload),
    setWatchingPlanID: (state, payload) => (state.watchingPlanID = payload),
    setPlanSettingsClassroomID: (state, payload) => (state.planSettingsClassroomID = payload),
    setCurrentCampusPlan: (state, payload) =>
      (state.currentCampusPlan = payload),
    setCurrentCampusPlanElements: (state, payload) =>
      state.currentCampusPlanElements.splice(
        0,
        state.currentCampusPlanElements.length,
        ...payload,
      ),
    setCurrentCampusPlanElementID: (state, payload) =>
      (state.currentCampusPlanElementID = payload),
  },
};
