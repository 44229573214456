import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "src/pages/settings/store.js";
import theme from "./theme.js";
import audio from "./audio.js";
import create from "src/pages/create/store.js";
import channels from "./channels.js";
import contactus from "src/pages/contactus/store.js";
import challenge from "src/pages/challenge/store.js";
import playlist from "src/pages/playlist/store.js";
import lessonStudio from "src/pages/lesson-studio/store.js";
import privacy from "./privacy.js";
import edit from "src/pages/edit/store.js";
import editLessonItem from "src/pages/edit-lesson-item/store.js";
import lesson from "src/pages/lesson/store.js";
import streamer from "./streamer.js";
import story from "./story.js";
import campus from "./campus.js";
import classroom from "./classroom.js";
import library from "./library.js";
import reports from "./reports.js";
import organization from "./organization.js";
import persisted from "./persisted.js";

import api from "../h/api";
// import { defineStore } from "pinia";

let store = null;

export default function () {
  const dataState = createPersistedState({
    paths: ["persisted"],
  });

  // // can't use both Vuex and Pinia
  // const StoreClipboard = defineStore("copyClipboard", {
  //   // plugins: [dataState],
  //   modules: {
  //     test,
  //   },
  // });

  const Store = createStore({
    plugins: [dataState],
    modules: {
      user,
      theme,
      audio,
      create,
      channels,
      contactus,
      challenge,
      playlist,
      lessonStudio,
      privacy,
      edit,
      editLessonItem,
      lesson,
      streamer,
      story,
      campus,
      classroom,
      library,
      reports,
      organization,
      persisted,
    },
    state: {
      left: true,
      loading: null,
      error: null,
      userscope: {},
      organizationID: null,
      organizationChanged: null, // here store date
      confirmation: {
        show: false,
        message: "",
        icon: null,
        buttonOk: "",
        fnct: function () {},
      },
      noaccesstariff: {
        show: false,
        capabilityID: null,
      },
      undo: {}, // timeout
      undooperationIDs: {}, // timeout operationIDs
    },
    mutations: {
      setLeft(state, payload) {
        state.left = payload;
      },
      setLoading(state, payload) {
        state.loading = payload;
      },
      setError(state, payload) {
        state.error = payload;
      },
      setUserScope(state, payload) {
        state.userscope = payload;
      },
      setOrganizationID(state, payload) {
        state.organizationID = payload;
      },
      setOrganizationChanged(state, payload) {
        state.organizationChanged = Date.now();
      },
      setConfirmation(state, payload) {
        state.confirmation = payload;
      },
      setUndo(state, payload) {
        state.undo[payload.ID] = payload.operationID;
        state.undooperationIDs[payload.operationID] = true;
      },
      setNoAccessTariff(state, payload) {
        state.noaccesstariff = payload;
      },
      setNoAccessTariffShow(state, payload) {
        state.noaccesstariff.show = payload;
      },
      clearUndo(state, payload) {
        if (payload in state.undo) {
          clearTimeout(payload);
          if (state.undo[payload] in state.undooperationIDs) {
            delete state.undooperationIDs[state.undo[payload]];
          }
          delete state.undo[payload];
        }
      },
    },
    actions: {
      setLoading({ commit }, payload) {
        commit("setLoading", payload);
      },
      setError({ commit }, payload) {
        commit("setError", payload);
      },
      setUserScope({ commit }, payload) {
        commit("setUserScope", payload);
      },
      setOrganizationID({ commit }, payload) {
        commit("setOrganizationID", payload);
      },
      setOrganizationChanged({ commit }, payload) {
        commit("setOrganizationChanged", payload);
      },
      showUndo({ commit }, payload) {
        commit("setUndo", payload);
      },
      showNoAccessTariff({ commit }, payload) {
        commit("setNoAccessTariff", {
          show: true,
          capabilityID: payload,
        });
      },
      hideNoAccessTariff({ commit }, payload) {
        commit("setNoAccessTariff", {
          show: false,
          capabilityID: null,
        });
      },
      hideUndo({ commit }, timeoutID) {
        commit("clearUndo", timeoutID);
      },
      showConfirmation({ commit }, payload) {
        commit("setConfirmation", {
          method: payload.method,
          message: payload.message === null || undefined ? "" : payload.message,
          icon: payload.icon,
          buttonOk: payload.buttonOk,
          show: true,
          fnct:
            payload.fnct === null || undefined ? function () {} : payload.fnct,
          key: payload.key,
          messageDetails: payload.messageDetails,
        });
      },
      hideConfirmation({ commit, state }, isConfirm) {
        var hide = function () {
          commit("setConfirmation", {
            message: "",
            icon: null,
            buttonOk: "",
            show: false,
            fnct: function () {},
          });
        };
        if (isConfirm) {
          state.confirmation.fnct().then(
            () => {
              hide();
            },
            (e) => {
              hide();
            },
          );
        } else {
          hide();
        }
      },
      checkTariffQuota({ dispatch, commit }, { capabilityID, channelID }) {
        return new Promise((resolve, reject) => {
          commit("setLoading", true, { root: true });
          api
            .Call({
              url: `/billing/check_quota_tariff_limit/${capabilityID}${
                channelID ? "?channelID=" + channelID : ""
              }`,
              method: "get",
              show_error: true,
            })
            .then(
              (response) => {
                commit("setLoading", false, { root: true });
                if (response.Access === true) {
                  resolve();
                } else {
                  dispatch("showNoAccessTariff", capabilityID, { root: true });
                  reject();
                }
              },
              (e) => {
                commit("setLoading", false, { root: true });
                console.error(
                  "error-6a5d4ed5",
                  `checkTariffQuota, capabilityID=${capabilityID}, channelID=${channelID}, error: `,
                  e,
                );
                reject();
              },
            );
        });
      },
    },
    getters: {
      left: (state) => state.left,
      loading: (state) => state.loading,
      error: (state) => state.error,
      userscope: (state) => state.userscope,
      organizationID: (state) => state.organizationID,
      organizationChanged: (state) => state.organizationChanged,
      confirmation: (state) => state.confirmation,
      undo: (state) => state.undo,
      undooperationIDs: (state) => state.undooperationIDs,
      noaccesstariff: (state) => state.noaccesstariff,
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING,
  });

  store = Store;

  return Store;
}

export { store };

// import Vuex from 'vuex'
// import VueMeta from 'vue-meta'

// Vue.use(Vuex)
// Vue.use(VueMeta)
