<template>
  <div class="row q-px-sm" style="position: relative; gap: 0.5rem">
    <!-- <q-icon
      name="favorite"
      size="10rem"
      style="
        right: -2rem;
        bottom: -2rem;
        position: absolute;
        opacity: 0.5;
        rotate: -45deg;
      "
      color="v"
    /> -->
    <q-icon
      name="favorite"
      size="3rem"
      style="opacity: 0.5; rotate: -45deg"
      color="v"
    />
    <avatar
      v-for="c in $store.state.channels.favoritesChannels"
      class="bg-1"
      style="min-width: 3rem !important; width: 3rem; height: 3rem"
      :key="c.ID"
      :channel="c"
      :avatar="c.avatar"
      :to="c.Name ? `/c/${c.Name}` : `/c/id/${c.ID}`"
      shirt="v"
    />
  </div>
</template>

<script>
import avatar from "src/components/avatar.vue";
// import pickerimage from "src/components/picker-picture/picker.vue";

export default {
  components: { avatar },
  mounted() {
    this.$store.dispatch("getFavoriteChannels");
  },
};
</script>
