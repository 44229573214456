<template>
  <div class="fit column items-center justify-center" v-show="loading">
    <q-spinner color="primary" size="3rem" />
  </div>
  <div
    class="column fit justify-between"
    style="border-radius: 0.75rem; scrollbar-width: none"
    v-show="!loading"
  >
    <div
      v-show="chatid"
      class="q-pb-sm q-mt-lg z-top bg-0"
      style="box-shadow: 0rem 0rem 1rem 1rem var(--q-0)"
    >
      <!-- <q-separator dark class="q-mx-md" /> -->
      <div class="row q-px-sm full-width">
        <div class="col" style="font-size: 1.5rem; font-weight: 1000">
          {{ chatsubject }}
        </div>
        <div
          class="row items-center"
          style="gap: 0.5rem"
          v-if="chatvar !== 'system_personal'"
        >
          <template v-for="usr in chatusers" :key="usr.ID + '_chat_users'">
            <div
              class="bg-2 row items-center round-both"
              style="font-weight: bold; height: 2rem; padding: 0 0.75rem"
            >
              {{ usr.Name }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- <q-separator dark class="q-my-md" /> -->
    <div
      id="scrollMessages"
      class="col q-py-md q-mx-none"
      style="
        max-width: 100%;
        scrollbar-width: none;
        scroll-behavior: smooth;
        overflow-y: auto;
        overflow-x: hidden;
      "
    >
      <div
        class="row full-width q-pa-sm"
        :class="
          messageObject.Username === user.username
            ? 'justify-end'
            : 'justify-start'
        "
        v-for="messageObject in messageList"
        :key="messageObject.ID + '_chat_message'"
      >
        <div
          class="col-6 column items-start justify-center q-pa-sm"
          style="border-radius: 0.75rem"
          :class="{
            'bg-2': isItMeTheGreatUser(messageObject.Username),
            'bg-1': !isItMeTheGreatUser(messageObject.Username),
            'full-width': chatvar === 'system_personal',
          }"
        >
          <div
            v-if="chatvar !== 'system_personal'"
            class="text-v ubuntu"
            style="font-size: 0.8rem"
          >
            {{ messageObject.Username }}
          </div>
          <div class="text-8 ubuntu" style="font-size: 0.6rem">
            {{ handleDate(messageObject.createdat) }}
          </div>
          <div
            v-for="metaObject in messageObject.Meta"
            :key="metaObject.ID + '_chat_msg_meta'"
            class="text-10"
            style="
              max-width: 100%;
              overflow-wrap: break-word;
              word-wrap: break-word;
            "
          >
            <div
              v-if="
                metaObject.Type === 'invitation_campus' ||
                metaObject.Type === 'invitation_classroom'
              "
              class="q-pb-sm"
            >
              <q-btn
                flat
                style="border-radius: 0.75rem"
                @click="joinConfirmOrCancel(metaObject, true)"
              >
                <q-icon name="task_alt" color="p" class="q-pr-sm" />
                {{ $t("-raw-tooltip-confirm") }}
              </q-btn>

              <q-btn
                flat
                style="border-radius: 0.75rem"
                @click="joinConfirmOrCancel(metaObject, false)"
              >
                <q-icon name="cancel" color="n" class="q-pr-sm" />
                {{ $t("-raw-tooltip-cancel") }}
              </q-btn>
            </div>
            <div
              v-else-if="metaObject.Type === 'mixer_need_repeat'"
              class="q-pb-sm"
            >
              <q-btn flat style="border-radius: 0.75rem" @click="openMixer()">
                {{ $t("-raw-mixer-open") }}
              </q-btn>
            </div>
            <div
              v-else-if="
                metaObject.Type === 'invitation_story' ||
                metaObject.Type === 'story_player_poke'
              "
              class="q-pb-sm"
            >
              <q-btn
                flat
                style="border-radius: 0.75rem"
                @click="joinStory(metaObject)"
              >
                {{ $t("-raw-story-pass") }}
              </q-btn>
            </div>
            <div
              v-else-if="metaObject.Type === 'campus_classroom_nofitication'"
              class="q-pb-sm"
            >
              <q-btn
                flat
                style="border-radius: 0.75rem"
                @click="openCampus(metaObject)"
              >
                {{ $t("-raw-campus-open") }}
              </q-btn>
            </div>
            <div
              v-else-if="metaObject.Type === 'invitation_organization'"
              class="q-pb-sm"
            >
              <q-btn
                flat
                style="border-radius: 0.75rem"
                @click="$router.push('/settings/organizations')"
              >
                {{ $t("-raw-view-invitation") }}
              </q-btn>
            </div>
            <div v-else>{{ metaObject.Body }}</div>
          </div>
        </div>
      </div>
    </div>
    <q-input
      v-if="chatvar !== 'system_personal'"
      v-show="!loading"
      autofocus
      :placeholder="$t('-raw-chat-send-message')"
      borderless
      class="col-auto q-pa-sm q-mb-md q-pl-md bg-1"
      v-model="newMessage"
      rows="1"
      style="border-radius: 0.75rem; resize: none"
      @keydown.enter="SendMessage"
    >
      <template #append>
        <q-btn
          flat
          round
          no-caps
          icon="send"
          class="text-v"
          v-model="newMessage"
          @click="SendMessage"
          :disable="!$streamer.isConnect.value"
        />
      </template>
    </q-input>
  </div>
</template>

<style scoped>
.chatmessage {
  background: var(--q-2);
}
</style>

<script src="./chat.js"></script>
