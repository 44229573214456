import api from "src/h/api.js";

export default {
  state: {
    editLessonItem: null,
    editTransitionLesson: null,
    evals: null,
  },
  mutations: {
    setEditLessonItem: (state, payload) => (state.editLessonItem = payload),
    setEditLessonItemNextItemID: (state, payload) =>
      (state.editLessonItem.NextItemID = payload),
    setEditLessontemSuggestions: (state, payload) =>
      (state.editLessonItem.Suggestions = payload),
    setEditTransitionLesson(state, payload) {
      state.editTransitionLesson = payload;
    },
    setEvals: (state, payload) => (state.evals = payload),
  },
  actions: {
    lessonAudiosampleUpdate({ state, commit, dispatch }) {
      commit("setLoading", true);

      const evals = state.evals.map((a) => ({
        ID: a.ID.startsWith("wavesurfer") ? null : a.ID,
        Acceptance: a.Acceptance,
        ItemID: a.ItemID,
        LinkedItemID: a.LinkedItemID,
        SegmentEnd: a.SegmentEnd,
        SegmentStart: a.SegmentStart,
      }));

      api
        .Call({
          url: "/api/v1/lesson_v2_item",
          method: "post",
          data: {
            ID: state.editLessonItem.ID,
            LessonV2ID: state.editLessonItem.LessonV2ID,
            ChallengeID: state.editLessonItem.ChallengeID,
            Rank: state.editLessonItem.Rank,
            NextItemID: state.editLessonItem.NextItemID,
            Evals: evals,
            Suggestions: state.editLessonItem.Suggestions,
          },
        })
        .then(
          () => {
            commit("needSavePicture", true);
          },
          (e) => {
            commit("setLoading", false);
            commit("setError", {
              code: "error-6113c46d: lessonAudiosampleUpdate error",
            });
            console.log(e);
          },
        );
    },
  },
  getters: {
    getEditMode: (state) =>
      state.editLessonItem ? "playlistItem-65fbfec6" : "challenge-f8b6f382",
  },
};
