export default {
  namespaced: true,
  state: {
    lesson: null,
    currentChallenge: null,
    currentItemIndex: null,
  },
  mutations: {
    setLesson(state, payload) {
      state.lesson = payload;
    },
    setCurrentChallenge(state, payload) {
      state.currentChallenge = payload;
    },
    setCurrentChallengeAutoplay(state, payload) {
      state.currentChallenge.autoplay = payload;
    },
    setCurrentItemIndex(state, payload) {
      state.currentItemIndex = payload;
    },
  },
};
