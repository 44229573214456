<template>
  <div
    style="position: absolute; right: 5rem; z-index: 9999 !important"
    :style="
      $q.screen.lt.sm
        ? 'width: 120vw;'
        : $q.screen.lt.md
          ? 'width: 80vw;'
          : 'width: 40rem;'
    "
    class="bg-3 column round-1"
    v-if="lastNotification.ObjectType"
  >
    <a
      style="display: none"
      :ref="'linkgoto_' + sessionID"
      :href="linkGoto"
      target="_blank"
    ></a>
    <div v-for="(lst, rnm) in list" :key="'obj_' + rnm">
      <!-- we need create v-for and transition for each object for right work transition-->
      <transition appear name="slide-fade" mode="out-in">
        <div
          v-if="lastNotification.ID === lst.ID"
          class="q-mt-xs q-mx-xs round-1"
          :class="list.length > 1 ? 'msg-div' : ''"
        >
          <div class="row full-width">
            <!-- <div style="width: 2rem" class="row items-center justify-center">
              <q-icon
                color="w"
                class="q-ml-md blink_notify"
                size="2rem"
                name="mark_unread_chat_alt"
              />
            </div> -->
            <div
              v-if="lastNotification.ObjectType == 'chat_message'"
              class="col row q-my-sm q-mx-md text-v"
              style="font-size: 1.2em"
            >
              <span>
                {{ $t("-raw-system-notifications-new-message-in") }}
                {{ lastNotification.ChatMessage.ObjectTypeName }}
                <span class="text-bold q-ml-sm">{{
                  lastNotification.ChatMessage.ObjectName
                }}</span>
              </span>
            </div>
            <div
              v-else-if="lastNotification.ObjectType == 'shared_content'"
              class="col row q-my-sm q-mx-md text-v"
              style="font-size: 1.2em"
            >
              {{ $t("-raw-system-notifications-shared-content") }}
            </div>
            <div
              v-else-if="lastNotification.ObjectType == 'story_pass_turn'"
              class="col row q-my-sm q-mx-md text-v"
              style="font-size: 1.2em"
            >
              {{ $t("-raw-system-notifications-story-pass-turn") }}
            </div>
            <div
              v-else-if="
                lastNotification.ObjectType == 'membership_owner_to_user'
              "
              class="col row q-my-sm q-mx-md text-v"
              style="font-size: 1.2em"
            >
              <template v-if="lst.MembershipOwnerToUser.Status == 'invited'">
                {{ $t("-raw-system-notifications-membership-invite") }}
              </template>
              <template
                v-else-if="lst.MembershipOwnerToUser.Status == 'rejected'"
              >
                {{ $t("-raw-system-notifications-membership-reject") }}
              </template>
              <template
                v-else-if="lst.MembershipOwnerToUser.Status == 'resolved'"
              >
                {{ $t("-raw-system-notifications-membership-resolved") }}
              </template>
            </div>
            <div
              v-else-if="
                lastNotification.ObjectType == 'membership_user_to_owner'
              "
              class="col row q-my-sm q-mx-md text-v"
              style="font-size: 1.2em"
            >
              <template v-if="lst.MembershipUserToOwner.Status == 'requested'">
                <span class="q-pr-sm text-e">{{
                  lst.MembershipUserToOwner.UserName
                }}</span>
                {{ $t("-raw-system-notifications-membership-request") }}
              </template>
              <template
                v-else-if="lst.MembershipUserToOwner.Status == 'confirmed'"
              >
                <span class="q-pr-sm text-e">{{
                  lst.MembershipUserToOwner.UserName
                }}</span>
                {{ $t("-raw-system-notifications-membership-confirmed") }}
              </template>
            </div>
            <div
              v-else-if="lastNotification.ObjectType === 'genai_content_ready'"
              class="col row q-my-sm q-mx-md text-v"
              style="font-size: 1.2em"
            >
              {{ $t("-raw-system-notifications-genai") }}
            </div>
            <!-- Note: ref-eeed0b73: add here other notifications type-->
            <div v-else class="q-ml-md text-n q-mb-sm">
              {{ lastNotification.ObjectType }}
            </div>
            <div
              class="row items-end justify-center q-mr-md"
              style="width: 2.5rem"
            >
              <q-btn
                @click.stop="closeLastNotification()"
                flat
                color="8"
                class="bg-2 q-my-auto"
                icon="close"
                style="
                  border-radius: 0.75rem;
                  width: 1rem;
                  height: 1rem;
                  position: relative;
                "
                :loading="
                  notificationClosingID &&
                  notificationClosingID === lastNotification.ID
                "
                :disabled="
                  (notificationClosingID &&
                    notificationClosingID !== lastNotification.ID) ||
                  notificationAllClosing
                "
              >
              </q-btn>
            </div>
          </div>
          <template v-if="lst.ObjectType == 'chat_message'">
            <div class="row full-width">
              <div class="column q-ml-md q-mr-xs">
                <pickerimage
                  :data="{
                    uid: 'user_avatar_msg_first_' + lst.ChatMessage.UserID,
                    objectid: lst.ChatMessage.UserID,
                    objecttype: 'user_avatar',
                  }"
                  :view="{
                    height: '3rem',
                    width: '3rem',
                    onlyshow: true,
                    // undraw_profile_pic_ic-5-t.svg
                    showdefaultsrc: 'gray_avatar.png',
                    borderradius: '2rem',
                  }"
                  style="
                    background: var(--q-1);
                    border-radius: 2rem;
                    max-height: 3rem;
                    min-height: 3rem;
                  "
                  class="glassy-8 bg-transparent"
                >
                </pickerimage>
                <q-tooltip
                  anchor="top left"
                  self="bottom left"
                  class="bg-0 text-8 round-both"
                  style="border: 1px solid var(--q-2)"
                  transition-show="jump-up"
                  transition-hide="jump-down"
                >
                  {{ lst.ChatMessage.UserName }}
                </q-tooltip>
              </div>
              <div
                class="col row q-mx-md items-center message"
                @click="goToNotifyObject(lst)"
              >
                <span
                  v-if="
                    lst.ChatMessage.Message &&
                    lst.ChatMessage.Message.length <= messageMaxLength
                  "
                >
                  {{ lst.ChatMessage.Message }}
                </span>
                <span v-else>
                  {{ lst.ChatMessage.Message.substring(0, messageMaxLength) }}
                  ...
                </span>
              </div>
            </div>
            <div
              class="row full-width justify-end q-mb-sm q-pr-xl text-italic text-c"
              style="padding-left: 3rem"
              :key="'calcMessageInterval_' + calcMessageIntervalKey"
            >
              {{ dateMessageIntervalStr(lst.Time) }}
            </div>
          </template>
          <template v-else-if="lst.ObjectType == 'shared_content'">
            <div class="row full-width">
              <div
                class="col row q-mx-md items-center message"
                @click="goToNotifyObject(lst)"
              >
                {{ capitalize(lst.SharedContent.ObjectType) }}
                <span class="text-bold q-ml-sm">{{
                  lst.SharedContent.ObjectName
                }}</span>
              </div>
            </div>
            <div
              class="row full-width justify-end q-mb-sm q-pr-xl text-italic text-c"
              style="padding-left: 3rem"
              :key="'calcMessageInterval_' + calcMessageIntervalKey"
            >
              {{ dateMessageIntervalStr(lst.Time) }}
            </div>
          </template>
          <template v-else-if="lst.ObjectType == 'story_pass_turn'">
            <div class="row full-width">
              <div
                class="col row q-mx-md items-center message"
                @click="goToNotifyObject(lst)"
              >
                {{ $t("-raw-system-notifications-story-pass-turn-story") }}
                <span class="text-bold q-ml-sm">{{
                  lst.StoryPassTurn.StoryName
                }}</span>
              </div>
            </div>
            <div
              class="row full-width justify-end q-mb-sm q-pr-xl text-italic text-c"
              style="padding-left: 3rem"
              :key="'calcMessageInterval_' + calcMessageIntervalKey"
            >
              {{ dateMessageIntervalStr(lst.Time) }}
            </div>
          </template>
          <template v-else-if="lst.ObjectType == 'membership_owner_to_user'">
            <div class="row full-width">
              <div
                class="col row q-mx-md items-center message"
                @click="goToNotifyObject(lst)"
              >
                {{ capitalize(lst.MembershipOwnerToUser.ObjectType) }}
                <span class="text-bold q-ml-sm">{{
                  lst.MembershipOwnerToUser.ObjectName
                }}</span>
              </div>
            </div>
            <div
              class="row full-width justify-end q-mb-sm q-pr-xl text-italic text-c"
              style="padding-left: 3rem"
              :key="'calcMessageInterval_' + calcMessageIntervalKey"
            >
              {{ dateMessageIntervalStr(lst.Time) }}
            </div>
          </template>
          <template v-else-if="lst.ObjectType == 'membership_user_to_owner'">
            <div class="row full-width">
              <div
                class="col row q-mx-md items-center message"
                @click="goToNotifyObject(lst)"
              >
                {{ capitalize(lst.MembershipUserToOwner.ObjectType) }}
                <span class="text-bold q-ml-sm">{{
                  lst.MembershipUserToOwner.ObjectName
                }}</span>
              </div>
            </div>
            <div
              class="row full-width justify-end q-mb-sm q-pr-xl text-italic text-c"
              style="padding-left: 3rem"
              :key="'calcMessageInterval_' + calcMessageIntervalKey"
            >
              {{ dateMessageIntervalStr(lst.Time) }}
            </div>
          </template>
          <template v-else-if="lst.ObjectType == 'genai_content_ready'">
            <div class="row full-width">
              <div
                class="col row q-mx-md items-center message"
                @click="goToNotifyObject(lst)"
              >
                {{ capitalize(lst.GenaiContent.ObjectType) }}
                <span class="text-bold q-ml-sm">{{
                  lst.GenaiContent.ObjectName
                }}</span>
              </div>
            </div>
            <div
              class="row full-width justify-end q-mb-sm q-pr-xl text-italic text-c"
              style="padding-left: 3rem"
              :key="'calcMessageInterval_' + calcMessageIntervalKey"
            >
              {{ dateMessageIntervalStr(lst.Time) }}
            </div>
          </template>
          <!-- Note: ref-eeed0b73: add here other notifications type-->
        </div>
      </transition>
    </div>
    <div v-if="list.length > 1" class="row q-py-xs q-mx-sm">
      <div class="row full-width items-center justify-center q-my-xs">
        <q-chip
          clickable
          @click="changeShowDetails"
          class="q-mb-sm q-ml-md"
          :class="visibleDetails ? 'bg-4' : ''"
          :id="'chip_' + sessionID"
        >
          <q-avatar color="n" class="text-center">{{
            list.length - 1
          }}</q-avatar
          >{{ $t("-raw-system-notifications-more-notifications") }}
        </q-chip>
        <div style="position: absolute; right: 1rem" class="q-mr-sm">
          <q-btn
            @click.stop="closeAllNotifications()"
            flat
            color="8"
            class="bg-2 q-py-auto"
            icon="speaker_notes_off"
            style="border-radius: 1rem; width: 3rem"
            :loading="notificationAllClosing"
            :disabled="notificationClosingID"
          >
            <q-tooltip
              anchor="top left"
              self="bottom left"
              class="bg-0 text-8 round-both"
              style="border: 1px solid var(--q-2); z-index: 10000 !important"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              {{ $t("-raw-system-notifications-remove-all") }}
            </q-tooltip>
          </q-btn>
        </div>
      </div>
      <q-slide-transition>
        <div
          v-show="list.length > 1 && visibleDetails"
          class="bg-4 full-width round-1"
        >
          <q-virtual-scroll
            :items="list"
            separator
            :style="{
              'max-height': containerMessagesHeight + 'px',
            }"
            :ref="'scrollMessagesDetails_' + sessionID"
            :id="'scrollMessagesDetails_' + sessionID"
            v-slot="{ item, index }"
          >
            <div :key="'obj_list_more_' + index" class="full-width">
              <transition appear name="slide-fade">
                <div
                  v-if="index !== 0 && item.hide !== true"
                  class="q-mt-xs round-1"
                  :class="{
                    'msg-div': index != list.length - 1,
                  }"
                >
                  <div class="row full-width">
                    <!-- <div
                    style="width: 2rem"
                    class="row items-center justify-center"
                  >
                    <q-icon
                      color="w"
                      class="q-ml-md blink_notify"
                      size="2rem"
                      name="mark_unread_chat_alt"
                    />
                  </div> -->
                    <div
                      v-if="item.ObjectType == 'chat_message'"
                      class="col row q-my-sm q-mx-md text-v"
                      style="font-size: 1.2em"
                    >
                      <span>
                        {{ $t("-raw-system-notifications-new-message-in") }}
                        {{ item.ChatMessage.ObjectTypeName }}
                        <span class="text-bold q-ml-sm">{{
                          item.ChatMessage.ObjectName
                        }}</span>
                      </span>
                    </div>

                    <div
                      v-else-if="item.ObjectType == 'shared_content'"
                      class="col row q-my-sm q-mx-md text-v"
                      style="font-size: 1.2em"
                    >
                      {{ $t("-raw-system-notifications-shared-content") }}
                    </div>
                    <div
                      v-else-if="item.ObjectType == 'story_pass_turn'"
                      class="col row q-my-sm q-mx-md text-v"
                      style="font-size: 1.2em"
                    >
                      {{ $t("-raw-system-notifications-story-pass-turn") }}
                    </div>
                    <div
                      v-else-if="item.ObjectType == 'membership_owner_to_user'"
                      class="col row q-my-sm q-mx-md text-v"
                      style="font-size: 1.2em"
                    >
                      <template
                        v-if="item.MembershipOwnerToUser.Status == 'invited'"
                      >
                        {{ $t("-raw-system-notifications-membership-invite") }}
                      </template>
                      <template
                        v-else-if="
                          item.MembershipOwnerToUser.Status == 'rejected'
                        "
                      >
                        {{ $t("-raw-system-notifications-membership-reject") }}
                      </template>
                      <template
                        v-else-if="
                          item.MembershipOwnerToUser.Status == 'resolved'
                        "
                      >
                        {{
                          $t("-raw-system-notifications-membership-resolved")
                        }}
                      </template>
                    </div>
                    <div
                      v-else-if="item.ObjectType == 'membership_user_to_owner'"
                      class="col row q-my-sm q-mx-md text-v"
                      style="font-size: 1.2em"
                    >
                      <template
                        v-if="item.MembershipUserToOwner.Status == 'requested'"
                      >
                        <span class="q-pr-sm text-e">{{
                          item.MembershipUserToOwner.UserName
                        }}</span>
                        {{ $t("-raw-system-notifications-membership-request") }}
                      </template>
                      <template
                        v-else-if="
                          item.MembershipOwnerToUser.Status == 'confirmed'
                        "
                      >
                        <span class="q-pr-sm text-e">{{
                          item.MembershipUserToOwner.UserName
                        }}</span>
                        {{
                          $t("-raw-system-notifications-membership-confirmed")
                        }}
                      </template>
                    </div>
                    <div
                      v-else-if="
                        lastNotification.ObjectType === 'genai_content_ready'
                      "
                      class="col row q-my-sm q-mx-md text-v"
                      style="font-size: 1.2em"
                    >
                      {{ $t("-raw-system-notifications-genai") }}
                    </div>
                    <!-- Note: ref-eeed0b73: add here other notifications type-->
                    <div v-else class="q-ml-md text-n q-mb-sm">
                      {{ item.ObjectType }}
                    </div>
                    <div
                      class="row items-end justify-center q-mr-md"
                      style="width: 2.5rem"
                    >
                      <q-btn
                        @click.stop="closeNotification(item.ID)"
                        flat
                        color="8"
                        class="bg-2 q-my-auto"
                        icon="close"
                        style="
                          border-radius: 0.75rem;
                          width: 1rem;
                          height: 1rem;
                          position: relative;
                        "
                        :loading="
                          notificationClosingID &&
                          notificationClosingID === item.ID
                        "
                        :disabled="
                          (notificationClosingID &&
                            notificationClosingID !== item.ID) ||
                          notificationAllClosing
                        "
                      >
                      </q-btn>
                    </div>
                  </div>
                  <!-- Note: ref-eeed0b73: add here other notifications type-->
                  <template v-if="item.ObjectType == 'chat_message'">
                    <div class="row full-width">
                      <div class="column q-ml-md q-mr-xs">
                        <pickerimage
                          :data="{
                            uid:
                              'user_avatar_msg_first_' +
                              item.ChatMessage.UserID,
                            objectid: item.ChatMessage.UserID,
                            objecttype: 'user_avatar',
                          }"
                          :view="{
                            height: '3rem',
                            width: '3rem',
                            onlyshow: true,
                            // undraw_profile_pic_ic-5-t.svg
                            showdefaultsrc: 'gray_avatar.png',
                            borderradius: '2rem',
                          }"
                          style="
                            background: var(--q-1);
                            border-radius: 2rem;
                            max-height: 3rem;
                            min-height: 3rem;
                          "
                          class="glassy-8 bg-transparent"
                        >
                        </pickerimage>
                        <q-tooltip
                          anchor="top left"
                          self="bottom left"
                          class="bg-0 text-8 round-both"
                          style="border: 1px solid var(--q-2)"
                          transition-show="jump-up"
                          transition-hide="jump-down"
                        >
                          {{ item.ChatMessage.UserName }}
                        </q-tooltip>
                      </div>
                      <div
                        class="col row q-mx-md items-center message"
                        @click="goToNotifyObject(item)"
                      >
                        <span
                          v-if="
                            item.ChatMessage.Message &&
                            item.ChatMessage.Message.length <= messageMaxLength
                          "
                        >
                          {{ item.ChatMessage.Message }}
                        </span>
                        <span v-else>
                          {{
                            item.ChatMessage.Message.substring(
                              0,
                              messageMaxLength,
                            )
                          }}
                          ...
                        </span>
                      </div>
                    </div>
                    <div
                      class="row full-width justify-end q-mb-sm q-pr-xl text-italic text-c"
                      style="padding-left: 3rem"
                      :key="'calcMessageInterval_' + calcMessageIntervalKey"
                    >
                      {{ dateMessageIntervalStr(item.Time) }}
                    </div>
                  </template>
                  <template v-else-if="item.ObjectType == 'shared_content'">
                    <div class="row full-width">
                      <div
                        class="col row q-mx-md items-center message"
                        @click="goToNotifyObject(item)"
                      >
                        {{ capitalize(item.SharedContent.ObjectType) }}
                        <span class="text-bold q-ml-sm">{{
                          item.SharedContent.ObjectName
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="row full-width justify-end q-mb-sm q-pr-xl text-italic text-c"
                      style="padding-left: 3rem"
                      :key="'calcMessageInterval_' + calcMessageIntervalKey"
                    >
                      {{ dateMessageIntervalStr(item.Time) }}
                    </div>
                  </template>
                  <template v-else-if="item.ObjectType == 'story_pass_turn'">
                    <div class="row full-width">
                      <div
                        class="col row q-mx-md items-center message"
                        @click="goToNotifyObject(item)"
                      >
                        {{
                          $t("-raw-system-notifications-story-pass-turn-story")
                        }}
                        <span class="text-bold q-ml-sm">{{
                          item.StoryPassTurn.StoryName
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="row full-width justify-end q-mb-sm q-pr-xl text-italic text-c"
                      style="padding-left: 3rem"
                      :key="'calcMessageInterval_' + calcMessageIntervalKey"
                    >
                      {{ dateMessageIntervalStr(item.Time) }}
                    </div>
                  </template>
                  <template
                    v-else-if="item.ObjectType == 'membership_owner_to_user'"
                  >
                    <div class="row full-width">
                      <div
                        class="col row q-mx-md items-center message"
                        @click="goToNotifyObject(item)"
                      >
                        {{ capitalize(item.MembershipOwnerToUser.ObjectType) }}
                        <span class="text-bold q-ml-sm">{{
                          item.MembershipOwnerToUser.ObjectName
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="row full-width justify-end q-mb-sm q-pr-xl text-italic text-c"
                      style="padding-left: 3rem"
                      :key="'calcMessageInterval_' + calcMessageIntervalKey"
                    >
                      {{ dateMessageIntervalStr(item.Time) }}
                    </div>
                  </template>
                  <template
                    v-else-if="item.ObjectType == 'membership_user_to_owner'"
                  >
                    <div class="row full-width">
                      <div
                        class="col row q-mx-md items-center message"
                        @click="goToNotifyObject(item)"
                      >
                        {{ capitalize(item.MembershipUserToOwner.ObjectType) }}
                        <span class="text-bold q-ml-sm">{{
                          item.MembershipUserToOwner.ObjectName
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="row full-width justify-end q-mb-sm q-pr-xl text-italic text-c"
                      style="padding-left: 3rem"
                      :key="'calcMessageInterval_' + calcMessageIntervalKey"
                    >
                      {{ dateMessageIntervalStr(item.Time) }}
                    </div>
                  </template>
                  <template
                    v-else-if="item.ObjectType == 'genai_content_ready'"
                  >
                    <div class="row full-width">
                      <div
                        class="col row q-mx-md items-center message"
                        @click="goToNotifyObject(item)"
                      >
                        {{ capitalize(item.GenaiContent.ObjectType) }}
                        <span class="text-bold q-ml-sm">{{
                          item.GenaiContent.ObjectName
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="row full-width justify-end q-mb-sm q-pr-xl text-italic text-c"
                      style="padding-left: 3rem"
                      :key="'calcMessageInterval_' + calcMessageIntervalKey"
                    >
                      {{ dateMessageIntervalStr(item.Time) }}
                    </div>
                  </template>
                </div>
              </transition>
            </div>
          </q-virtual-scroll>
        </div>
      </q-slide-transition>
    </div>
  </div>
  <!-- <div style="display: none"></div> -->
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.msg-div {
  border-bottom: 2px solid var(--q-0);
}

.blink_notify {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  90% {
    opacity: 0.6;
  }
}

.message {
  cursor: pointer;
  font-weight: normal;
}
.message:hover {
  text-shadow: 1px 1px 2px var(--q-7);
}
</style>

<script src="./index.js"></script>
