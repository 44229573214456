<template>
  <!--
    style="border-style: solid; border-color: var(--q-0)"
    -->
  <div
    class="row justify-center items-center picker-row bg-transparent"
    :style="{
      height: view.height ? view.height : 'auto',
      width: view.width ? view.width : 'auto',
      display:
        status === 'empty' && view.onlyshow === true && showdefault === false
          ? 'none'
          : '',
    }"
  >
    <q-img
      :src="link"
      class="full-width full-height"
      :fit="view.fit ? view.fit : 'contain'"
      @error="ImageLoadError()"
      @load="ImageLoadSuccess()"
      v-show="status === null || status === 'loaded'"
      :style="{
        'background-color': view.bgcolor ? view.bgcolor : '',
        'border-radius': view.borderradius ? view.borderradius : '',
        border: view.border ? view.border : '',
      }"
    >
      <div
        class="row full-width items-center justify-center absolute-bottom no-margin no-padding q-gutter-x-sm bg-transparent"
        style="height: 2.5em"
        v-show="status !== null"
        v-if="view.onlyshow !== true"
      >
        <q-btn
          size="sm"
          round
          dense
          icon="delete"
          color="negative"
          @click="DeleteFile()"
          v-if="view.hidedelete !== true"
        >
          <q-tooltip
            anchor="top right"
            self="bottom right"
            class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
            transition-show="jump-right"
            transition-hide="jump-left"
          >
            Delete
          </q-tooltip>
        </q-btn>
        <q-btn
          color="v"
          size="sm"
          round
          dense
          icon="edit"
          @click="CreateUpload"
        >
          <q-tooltip
            anchor="top right"
            self="bottom right"
            class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
            transition-show="jump-right"
            transition-hide="jump-left"
          >
            Edit
          </q-tooltip>
        </q-btn>

        <div>
          <q-btn
            color="v"
            size="sm"
            round
            dense
            icon="collections"
            transition-show="jump-right"
            transition-hide="jump-left"
          >
            <q-tooltip
              anchor="top right"
              self="bottom right"
              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
              transition-show="jump-right"
              transition-hide="jump-left"
            >
              Choose from library
            </q-tooltip>
            <q-menu @show="OnShowChooseLibrary()">
              <div
                class="column row items-center q-pa-md"
                v-if="!campusesLoaded"
              >
                <q-spinner color="primary" size="md" />
              </div>
              <div class="column row items-center q-pa-md" v-else>
                <div
                  class="text-italic text-v text-bold row justify-center q-py-sm"
                >
                  {{ $t("-raw-story-available-libraries") }}
                </div>
                <div class="full-width"><q-separator /></div>

                <q-list>
                  <q-item
                    clickable
                    v-close-popup
                    @click="OnChooseLibrary('system')"
                  >
                    <q-item-section avatar>
                      <q-icon name="collections" color1="v" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Public</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                    clickable
                    v-close-popup
                    @click="OnChooseLibrary('personal')"
                  >
                    <q-item-section avatar>
                      <q-icon name="photo_album" color1="v" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Personal</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item clickable v-if="campuses && campuses.length > 0">
                    <q-item-section avatar>
                      <q-icon name="supervised_user_circle" color1="v" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Campus</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-icon name="keyboard_arrow_right"></q-icon>
                    </q-item-section>
                    <q-menu anchor="top right" self="top left">
                      <q-list>
                        <q-item
                          clickable
                          v-close-popup
                          v-for="s in campuses"
                          :key="'current_campus_' + s.Campus.ID"
                          @click="
                            OnChooseLibrary(
                              'campus',
                              s.Campus.ID,
                              s.Campus.Name,
                            )
                          "
                        >
                          <q-item-section>
                            <q-item-label>{{ s.Campus.Name }}</q-item-label>
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-item>
                </q-list>
              </div>
            </q-menu>
          </q-btn>

          <!-- <q-btn-dropdown
            size="md"
            split
            color="v"
            rounded
            flat
            @click="OnChooseLibrary('system')"
            @show="OnShowChooseLibrary()"
            transition-show="jump-right"
            transition-hide="jump-left"
          >
            <template #label>
              <q-icon name="collections">
                <q-tooltip
                  anchor="top right"
                  self="bottom right"
                  class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                  transition-show="jump-right"
                  transition-hide="jump-left"
                >
                  Public library
                </q-tooltip>
              </q-icon>
            </template>

            <div class="column row items-center q-pa-md" v-if="!campusesLoaded">
              <q-spinner color="primary" size="md" />
            </div>
            <div class="column row items-center q-pa-md" v-else>
              <div
                class="text-italic text-v text-bold row justify-center q-py-sm"
              >
                Available libraries
              </div>
              <div class="full-width"><q-separator /></div>

              <q-list>
                <q-item
                  clickable
                  v-close-popup
                  @click="OnChooseLibrary('system')"
                >
                  <q-item-section avatar>
                    <q-icon name="collections" color1="v" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Public</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item
                  clickable
                  v-close-popup
                  @click="OnChooseLibrary('personal')"
                >
                  <q-item-section avatar>
                    <q-icon name="photo_album" color1="v" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Personal</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-if="campuses && campuses.length > 0">
                  <q-item-section avatar>
                    <q-icon name="supervised_user_circle" color1="v" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Campus</q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-icon name="keyboard_arrow_right"></q-icon>
                  </q-item-section>
                  <q-menu anchor="top right" self="top left">
                    <q-list>
                      <q-item
                        clickable
                        v-close-popup
                        v-for="s in campuses"
                        :key="'current_campus_' + s.Campus.ID"
                        @click="OnChooseLibrary('campus', s.Campus.ID)"
                      >
                        <q-item-section>
                          <q-item-label>{{ s.Campus.Name }}</q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-item>
              </q-list>
            </div>
          </q-btn-dropdown> -->
        </div>

        <q-btn
          size="sm"
          round
          dense
          icon="fullscreen"
          color="e"
          text-color="0"
          @click="ShowFullImageDialog"
        >
          <q-tooltip
            anchor="top right"
            self="bottom right"
            class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
            transition-show="jump-right"
            transition-hide="jump-left"
          >
            Zoom in
          </q-tooltip>
        </q-btn>
      </div>
    </q-img>

    <q-img
      :src="
        newFile.__img
          ? newFile.__img.src
          : '/api/v1/asset/file/library_img/' + fileChosen
      "
      class="full-width full-height"
      :fit="view.fit ? view.fit : 'contain'"
      v-if="status === 'changed' && view.onlyshow !== true"
    >
      <div
        class="fit col column justify-center items-center z-max bg-transparent"
      >
        <q-btn
          round
          dense
          icon="cancel"
          color="negative"
          @click="CancelChange()"
          :loading="saving"
        >
          <q-tooltip
            anchor="top right"
            self="bottom right"
            class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
            transition-show="jump-right"
            transition-hide="jump-left"
          >
            Cancel
          </q-tooltip>
        </q-btn>
      </div>
    </q-img>

    <q-uploader
      class="full-width"
      @added="AddFile"
      :ref="'qUploadFilePicker_' + incomeData.uid"
      max-files="1"
      :filter="FilterUploader"
      style="display: none"
      v-if="view.onlyshow !== true && uploadCreated"
      :accept="fileType.extension_mask_"
    >
    </q-uploader>

    <div
      class="fit col column items-center justify-center"
      v-if="
        status === 'empty' && view.onlyshow === true && showdefault === true
      "
    >
      <img
        :src="view.showdefaultsrc ? view.showdefaultsrc : 'Cc.svg'"
        class="fit"
        :style="{
          'background-color': view.bgdefaultcolor ? view.bgdefaultcolor : '',
          'border-radius': view.borderradius ? view.borderradius : '',
          border: view.border ? view.border : '',
        }"
      />
    </div>

    <div
      class="col column items-center justify-center q-gutter-y-sm"
      v-if="status === 'empty' && view.onlyshow !== true"
    >
      <div class="text-center text-e text-italic text-bold text-v">
        {{ $t("-raw-story-no-image") }}
      </div>
      <div class="row items-center justify-center">
        <q-btn
          color="e"
          flat
          size="md"
          rounded
          icon="file_upload"
          @click="CreateUpload"
        >
          <q-tooltip
            anchor="top right"
            self="bottom right"
            class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
            transition-show="jump-right"
            transition-hide="jump-left"
          >
            {{ $t("-raw-story-pick-up-file") }}
          </q-tooltip>
        </q-btn>

        <q-btn
          size="md"
          split
          color="e"
          rounded
          flat
          @click.stop="OnChooseLibrary('system')"
          @show.stop="OnShowChooseLibrary()"
          transition-show="jump-right"
          transition-hide="jump-left"
        >
          <template #default>
            <q-icon name="local_library">
              <q-tooltip
                anchor="top right"
                self="bottom right"
                class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                transition-show="jump-right"
                transition-hide="jump-left"
              >
                {{ $t("-raw-story-public-library") }}
              </q-tooltip>
            </q-icon>
          </template>
        </q-btn>

        <q-btn
          size="md"
          split
          color="e"
          rounded
          flat
          @click="OnChooseLibrary('personal')"
          v-if="defaultObjectLibrary"
        >
          <q-icon name="photo_library">
            <q-tooltip
              anchor="top right"
              self="bottom right"
              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
              transition-show="jump-right"
              transition-hide="jump-left"
              style="white-space: nowrap"
            >
              {{ $t("-raw-general-personal-library") }}
            </q-tooltip>
          </q-icon>
        </q-btn>

        <!-- see the same twice in code -->
        <q-btn-dropdown
          size="md"
          split
          color="e"
          rounded
          flat
          @click.stop="
            !defaultObjectLibrary
              ? OnChooseLibrary('personal')
              : OnChooseLibrary(
                  'campus',
                  defaultObjectLibrary.CampusID,
                  defaultObjectLibrary.CampusName,
                )
          "
          @show="OnShowChooseLibrary()"
          transition-show="jump-right"
          transition-hide="jump-left"
        >
          <template #label>
            <q-icon name="photo_library" v-if="!defaultObjectLibrary">
              <q-tooltip
                anchor="top right"
                self="bottom right"
                class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                transition-show="jump-right"
                transition-hide="jump-left"
                style="white-space: nowrap"
              >
                {{ $t("-raw-general-personal-library") }}
              </q-tooltip>
            </q-icon>
            <q-icon name="shutter_speed" v-else color="p">
              <q-tooltip
                anchor="top right"
                self="bottom right"
                class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                transition-show="jump-right"
                transition-hide="jump-left"
              >
                {{ $t("-raw-general-campus-library") }}
                <span class="text-e q-pl-sm">{{
                  defaultObjectLibrary.CampusName
                }}</span>
              </q-tooltip>
            </q-icon>
          </template>

          <div class="column row items-center q-pa-md" v-if="!campusesLoaded">
            <q-spinner color="primary" size="md" />
          </div>
          <q-list class="q-pa-md" v-else>
            <div class="text-v text-bold row justify-center q-py-sm">
              {{ $t("-raw-route-campuses") }}
            </div>
            <div class="full-width q-pb-md"><q-separator /></div>
            <div v-if="!Array.isArray(campuses) || !campuses.length">
              {{ $t("-raw-classroom-no-campuses") }}
            </div>
            <q-item
              clickable
              v-close-popup
              v-for="s in campuses"
              :key="'current_campus_' + s.Campus.ID"
              @click.stop="
                OnChooseLibrary('campus', s.Campus.ID, s.Campus.Name)
              "
            >
              <q-item-section>
                <q-item-label>{{ s.Campus.Name }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
          <!-- <div class="column row items-center q-pa-md" v-else>
            <div
              class="text-italic text-v text-bold row justify-center q-py-sm"
            >
              {{ $t("-raw-story-available-libraries") }}
            </div>
            <div class="full-width"><q-separator /></div>

            <q-list>
              <q-item
                clickable
                v-close-popup
                @click="OnChooseLibrary('system')"
              >
                <q-item-section avatar>
                  <q-icon name="collections" color1="v" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ $t("-raw-general-public") }}</q-item-label>
                </q-item-section>
              </q-item>

              <q-item
                clickable
                v-close-popup
                @click="OnChooseLibrary('personal')"
              >
                <q-item-section avatar>
                  <q-icon name="photo_album" color1="v" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ $t("-raw-general-personal") }}</q-item-label>
                </q-item-section>
              </q-item>

              <q-item clickable v-if="campuses && campuses.length > 0">
                <q-item-section avatar>
                  <q-icon name="supervised_user_circle" color1="v" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ $t("-raw-general-campus") }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-icon name="keyboard_arrow_right"></q-icon>
                </q-item-section>
                <q-menu anchor="top right" self="top left">
                  <q-list>
                    <q-item
                      clickable
                      v-close-popup
                      v-for="s in campuses"
                      :key="'current_campus_' + s.Campus.ID"
                      @click="OnChooseLibrary('campus', s.Campus.ID)"
                    >
                      <q-item-section>
                        <q-item-label>{{ s.Campus.Name }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-item>
            </q-list>
          </div> -->
        </q-btn-dropdown>
      </div>
    </div>

    <q-dialog v-model="showFullImage">
      <q-card
        :style="{
          width: `calc(${window.innerWidth * ($q.screen.lt.md ? 1 : 2 / 3)}px)`,
          'max-width': `calc(${
            window.innerWidth * ($q.screen.lt.md ? 1 : 2 / 3)
          }px)`,
        }"
      >
        <q-card-section>
          <q-btn
            text-color="0"
            class="bg-e"
            v-close-popup
            icon="close"
            style="
              position: absolute;
              right: 1rem;
              width: 2rem;
              height: 2rem;
              z-index: 9999 !important;
            "
            no-caps
          ></q-btn>
          <q-img
            :src="link"
            class="full-width"
            fit="contain"
            v-if="showFullImage"
          >
          </q-img>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog
      v-model="showLibraryDialog"
      :maximized="showLibraryDialogMax"
      persistent
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card>
        <q-card-section>
          <q-btn
            text-color="0"
            class="bg-e"
            v-close-popup
            icon="close"
            style="
              position: absolute;
              right: 1rem;
              width: 2rem;
              height: 2rem;
              z-index: 9999 !important;
            "
            no-caps
          ></q-btn>
          <q-page-container
            :style="{ height: `calc(${window.innerHeight}px - 6rem)` }"
          >
            <Library
              :view="{ isFit: true, canChangeType: false, canChoose: true }"
              :data="libraryData"
              class="full-height full-width"
              @choosefile="chooseFile"
            />
          </q-page-container>
        </q-card-section>
        <q-card-actions align="right" class="q-pr-md">
          <q-btn
            color="e"
            outline
            dense
            rounded
            class="q-py-md bg-2"
            icon-left="arrow_back"
            no-caps
            v-close-popup
            style="height: 3rem"
          >
            <span class="q-mx-md">Cancel</span>
          </q-btn>
          <q-btn
            :color="fileChosen == null ? 'v' : 'p'"
            dense
            rounded
            icon-right="arrow_forward"
            no-caps
            v-close-popup
            style="height: 3rem"
            :disabled="fileChosen == null"
            text-color="0"
            @click="chooseFileConfirm"
          >
            <span class="q-mx-md">Confirm</span>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style scoped></style>

<script src="./picker.js"></script>
