<template>
  <div :id="containerID" />
</template>

<script>
import { getCssVar } from "quasar";
import WaveSurfer from "wavesurfer-old.js";
import Regions from "wavesurfer-old.js/dist/plugin/wavesurfer.regions.min.js";

export default {
  props: ["blob", "progressColor", "waveColor", "annotations"],
  emits: ["finish", "play", "pause", "ready"],
  data() {
    return {
      containerID: "c" + this.$h.uuidV1(),
      surfer: null,
    };
  },
  watch: {
    blob: {
      handler(newVal) {
        this.blobHandler(newVal);
      },
      immediate: false, // NOTE: immediate: true on watcher doesn't work, since surfer has not yet been initialized when it's called
    },
    annotations: {
      handler(newVal) {
        this.setAnnotations(newVal);
      },
      immediate: false,
    },
  },
  beforeUnmount() {
    this.surfer.stop();
    this.surfer.unAll();
  },
  mounted() {
    this.surfer = WaveSurfer.create({
      container: "#" + this.containerID,
      barWidth: 3,
      barMinHeight: 1,
      barGap: 3,
      barRadius: 3,
      cursorColor: getCssVar(this.progressColor),
      progressColor: getCssVar(this.progressColor),
      waveColor: getCssVar(this.waveColor),
      height: 48,
      normalize: true,
      interact: false,
      responsive: 100,
      cursorWidth: 2,
      plugins: [
        Regions.create({
          regions: [],
        }),
      ],
    });

    // https://wavesurfer-js.org/docs/events.html
    this.surfer.on("finish", () => {
      this.$emit("finish");
    });
    this.surfer.on("pause", () => {
      this.$emit("pause");
    });
    this.surfer.on("play", () => {
      this.$emit("play");
    });
    this.surfer.on("ready", async () => {
      this.$emit("ready", this.surfer.backend.getDuration());
    });
    this.blobHandler(this.blob);
    this.setAnnotations(this.annotations);
  },
  methods: {
    playOrPause(start, end) {
      if (this.surfer?.isPlaying()) {
        this.surfer.pause();
      } else {
        this.surfer?.play(start, end);
      }
    },
    stop() {
      this.surfer?.stop();
    },
    blobHandler(newVal) {
      if (newVal) {
        this.surfer?.load(newVal);
      }
    },
    setAnnotations(annotations) {
      if (annotations && annotations.length > 0) {
        this.surfer.params.interact = false;
        this.surfer.drawer.setHeight(72 * this.surfer.params.pixelRatio);

        annotations.forEach((annotation, index) => {
          const region = this.surfer.regions.add({
            id: annotation.Transcription,
            start: annotation.SegmentStart,
            end: annotation.SegmentEnd,
            loop: false,
            drag: false,
            resize: false,
          });

          region.on("click", () => {
            region.play();
          });
        });
      } else {
        this.surfer.params.interact = true;
        this.surfer.regions.clear();
        this.surfer.drawer.setHeight(48 * this.surfer.params.pixelRatio);
      }
    },
  },
};
</script>

<style scoped>
::v-deep(.wavesurfer-region) {
  z-index: 3 !important;
  height: 15px !important;
  top: 60px !important;
  padding-right: 1px !important;
  padding-left: 1px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: content-box var(--q-3) !important;
}
::v-deep(.wavesurfer-region:hover) {
  height: 19px !important;
  top: 58px !important;
}
::v-deep(.wavesurfer-region::after) {
  content: attr(data-id);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(0, -80%);
  text-align: center;
}
</style>
