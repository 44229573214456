import { setCssVar, Dark } from "quasar";
export default {
  state: {
    lighter: [
      "#FFFFFF", // 0 - layer 0 background
      "#EEEEEE", // 1 - layer 1 (for big elements)
      "#DDDDDD", // 2 - layer 2 (for big elements:hover state)
      "#CCCCCC", // 3 - layer 3 (for input fields / buttons)
      "#BBBBBB", // 4 - layer 4
      "#AAAAAA", // 5 - for text on layer 0, or standout backgrounds with text 0
      "#999999", // 6 - for text on layer 1, or standout backgrounds with text 0
      "#888888", // 7 - for text on layer 2, or standout backgrounds with text 0
      "#777777", // 8 - for text on layer 3, or standout backgrounds with text 0
      "#666666", // 9 - just don't use this, unlucky number (iPhone 9, Windows 9)
      "#555555", // a - text
      "#444444", // b -
      "#333333", // c -
      "#222222", // d -
      "#111111", // e - standout text

      "#FFFFFF", // f - slightly lighter than 0, for neumorphism shadow
      "#FFFFFF", // t - slightly darker than 0, for neumorphism shadow

      "#666DDD", // v - voccent (primary)
      "#DD66DD", // h - pink (secondary?)
      "#DD6666", // n - negative (error)
      "#229922", // p - positive (success)
      "#FF9900", // w - warning (accent)
      false, // dark
      "lighter", // theme name
    ],
    light: [
      "#EEEEEE", // 0
      "#DDDDDD", // 1
      "#CCCCCC", // 2
      "#BBBBBB", // 3
      "#AAAAAA", // 4
      "#999999", // 5
      "#888888", // 6
      "#777777", // 7
      "#666666", // 8
      "#555555", // 9
      "#444444", // a
      "#333333", // b
      "#222222", // c
      "#111111", // d
      "#000000", // e

      "#FFFFFF", // f
      "#DDDDDD", // t

      "#666DDD", // v
      "#DD66DD", // h
      "#DD6666", // n
      "#229922", // p
      "#FF9900", // w
      false,
      "light",
    ],
    grey: [
      "#333333",
      "#444444",
      "#555555",
      "#666666",
      "#777777",
      "#888888",
      "#999999",
      "#AAAAAA",
      "#bbbbbb",
      "#cccccc",
      "#dddddd",
      "#eeeeee",
      "#ffffff",
      "#ffffff",
      "#ffffff",

      "#424242", // f
      "#282828", // t

      "#888FFF", // v
      "#FF88FF", // h
      "#FF8888", // n
      "#88FF88", // p
      "#FFF888", // w
      true,
      "grey",
    ],
    dark: [
      "#111111",
      "#222222",
      "#333333",
      "#444444",
      "#555555",
      "#666666",
      "#777777",
      "#888888",
      "#999999",
      "#AAAAAA",
      "#bbbbbb",
      "#cccccc",
      "#dddddd",
      "#eeeeee",
      "#ffffff",

      "#181818", // f
      "#080808", // t

      "#888FFF", // v
      "#FF88FF", // h
      "#FF8888", // n
      "#88FF88", // p
      "#FFF888", // w
      true,
      "dark",
    ],
    darker: [
      "#000000",
      "#111111",
      "#222222",
      "#333333",
      "#444444",
      "#555555",
      "#666666",
      "#777777",
      "#888888",
      "#999999",
      "#aaaaaa",
      "#bbbbbb",
      "#cccccc",
      "#dddddd",
      "#eeeeee",

      "#000000", // f
      "#000000", // t

      "#888FFF", // v
      "#FF88FF", // h
      "#FF8888", // n
      "#88FF88", // p
      "#FFFF88", // w
      true,
      "darker",
    ],
    // NOTE: https://slackthemes.net/
    // TODO: elementary GTk, Github(light/dark), Atom OneDark/Arc Dark, Discord, Drakula, Base16 Default Dark, Base16 Ocean Dark, Kill Bill, High Contrast, Latte, Material, Spotify, Twitch, Tomorrow Dark, YouTube, Vue.js, etc.
    // other: [
    //   "#EEAA77",
    //   "#DD9966",
    //   "#CC8855",
    //   "#BB7744",
    //   "#AA6633",
    //   "#995522",
    //   "#884411",
    //   "#773300",
    //   "#773300",
    //   "#773300",
    //   "#F8B888",
    //   "#E0A066",
    //   "#446688"
    // ],
    // blue: [
    //   "#446688",
    //   "#335577",
    //   "#224466",
    //   "#113355",
    //   "#002244",
    //   "#002244",
    //   "#002244",
    //   "#002244",
    //   "#002244",
    //   "#002244",
    //   "#557799",
    //   "#335577",
    //   "#FFFF77"
    // ],
    // green: [
    //   "#449944",
    //   "#338833",
    //   "#227722",
    //   "#116611",
    //   "#005500",
    //   "#005500",
    //   "#005500",
    //   "#005500",
    //   "#005500",
    //   "#005500",
    //   "#55AA55",
    //   "#338833",
    //   "#FFFF77"
    // ]
  },
  getters: {
    getTheme: () => {
      return JSON.parse(localStoragePolyFill.getItem("theme"));
    },
    getDefaultTheme: (state) => state.dark,
  },
  actions: {
    setTheme: ({}, payload) => {
      Dark.set(payload[22]);

      setCssVar("0", payload[0]);
      setCssVar("1", payload[1]);
      setCssVar("2", payload[2]);
      setCssVar("3", payload[3]);
      setCssVar("4", payload[4]);
      setCssVar("5", payload[5]);
      setCssVar("6", payload[6]);
      setCssVar("7", payload[7]);
      setCssVar("8", payload[8]);
      setCssVar("9", payload[9]);
      setCssVar("a", payload[10]);
      setCssVar("b", payload[11]);
      setCssVar("c", payload[12]);
      setCssVar("d", payload[13]);
      setCssVar("e", payload[14]);

      setCssVar("f", payload[15]);
      setCssVar("t", payload[16]);

      setCssVar("v", payload[17]);
      setCssVar("h", payload[18]);
      setCssVar("n", payload[19]);
      setCssVar("p", payload[20]);
      setCssVar("w", payload[21]);
    },
    setSaveTheme: ({ dispatch }, payload) => {
      localStoragePolyFill.setItem("theme", JSON.stringify(payload));
      dispatch("setTheme", payload);
    },
  },
};
