<template>
  <div
    class="q-pa-sm full-height full-width column no-wrap channel bg-0"
    style="
      dislpay: inline-block;
      border-radius: 1rem;
      position: relative;
      overflow: hidden;
    "
  >
    <!-- <div
         class="text-8 sub absolute q-pa-sm ubuntu"
         style="
         border-top-right-radius: 1rem;
         left: 0;
         bottom: 0;
         font-size: 0.75rem;
         "
         >
         {{ $t("-raw-channel") }}
         </div> -->
    <!-- <div
         class="bg-2 text-8 sub absolute q-pa-sm ubuntu"
         style="
         border-top-left-radius: 1rem;
         right: 0;
         bottom: 0;
         font-size: 0.75rem;
         "
         >
         {{ handleDate(item.CreatedAt || item.createdat) }}
         </div> -->
    <div class="row" :class="$q.screen.gt.sm ? 'q-pa-sm' : 'q-pa-xs'">
      <div class="column" style="position: absolute; right: 0px; z-index: 0">
        <q-avatar
          size="6rem"
          color="0"
          class="q-mr-md bg-0"
          style="border-radius: 3rem"
        >
          <img
            style="border: 1px solid var(--q-0); opacity: 0.5"
            :src="handleChannelAvatar()"
            onerror="this.onerror=null;this.src='Cc.svg';"
          />
        </q-avatar>
        <div
          v-if="item.Rating"
          style="height: 1rem; font-size: 0.9rem; font-weight: bold"
        >
          <q-icon name="star" />
          {{ item.Rating.toFixed(1)
          }}<span style="font-size: 0.6rem">/{{ item.CountRating }}</span>
          <q-tooltip
            anchor="top middle"
            self="bottom middle"
            class="bg-4 text-8 round-both"
            transition-show="jump-up"
            transition-hide="jump-down"
          >
            <b
              >{{ $t("-raw-challenge-rated-by") }} {{ item.CountRating }}
              {{ $t("-raw-challenge-rated-by-users") }}</b
            >
          </q-tooltip>
        </div>
      </div>
      <div>
        <div
          class="col column no-wrap col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-7 column"
          style="
            font-size: 1.7rem;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-stroke: 1px var(--q-0);
            text-fill-color: var(--q-9);
            -webkit-text-stroke: 1px var(--q-0);
            -webkit-text-fill-color: var(--q-9);
            paint-order: stroke fill;
          "
        >
          {{ item.Name?.substring(0, 20) }}
          {{ item.Name.length > 20 ? "..." : "" }}
        </div>
        <div class="items-start q-mx-xs" style="overflow: hidden">
          <div class="text-6" style="font-size: 1rem" v-if="item?.Description">
            {{ item.Description?.substring(0, 32) }}
            {{ item.Description.length > 32 ? "..." : "" }}
          </div>
          <br />
          <div class="text-4">
            <span style="font-weight: bold">{{ $t("-raw-author") }}: </span>
            <template v-if="item?.UserName">{{ item?.UserName }}</template>
            <template v-else>{{ item?.CreatedByName }}</template>
          </div>
          <div
            class="text-4"
            v-if="
              item.LanguageIDs &&
              Array.isArray(item.LanguageIDs) &&
              item.LanguageIDs.length > 0
            "
          >
            <span style="font-weight: bold"
              >{{
                $t("-raw-languages") === "-raw-languages"
                  ? "Languages"
                  : $t("-raw-languages")
              }}:
            </span>
            <template
              v-for="(lng1, n1) in item.LanguageIDs"
              :key="n1 + 'channel_languages_list1'"
            >
              <template
                v-for="(lng2, n2) in $store.getters.languages"
                :key="n2 + 'channel_languages_list2'"
              >
                <template v-if="lng1 === lng2.ID"> {{ lng2.name }}; </template>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.channel {
  cursor: pointer;
  user-select: none;
  border: 2px solid var(--q-1);
}
.channel:hover {
  border: 2px dashed var(--q-v);
}
</style>

<script>
export default {
  props: ["item"],
  methods: {
    handleChannelAvatar() {
      return this.item.Asset?.channel_avatar
        ? process.env.PUBLIC_API_URL +
            "/api/v1/asset/file/channel_avatar/" +
            this.item.Asset?.channel_avatar[0]
        : "Cc.svg";
    },
    handleDate(datesrc) {
      const date = new Date(datesrc);
      return date.toDateString();
    },
  },
};
</script>
