/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/en-US'

import iconSet from 'quasar/icon-set/material-icons'



import {Notify,Dialog} from 'quasar'



export default { config: {"dark":false,"brand":{"0":"#EEEEEE","1":"#E8E8E8","2":"#CCCCCC","3":"#BBBBBB","4":"#AAAAAA","5":"#999999","6":"#888888","7":"#777777","8":"#666666","9":"#555555","a":"#444444","b":"#333333","c":"#222222","d":"#111111","e":"#000000","f":"#FFFFFF","t":"#DDDDDD","v":"#666DDD","v_rgb":"102, 109, 221","h":"#DD66DD","n":"#DD6666","n_rgb":"221, 102, 102","p":"#229922","p_rgb":"34, 153, 34","w":"#FF9900","w_rgb":"ff, 99, 00","ww":"#FF8800","primary":"#666FFF","negative":"#DD6666"}},lang,iconSet,plugins: {Notify,Dialog} }

