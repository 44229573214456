import api from "src/h/api.js";
import h from "../h/helpers";

export default {
  state: {
    accessTypes: null,
    privacySettings: {
      ObjectID: null,
      Privacy: null,
      ShareWith: null,
      Type: null,
    },
  },
  getters: {
    privacy: (state) => state.privacySettings.Privacy,
    sharedWith: (state) => state.privacySettings.ShareWith,
    accessTypes: (state) => state.accessTypes,
    organization: (state) => ({
      ID: state.privacySettings.OrganizationID,
      Name: state.privacySettings.OrganizationName,
      ShowForAll: state.privacySettings.ShowForAll,
    }),
  },
  mutations: {
    setAccessTypes: (state, payload) => (state.accessTypes = payload),
    setPrivacySettings: (state, payload) => (state.privacySettings = payload),
    addShareWith: (state, payload) => {
      let objIndex;
      if (!state.privacySettings.ShareWith) {
        state.privacySettings.ShareWith = [];
      }
      objIndex = state.privacySettings.ShareWith.findIndex(
        (obj) => obj.ShareWithUser == payload.ShareWithUser,
      );
      if (objIndex === -1) {
        state.privacySettings.ShareWith.push(payload);
      }
    },
    deleteShareWith: (state, payload) => {
      state.privacySettings.ShareWith.splice(
        state.privacySettings.ShareWith.findIndex(
          (obj) => obj.ShareWithUser === payload,
        ),
        1,
      );
    },
    updatePrivacy: (state, payload) =>
      (state.privacySettings.Privacy = payload),
    updateShowForAll: (state, payload) =>
      (state.privacySettings.ShowForAll = payload),
    updatePermission: (state, payload) => {
      let objIndex;
      objIndex = state.privacySettings.ShareWith.findIndex(
        (obj) => obj.ShareWithUser == payload.userName,
      );
      let permissions = [];
      if (payload.permissions) {
        permissions = payload.permissions;
      } else {
        permissions = [payload.permission];
      }

      for (let i = 0; i < permissions.length; i++) {
        if (
          state.privacySettings.ShareWith[objIndex].Permissions.indexOf(
            permissions[i],
          ) === -1
        ) {
          state.privacySettings.ShareWith[objIndex].Permissions.push(
            permissions[i],
          );
        } else {
          state.privacySettings.ShareWith[objIndex].Permissions.splice(
            state.privacySettings.ShareWith[objIndex].Permissions.indexOf(
              permissions[i],
            ),
            1,
          );
        }
      }
    },
  },
  actions: {
    getAccessTypes({ commit }, objType) {
      api
        .Call({
          url: `/api/v1/dictionary/sharepermission/${objType}`,
          method: "get",
        })
        .then(
          (res) => {
            // console.log(res)
            commit("setAccessTypes", res);
          },
          (e) => {
            console.log(e);
          },
        );
    },
    getPrivacySettings({ rootState, commit }, objType) {
      let id;
      switch (objType) {
        case "channel":
          id = rootState.channels.currentchannel.ID;
          break;
        // case "challenge":
        //   id = rootState.edit.challenge.ID;
        //   break;
        // be careful! right now use new version challenge-studio
        case "challenge":
          id = rootState.challenge.currentChallenge.ID;
          objType = "challenge"; // need for api
          break;
        case "playlist":
          id = rootState.playlist.playlistID;
          objType = "playlist"; // need for api
          break;
        case "story":
          id = rootState.story.currentStory.ID;
          break;
      }
      api
        .Call({
          url: `/api/v1/share/object/${objType}/${id}`,
          method: "get",
        })
        .then(
          (res) => {
            console.log(res);
            commit("setPrivacySettings", res);
          },
          (e) => {
            console.log(e);
          },
        );
    },
    updatePrivacy({ state, commit, dispatch }, payload) {
      commit("updatePrivacy", payload);
      let tmp = {};
      h.CopyObject(state.privacySettings, tmp);
      // drop ShowForAll to avoid making unnecessary SQL requests
      tmp.ShowForAll = null;
      api
        .Call({
          url: `/api/v1/share/${state.privacySettings.Type}/${state.privacySettings.ObjectID}`,
          method: "patch",
          data: tmp,
          show_error: true,
          show_success: true,
        })
        .then(
          (res) => {
            dispatch("getPrivacySettings", state.privacySettings.Type);
          },
          (e) => {
            console.log(e);
            dispatch("getPrivacySettings", state.privacySettings.Type);
          },
        );
    },
    updateShowForAll({ state, commit, dispatch }, payload) {
      commit("updateShowForAll", payload);
      api
        .Call({
          url: `/api/v1/share/${state.privacySettings.Type}/${state.privacySettings.ObjectID}`,
          method: "patch",
          data: state.privacySettings,
          show_error: true,
          show_success: true,
        })
        .then(
          (res) => {
            dispatch("getPrivacySettings", state.privacySettings.Type);
          },
          (e) => {
            console.log(e);
            dispatch("getPrivacySettings", state.privacySettings.Type);
          },
        );
    },
    shareWithUser({ state, commit, dispatch }, payload) {
      commit("addShareWith", payload);
      api
        .Call({
          url: `/api/v1/share/${state.privacySettings.Type}/${state.privacySettings.ObjectID}`,
          method: "patch",
          data: state.privacySettings,
          show_error: true,
          show_success: true,
        })
        .then(
          (res) => {
            dispatch("getPrivacySettings", state.privacySettings.Type);
          },
          (e) => {
            console.log(e);
            dispatch("getPrivacySettings", state.privacySettings.Type);
          },
        );
    },
    deleteShare({ state, commit, dispatch }, payload) {
      commit("deleteShareWith", payload);
      api
        .Call({
          url: `/api/v1/share/${state.privacySettings.Type}/${state.privacySettings.ObjectID}`,
          method: "patch",
          data: state.privacySettings,
          show_error: true,
          show_success: true,
        })
        .then(
          (res) => {
            dispatch("getPrivacySettings", state.privacySettings.Type);
          },
          (e) => {
            console.log(e);
            dispatch("getPrivacySettings", state.privacySettings.Type);
          },
        );
    },
    updatePermission({ state, commit, dispatch }, payload) {
      commit("updatePermission", payload);
      api
        .Call({
          url: `/api/v1/share/${state.privacySettings.Type}/${state.privacySettings.ObjectID}`,
          method: "patch",
          data: state.privacySettings,
          show_error: true,
          show_success: true,
        })
        .then(
          (res) => {
            dispatch("getPrivacySettings", state.privacySettings.Type);
          },
          (e) => {
            console.log(e);
            dispatch("getPrivacySettings", state.privacySettings.Type);
          },
        );
    },
  },
};
