<template>
  <div
    id="avatar"
    flat
    :aria-label="to"
    class="q-pa-none z-max bg-0"
    style="
      border-radius: 0.75rem;
      overflow: hidden;
      cursor: pointer;
      height: 3rem;
      width: 3rem;
    "
    @click="$router.push(encodeURI(to))"
  >
    <q-tooltip
      v-if="to !== '/settings' && decodeURI($route.path) !== to"
      anchor="top middle"
      self="bottom middle"
      :offset="[0, 8]"
      class="mytooltip ubuntu text-9 bg-2 row round-1 items-center q-px-lg"
      transition-show="jump-up"
      transition-hide="jump-down"
    >
      {{ to.replace("/c/id/", "").replace("/c/", "") }}
    </q-tooltip>
    <div
      v-if="!channel"
      class="full-height full-width"
      style="border-radius: 1.5rem; position: relative"
    >
      <!-- <q-icon v-if="!userAvatarURL && decodeURI(this.$route.path).startsWith('/settings')" name="add_a_photo" color="e" class="absolute z-top" style="top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: .5" /> -->
      <q-img v-if="!userAvatarURL" src="undraw_profile_pic_ic-5-t.svg" />
      <q-img
        v-if="userAvatarURL"
        :src="userAvatarURL"
        class="full-height full-width"
      />
    </div>
    <!-- Settings-Only Avatar Above -->
    <!-- TODO: unify the two -->
    <!-- Channel Avatar below -->
    <q-img
      v-else
      no-spinner
      :src="channelAvatarURL"
      class="full-height full-width"
      style="border-radius: 0.5rem"
    >
      <template #error>
        <q-img
          no-spinner
          class="full-height full-width bg-transparent"
          src="undraw_profile_pic_ic-5-t.svg"
        />
      </template>
    </q-img>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["to", "shirt", "dy", "channel"],
  data() {
    return {
      newUserAvatar: null,
    };
  },
  computed: {
    ...mapGetters(["user", "updatedUser", "userAvatarURL"]),
    localChannelAvatar() {
      return this.channel.avatar;
    },
    channelAvatarURL() {
      const assetID = this.channel.Asset?.channel_avatar;
      return this.localChannelAvatar
        ? URL.createObjectURL(this.localChannelAvatar, { oneTimeOnly: true })
        : assetID
        ? process.env.PUBLIC_API_URL +
          "/api/v1/asset/file/channel_avatar/" +
          assetID[0]
        : "";
    },
  },
};
</script>
