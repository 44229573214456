export default {
  state: {
    objectLibrary: {},
    ttsVoice: {},
  },
  getters: {
    getObjectLibrary: (state) => (key) => {
      return state.objectLibrary[key] ? state.objectLibrary[key] : null;
    },
    getTTSVoice: (state) => {
      return state.ttsVoice;
    },
  },
  mutations: {
    setObjectLibrary: (state, payload) => {
      // saved example:
      // [%storyID%]: {CampusID:x,CampusName:y}
      if (typeof state.objectLibrary !== "object") {
        state.objectLibrary = {};
      }
      if (
        !(
          payload.value === null ||
          payload.value === undefined ||
          payload.value === "object"
        )
      ) {
        // delete state.objectLibrary[payload.key];
        state.objectLibrary[payload.key] = payload.value;
      } else if (state.objectLibrary.hasOwnProperty(payload.key)) {
        delete state.objectLibrary[payload.key];
      }
    },
    setTTSVoice: (state, payload) => {
      if (typeof state.ttsVoice !== "object") {
        state.ttsVoice = {};
        return;
      }
      state.ttsVoice = payload;
    },
  },
};
