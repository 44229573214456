import api from "src/h/api.js";
import { Notify } from "quasar";
import { i18n } from "src/boot/i18n.js";
var log = console.log.bind(console);
var loge = console.error.bind(console);
const P = "channels";

export default {
  state: {
    channels: [],
    newloading: false,
    subLoading: false,
    currentchannel: {
      ID: null,
      Name: null,
      Description: null,
      SourceLanguageID: null,
      Privacy: null,
      Share: null,
      createdat: null,
      createdby: null,
      updatedat: null,
      Edit: false,
      NewName: null,
    },
    channelContentSearchString: null,
    subscription: null,
    favorite: null,
  },
  getters: {
    channels: (state) => state.channels,
    currentchannel: (state) => state.currentchannel,
    channelContentSearchString: (state) => state.channelContentSearchString,
  },
  mutations: {
    changeName: (state, payload) => (state.currentchannel.Name = payload),
    changeDesc: (state, payload) =>
      (state.currentchannel.Description = payload),
    changeSourceLanguageID: (state, payload) =>
      (state.currentchannel.SourceLanguageID = payload),
    setChannels: (state, payload) => (state.channels = payload),
    setFavoriteChannels: (state, payload) =>
      (state.favoritesChannels = payload),
    newloading: (state, payload) => (state.newloading = payload),
    subLoading: (state, payload) => (state.subLoading = payload),
    setCurrentChannel: (state, payload) => (state.currentchannel = payload),
    setCurrentChannelEdit: (state, payload) =>
      (state.currentchannel.Edit = payload),
    setCurrentChannelNewName: (state, payload) =>
      (state.currentchannel.NewName = payload),
    setChannelContentSearchString: (state, payload) =>
      (state.channelContentSearchString = payload),
    deleteChannel: (state, payload) => state.channels.splice(payload, 1),
    addChannel: (state, payload) => {
      state.channels.push(payload);
    },
    setFavorite: (state, payload) => (state.favorite = payload),
    setSubscription: (state, payload) => (state.subscription = payload),
    setChAvatar: (state, payload) => {
      const channelIndex = state.channels.findIndex(
        (ch) => ch.ID === payload.id,
      );
      if (channelIndex !== -1) {
        state.channels[channelIndex].avatar = payload.a;
      }
    },
    setChBanner: (state, payload) => {
      const channelIndex = state.channels.findIndex(
        (ch) => ch.ID === payload.id,
      );
      if (channelIndex !== -1) {
        state.channels[channelIndex].banner = payload.a;
      }
    },
    setChannel: (state, payload) => {
      const channelIndex = state.channels.findIndex(
        (ch) => ch.ID === payload.id,
      );
      if (channelIndex !== -1) {
        state.channels[channelIndex] = state.currentchannel;
      }
    },
  },
  actions: {
    getChByName({ commit }, name) {
      return api
        .Call({
          url: `/api/v1/channel/name/${encodeURI(name)}`,
          method: "get",
        })
        .then(
          (c) => {
            commit("setCurrentChannel", c);
          },
          (e) => {
            console.error(e);
            // this.$router.push("/");
          },
        );
    },
    getChByID({ commit }, id) {
      return api
        .Call({
          url: `/api/v1/channel/id/${id}`,
          method: "get",
        })
        .then(
          (c) => {
            commit("setCurrentChannel", c);
          },
          (e) => {
            console.error(e);
            // this.$router.push("/");
          },
        );
    },
    getChannels({ commit }) {
      api
        .Call({
          url: "/api/v1/search/feed?type=channel&my=true&sort=Name:asc",
          method: "get",
        })
        .then(
          (channels) => {
            // console.log('Channels: ', channels)
            if (Array.isArray(channels) && channels.length > 0) {
              commit(
                "setChannels",
                channels
                  .filter((item) => !!item.Object)
                  .map((item) => item.Object),
              );
            }
          },
          (e) => {
            console.log(
              "%cchannels.vue:%c/api/v1/channels/my/",
              " margin-bottom: 0.5rem; background: #34495E; color: #41B883; padding-left: 0.5rem; font-weight: bold; padding-right: 1.5rem; margin-right: -1rem; border-top-left-radius: 1rem; border-bottom-left-radius: 1rem;",
              " margin-bottom: 0.5rem; background: #FF7777; color: black; border-radius: 1rem;",
              e,
            );
          },
        );
    },
    getFavoriteChannels({ commit }) {
      api
        .Call({
          url: "/api/v1/channels/favorited?limit=32&offset=0",
          method: "get",
        })
        .then(
          (channels) => {
            // console.log('Fav Channels: ', channels)
            if (channels?.length > 0) {
              commit("setFavoriteChannels", channels);
            }
          },
          (e) => {
            console.log(
              "%channels.vue:%c/api/v1/channels/favorited?limit=32&offset=0",
              " margin-bottom: 0.5rem; background: #34495E; color: #41B883; padding-left: 0.5rem; font-weight: bold; padding-right: 1.5rem; margin-right: -1rem; border-top-left-radius: 1rem; border-bottom-left-radius: 1rem;",
              " margin-bottom: 0.5rem; background: #FF7777; color: black; border-radius: 1rem;",
              e,
            );
          },
        );
    },
    newChannel({ commit, getters }, name) {
      let sourceLanguageID = null;
      if (getters.languages) {
        sourceLanguageID = getters.languages.find(
          (val) => val.locale === i18n.global.locale,
        )?.ID;
      }
      if (/[$%^*/]/.exec(name)) {
        Notify.create({
          message: "Channel name can not contain special characters",
          color: "negative",
        });
        return;
      }
      name = name.trim();
      commit("newloading", true);
      api
        .Call({
          url: "/api/v1/channel",
          method: "post",
          show_error: true,
          data: {
            Name: name,
            Description: name,
            // don't use: always create private channel with no share
            // Privacy: "public",
            // Share: [
            //   // {permission:ro, shared_with_email: rrr@gmail.com},
            //   {
            //     permission: "rw",
            //     shared_with_email: getters.user.cred_id,
            //   },
            // ],
            SourceLanguageID: sourceLanguageID,
          },
        })
        .then(
          (res) => {
            console.log(res);
            commit("newloading", false);
            commit("addChannel", res);
            commit("setCurrentChannel", res);
            // NOTE: Construction like: this.$router.push(`/c/${res.Name}`); doesn't work on iPad Safari.
            this.$router.push("/c/" + res.Name);
          },
          (e) => {
            commit("newloading", false);
          },
        );
    },
    saveChannel({ state, commit }, id) {
      function reduceString(str) {
        var s = str
          .replace(/[\x00-\x09\x0B-\x0C\x0E-\x1F\x7F-\x9F\s\xA0]/g, " ")
          .replaceAll("  ", " ")
          .trim();
        while (s.includes("  ")) {
          s = s.replaceAll("  ", " ");
        }
        return s;
      }

      if (
        reduceString(state.currentchannel.Name) !== state.currentchannel.Name
      ) {
        commit("changeName", reduceString(state.currentchannel.Name));
      }
      api
        .Call({
          url: `/api/v1/channel/id/${id}`,
          method: "PATCH",
          data: state.currentchannel,
          show_error: true,
          show_success: true,
        })
        .then(
          (c) => {
            // dispatch('getChannels') // resets channels
            commit("setChannel", { id });
            this.$router.push(c.Name ? `/c/${c.Name}` : `/c/id/${c.ID}`);
            console.log(c);
          },
          (e) => {
            console.error(e);
          },
        );
    },
    saveChannelAvatar({ state, commit }, a) {
      // TODO: make saveAsset helper
      const formData = new FormData();
      formData.append("object_id", state.currentchannel.ID);
      formData.append("file_type", "channel_avatar");
      formData.append("file", a);

      api
        .Call({
          url: "/api/v1/asset",
          data: formData,
          method: "post",
        })
        .then(
          (postAssetResponse) => {
            commit("setChAvatar", { id: state.currentchannel.ID, a: a });
            console.log("savedAvatar: ", postAssetResponse);
          },
          (e) => {
            commit("setChAvatar", false);
            console.error(
              "store/channels.js" + "error-0af8a988",
              "postAssetCompare",
              e,
            );
          },
        );
    },
    saveChannelBanner({ state, commit }, b) {
      // TODO: make saveAsset helper
      const formData = new FormData();
      formData.append("object_id", state.currentchannel.ID);
      formData.append("file_type", "channel_banner");
      formData.append("file", b);

      api
        .Call({
          url: "/api/v1/asset",
          data: formData,
          method: "post",
        })
        .then(
          (postAssetResponse) => {
            commit("setChBanner", { id: state.currentchannel.ID, b: b });
            console.log("savedBanner: ", postAssetResponse);
          },
          (e) => {
            commit("setChBanner", false);
            console.error(
              "store/channels.js" + "error-0af8a988",
              "postAssetCompare",
              e,
            );
          },
        );
    },
    deleteChannel({ state, commit }, id) {
      api.CallConfirmation(
        {
          method: "delete",
        },
        () => {
          return new Promise((resolve, reject) => {
            api.CallUndo(
              {
                message: i18n.global.t("-raw-message-del-channel"),
                ID: "channel_" + id,
              },
              () => {
                return api
                  .Call({
                    url: `/api/v1/channel/id/${id}`,
                    method: "DELETE",
                  })
                  .then(
                    (affectedCount) => {
                      this.$router.push("/");
                      commit(
                        "deleteChannel",
                        state.channels.map((item) => item.ID).indexOf(id),
                      );
                    },
                    (e) => {
                      console.error(e);
                    },
                  );
              },
            );
            reject();
          });
        },
      );
    },
    getFavorite({ commit, state }) {
      if (!state.currentchannel.ID) return;
      commit("subLoading", true);
      api
        .Call({
          url: "/api/v1/favorite/object/channel/" + state.currentchannel.ID,
          method: "get",
          show_error: false,
          show_success: false,
        })
        .then(
          (res) => {
            if (res?.IsFavorite === true) {
              commit("setFavorite", true);
            } else {
              commit("setFavorite", false);
            }
            if (res?.MailSubscription === true) {
              commit("setSubscription", true);
            } else {
              commit("setSubscription", false);
            }
            commit("subLoading", false);
          },
          (e) => {
            console.error("subscription: ", e);
            commit("subLoading", false);
          },
        );
    },
    addToFavorite({ commit, dispatch, state }, payload) {
      let url = "/api/v1/favorite/channel/" + state.currentchannel.ID;
      commit("subLoading", true);
      api.CallUndo(
        {
          message: "Add to favorites",
          ID: "channel_" + state.currentchannel.ID,
        },
        function () {
          return api
            .Call({
              url: url,
              method: "post",
              show_error: true,
              show_success: true,
            })
            .then(
              (res) => {
                dispatch("getFavorite");
                dispatch("getFavoriteChannels");
                commit("subLoading", false);
              },
              (e) => {
                loge(P + "error-9a11984a", "add channel in favorite", e);
                commit("subLoading", false);
              },
            );
        },
      );
    },
    addToFavoriteAndSubscribe({ commit, dispatch, state }, payload) {
      let url = "/api/v1/favorite/channel/" + state.currentchannel.ID;
      let url_ = "/mail/subscribe_user_channel/" + state.currentchannel.ID;
      commit("subLoading", true);
      api.CallUndo(
        {
          message: "Add to favorites and subscribe",
          ID: "channel_" + state.currentchannel.ID,
        },
        function () {
          return api
            .Call({
              url: url,
              method: "post",
              show_error: false,
              show_success: false,
            })
            .then(
              (res) => {},
              (e) => {
                loge(P + "error-9a119841", "add channel to favorite", e);
                commit("subLoading", false);
              },
            )
            .then(() => {
              api
                .Call({
                  url: url_,
                  method: "post",
                  show_error: true,
                  show_success: true,
                })
                .then(
                  (res) => {
                    dispatch("getFavorite");
                    dispatch("getFavoriteChannels");
                    commit("subLoading", false);
                  },
                  (e) => {
                    loge(P + "error-9a119841", "subscribe", e);
                    commit("subLoading", false);
                  },
                );
            });
        },
        function () {
          commit("subLoading", false);
        },
      );
    },
    removeFromFavorite({ commit, dispatch, state }, payload) {
      let url = "/api/v1/favorite/channel/" + state.currentchannel.ID;
      commit("subLoading", true);
      api.CallUndo(
        {
          message: "Remove from favorites",
          ID: "channel_" + state.currentchannel.ID,
        },
        function () {
          return api
            .Call({
              url: url,
              method: "delete",
              show_error: true,
              show_success: false,
            })
            .then(
              (res) => {
                api
                  .Call({
                    url:
                      "/mail/unsubscribe_user_channel/" +
                      state.currentchannel.ID,
                    method: "post",
                    show_error: true,
                    show_success: true,
                  })
                  .then(
                    (res) => {
                      dispatch("getFavorite");
                      dispatch("getFavoriteChannels");
                      commit("subLoading", false);
                    },
                    (e) => {
                      loge(
                        P + "error-bbbc1cd1",
                        "subscribe_user_channel/unsubscribe_user_channel",
                        e,
                      );
                      commit("subLoading", false);
                    },
                  );
              },
              (e) => {
                loge(P + "error-aa6b1894", "remove channel from favorite", e);
                commit("subLoading", false);
              },
            );
        },
        function () {
          commit("subLoading", false);
        },
      );
    },
    updateSubscribe({ commit, dispatch, state }, payload) {
      let url;
      commit("subLoading", true);
      if (payload == true) {
        url = "/mail/subscribe_user_channel/";
      } else {
        url = "/mail/unsubscribe_user_channel/";
      }
      api
        .Call({
          url: url + state.currentchannel.ID,
          method: "post",
          show_error: true,
          show_success: true,
        })
        .then(
          (res) => {
            dispatch("getFavorite");
            dispatch("getFavoriteChannels");
            commit("subLoading", false);
          },
          (e) => {
            loge(
              P + "error-bbbc1cda",
              "subscribe_user_channel/unsubscribe_user_channel",
              e,
            );
            commit("subLoading", false);
          },
        );
    },
  },
};
