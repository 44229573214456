export default {
  state: {
    currentReport: null,
    lastReportRequested: null,
  },
  getters: {
    currentReport: (state) => state.currentReport,
    lastReportRequested: (state) => state.lastReportRequested,
  },
  mutations: {
    setCurrentReport: (state, payload) => (state.currentReport = payload),
    setLastReportRequested: (state, payload) =>
      (state.lastReportRequested = payload),
  },
};
