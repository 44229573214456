import helpers from "src/h/helpers.js";

export default ({ app }) => {
  // console.log('%cboot/helpers.js ($h & window object)', 'background: black; font-weight: bold; color: white;')

  app.config.globalProperties.$h = helpers;
  // exposing global window object here for now
  app.config.globalProperties.window = window;
  app.config.devtools = true;
};
