<template>
  <q-btn
    flat
    icon="translate"
    style="
      height: 2.5rem;
      color: var(--lc);
      padding: 0 !important;
      font-size: 0.75rem;
      width: 2.5rem;
    "
  >
    <q-menu
      transition-show="jump-up"
      transition-hide="jump-down"
      auto-close
      id="localizator"
      :offset="[0, 32]"
      anchor="top left"
      self="bottom left"
      class="no-shadow z-max menu"
      style="scroll-behavior: smooth"
    >
      <div class="column">
        <q-btn
          flat
          fit
          class="z-top scrlbtn1"
          style="position: sticky; top: 0"
          icon="keyboard_arrow_up"
          @mouseover="scrollToFirst()"
          @click.stop=""
        />
        <q-item
          v-for="item in languages"
          :key="item.name"
          :id="item.name"
          @click="language = item"
          class="text-center"
          clickable
          v-ripple
        >
          <q-item-section style="color: var(--lc)">
            {{ item.name }}
          </q-item-section>
        </q-item>
        <q-btn
          flat
          fit
          class="scrlbtn2"
          style="position: sticky; bottom: 0"
          icon="keyboard_arrow_down"
          @mouseover="scrollToLast()"
          @click.stop=""
        />
      </div>
    </q-menu>
  </q-btn>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      language: null,
    };
  },
  methods: {
    // NOTE: Safari doesn't support smooth scrolling
    scrollToFirst() {
      // const target = document.querySelector(`#${this.languages[0].name}`);
      // target.scrollIntoView({ behavior: "smooth", block: "end" });
      const container = document.querySelector("#localizator");
      container.scrollTo({ top: 0, behavior: "smooth" });
    },
    scrollToLast() {
      // const target = document.querySelector(
      //   `#${this.languages[this.languages.length - 1].name}`
      // );
      // target.scrollIntoView({ behavior: "smooth", block: "start" });
      const container = document.querySelector("#localizator");
      container.scrollTo({ top: container.scrollHeight, behavior: "smooth" });
    },
  },
  computed: {
    ...mapGetters(["languages"]),
  },
  mounted() {
    this.$store.dispatch("fetchLanguages");
    this.language = JSON.parse(
      localStoragePolyFill.getItem("interface language")
    );
  },
  watch: {
    language: function (val) {
      if (val) {
        this.$store.commit("setLanguageUI", val);
        this.$i18n.locale = val.locale;
        localStoragePolyFill.setItem("interface language", JSON.stringify(val));
      }
    },
  },
};
</script>

<style>
.menu {
  background: hsl(var(--l2), 50%);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  background-blend-mode: overlay;
  border: 1px solid #fff2;
  border-radius: 1.25rem;
  /* padding: 0.5rem 0; */
  color: var(--lc);
}
.scrlbtn1 {
  background-color: hsl(var(--l2));
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  background-blend-mode: overlay;
  /* border: 1px solid #fff2; */
  border-top: 0 !important;
  border-radius: 0.75rem;
  margin: 0 0.75rem;
}
.scrlbtn2 {
  background-color: hsl(var(--l2));
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  background-blend-mode: overlay;
  /* border: 1px solid #fff2; */
  border-bottom: 0 !important;
  border-radius: 0.75rem;
  margin: 0 0.75rem;
}
</style>
