<template>
  <q-btn
    class="btn q-ml-sm rounded-12"
    :style="{ backgroundColor: color, color: textColor }"
    style="height: 2.5rem !important; pointer-events: auto"
    flat
    no-caps
    :href="link"
  >
    <div class="row no-wrap items-center">
      <div class="mont-600" style="white-space: nowrap">
        {{ name }}
      </div>
      <q-icon v-if="icon" class="icn q-ml-sm" size="xs" :name="icon" />
    </div>
  </q-btn>
</template>

<script setup>
defineProps({
  color: String,
  textColor: String,
  name: String,
  link: String,
  icon: String,
});
</script>

<style scoped>
.icn {
  transition: transform 0.3s ease-out;
}
.btn {
  overflow: hidden;
}
.btn:hover .icn {
  transform: translateX(0.5rem) rotate(-45deg);
}
</style>
