import {
  createRouter,
  createMemoryHistory,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import routes from "./routes";
import "firebase/auth";
import Lib from "src/h/helpers.js";
import { Notify } from "quasar";

let router = null;
let Store = null;

export default function ({ store }) {
  const createHistory =
    process.env.MODE === "ssr"
      ? createMemoryHistory
      : process.env.VUE_ROUTER_MODE === "history"
        ? createWebHistory
        : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes: routes({ store }),

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === "ssr" ? void 0 : process.env.VUE_ROUTER_BASE,
    ),
  });
  // console.group("/boot");
  // console.log("Router Boot");

  Router.beforeEach(async (to, from, next) => {
    // auth guard & meta tag manager

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.

    // console.log('!!!!!!!!!!!!!!1 ', to)
    // console.log('!!!!!!!!!!!!!!2 ', from)
    if (
      !Lib.getItem("VOC_USER_TOKEN") &&
      to.fullPath !== "/" &&
      to.path !== "/finish-sign-up" &&
      to.path !== "/login"
    ) {
      Lib.setItem("VOC_REDIRECT", JSON.stringify(to.fullPath));
    }

    if (to.query["redirect"]) {
      Lib.setItem("VOC_REDIRECT", JSON.stringify(to.query["redirect"]));
    }

    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
      (el) => el.parentNode.removeChild(el),
    );

    // Skip rendering meta tags if there are none.
    if (nearestWithMeta) {
      // Turn the meta tag definitions into actual elements in the head.
      nearestWithMeta.meta.metaTags
        .map((tagDef) => {
          const tag = document.createElement("meta");

          Object.keys(tagDef).forEach((key) => {
            tag.setAttribute(key, tagDef[key]);
          });

          // We use this to track which meta tags we create so we don't interfere with other ones.
          tag.setAttribute("data-vue-router-controlled", "");

          return tag;
        })
        // Add the meta tags to the document head.
        .forEach((tag) => document.head.appendChild(tag));
    }

    try {
      // Force the app to wait until Firebase has
      // finished its initialization, and handle the
      // authentication state of the user properly

      if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (Lib.getItem("VOC_USER_TOKEN")) {
          next();
        } else {
          next("/");
        }
        // }
        // else if ((to.path === '/auth/register' ||
        //   // to.path === '/auth/login' ||
        //   to.path === '/auth/phone') &&
        //   store.getters.user) {
        //   next('/profile')
      } else {
        if (
          Lib.getItem("VOC_USER_TOKEN") &&
          Lib.getItem("VOC_REDIRECT") &&
          to.path === "/" &&
          to.fullPath !== "/"
        ) {
          const path = JSON.parse(Lib.getItem("VOC_REDIRECT"));
          Lib.setItem("VOC_REDIRECT", null);
          next(path);
        } else {
          next();
        }
      }
    } catch (err) {
      Notify.create({
        message: `${err}`,
        color: "negative",
      });
    }
    // const user = await new Promise((resolve, reject) => {
    //   firebase.auth().onAuthStateChanged(user => {
    //     if (user) {
    //       store.dispatch("getUserFirebase", user);
    //     } else {
    //       store.dispatch("setLoading", false);
    //     }
    //     resolve(user);
    //   });
    // });
    // console.error('Before Each')
    // if (to.matched.some(record => record.meta.requiresAuth)) {
    //   if (store.getters.user) next()
    //   else {
    //     // const user = await firebase.auth().currentUser
    //     console.error(user)
    //     if (user) {
    //       next()
    //     } else {
    //       next('/')
    //     }
    //   }
    // } else next()
  });

  router = Router;
  Store = store;

  return Router;
}

export { router, Store };
