import api from "src/h/api.js";
export default {
  state: {
    email: null,
    subject: null,
    message: null,
    sending: false,
    sent: false,
    error: false,
  },
  mutations: {
    setEmail: (state, payload) => (state.email = payload),
    setSubject: (state, payload) => (state.subject = payload),
    setMessage: (state, payload) => (state.message = payload),
    setSending: (state, payload) => (state.sending = payload),
    setSent: (state, payload) => (state.sent = payload),
    setContactError: (state, payload) => (state.error = payload),
  },
  actions: {
    Send({ state, commit }) {
      commit("setContactError", false);
      commit("setSending", true);
      api
        .Call({
          url: "/mail/contactus",
          method: "post",
          data: {
            email: state.email,
            subject: state.subject,
            message: state.message,
          },
        })
        .then(
          (response) => {
            commit("setSending", false);
            commit("setSent", true);
          },
          (e) => {
            commit("setSending", false);
            commit("setSent", false);
            commit("setContactError", true);
            console.error(e);
          },
        );
    },
  },
};
