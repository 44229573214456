<script setup>
import localizator2 from "./localizator2.vue";
import RightLink from "./RightLink.vue";
import { i18n } from "src/boot/i18n.js";
const blog = {
  name: i18n.global.t("-raw-blog"),
  link: "blog",
  color: "hsl(var(--l2))",
  icon: "r_open_in_new",
};
const social = [
  {
    icon: "fab fa-twitter",
    link: "https://twitter.com/voccent",
  },
  {
    icon: "fab fa-reddit",
    link: "https://www.reddit.com/r/voccent/",
  },
  {
    icon: "fab fa-instagram",
    link: "https://www.instagram.com/v0ccent/",
  },
  {
    icon: "fab fa-facebook",
    link: "https://www.facebook.com/voccent",
  },
  // pinterest
  {
    icon: "fab fa-pinterest",
    link: "https://www.pinterest.com/voccent/",
  },
  // linkedin
  {
    icon: "fab fa-linkedin",
    link: "https://www.linkedin.com/company/voccent/",
  },
  // youtube
  {
    icon: "fab fa-youtube",
    link: "https://www.youtube.com/voccent",
  },
  // discord
  {
    icon: "fab fa-discord",
    link: "https://discord.voccent.info",
  },
];
</script>

<template>
  <section class="column q-px-md">
    <!-- <q-separator style="background: var(--l4)" /> -->
    <div class="row q-pb-lg">
      <svg class="gt-xs q-mb-xs" height="2rem" viewBox="0 0 1250 400">
        <use href="landing/Voccent.svg#voccent" />
      </svg>
      <q-space class="gt-xs" />
      <q-btn
        v-for="s in social"
        :key="s.icon"
        class="soc q-mx-sm"
        :icon="s.icon"
        :href="s.link"
        rounded
        flat
      />
    </div>

    <q-separator style="background-color: var(--l3)" />

    <!-- https://quasar.dev/style/spacing -->
    <div class="q-my-lg row no-wrap justify-between items-center">
      <div class="column items-start">
        <div class="row q-pt-md">
          <localizator2
            class="q-mb-md q-mr-md rounded-12"
            style="background: hsl(var(--l2))"
          />
          <RightLink
            v-bind="blog"
            class="q-mb-md"
            style="margin-left: 0px !important"
          />
        </div>
      </div>
      <q-space />
      <div style="margin-right: -0.65rem; margin-bottom: -0.325rem">
        <a
          href="https://play.google.com/store/apps/details?id=com.voccent.voccent&amp;utm_source=landingpage&amp;utm_campaign=landing_page&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-2"
        >
          <img
            style="height: 4rem"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>
        <a
          href="https://apps.apple.com/us/app/voccent-learning-languages/id1608462833"
        >
          <img
            style="height: 4rem; padding: 0.65rem"
            src="/app-store-badge.png"
          />
        </a>
        <a href="/login">
          <img
            class="gt-md"
            style="height: 4rem; padding: 0.65rem"
            src="/webapp-badge.png"
          />
        </a>
      </div>
    </div>

    <q-separator style="background-color: var(--l3)" />
    <div class="row items-center">
      <h5 class="q-my-lg">We are proud members of</h5>
      <q-space />
      <a href="https://www.actfl.org/">
        <img style="height: 3rem" src="actfl-white-logo.png" />
      </a>
      <a href="https://www.iallt.org/">
        <img
          style="height: 3rem; background: black"
          src="iallt-logo-black.png"
        />
      </a>
    </div>
    <q-separator style="background-color: var(--l3)" />

    <div class="row q-pb-xl q-pt-lg">
      © 2024 Voccent, Inc. All rights reserved.
      <q-space />
      <a
        class="lnk q-mr-lg gt-xs"
        href="welcome"
        style="
          font-size: 0.9rem;
          font-weight: 400;
          color: var(--lc);
          text-decoration: none;
        "
        v-text="$t('-raw-login-mission-and-team')"
      />
      <a
        class="lnk q-mr-lg"
        href="terms-of-use"
        style="
          font-size: 0.9rem;
          font-weight: 400;
          color: var(--lc);
          text-decoration: none;
        "
        v-text="$t('-raw-login-terms-and-conditions')"
      />
      <a
        class="lnk"
        href="privacy-policy"
        style="
          font-size: 0.9rem;
          font-weight: 400;
          color: var(--lc);
          text-decoration: none;
        "
        v-text="$t('-raw-login-privacy policy')"
      />
    </div>
  </section>
</template>

<style scoped>
.soc {
  color: var(--l8);
  transition: all 0.3s ease-out;
}
.soc:hover {
  color: var(--lc);
  scale: 1.1;
}
.lnk:hover {
  text-decoration: underline !important;
}
</style>
