<template>
  <q-fab
    flat
    depressed
    ref="theme2"
    class="bg-1"
    :color="color || '8'"
    :direction="direction"
    :class="thisstate ? 'bg-2' : 'bg-1'"
    @click="$refs.theme1.toggle()"
    icon="svguse:iconz.svg#theme"
    style="width: 3rem; height: 3rem; border-radius: 0.75rem"
    v-model="thisstate"
    padding="none"
  >
    <div
      class="round-1 z-top bg-0"
      style="margin-bottom: -0.5rem"
      :class="
        direction === 'right' || direction === 'left' ? 'row no-wrap' : ''
      "
    >
      <q-fab-action
        unelevated
        :key="theme[0]"
        style="border-radius: 0.75rem !important"
        v-for="theme in $store.state.theme"
        @click="$store.dispatch('setSaveTheme', theme)"
        :style="{
          background: theme[0],
        }"
      />
    </div>
  </q-fab>
</template>

<script>
export default {
  props: ["direction", "color", "parentstate"],
  data: () => ({
    thisstate: false,
  }),
  watch: {
    parentstate(val) {
      if (!val) {
        this.thisstate = false;
      }
    },
  },
};
</script>
