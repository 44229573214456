import Info from "src/pages/library/info.vue";
import Content from "src/pages/library/content.vue";
// import api from "src/h/api.js";
// import h from "src/h/helpers.js"
import { Notify } from "quasar";
import { v4 as uuidv4 } from "uuid";

export default {
  components: { Info, Content },
  emits: ["choosefile"],
  props: {
    view: {
      // {isFit, canChangeType, canChoose}
      type: Object,
      default: function () {
        return {};
      },
    },
    data: {
      // {type, objectid, fileType}
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    isloaded: false,
    isreadyRead: false,
    isreadyUpdate: false,
    canUpdate: false,
    library: {},
    streamerUpdate: null,
    streamerRead: null,
    streamerReadByType: null,
    streamerOperationID: null,
    // paramsURL: {},
    sessionID: uuidv4(),
    enumID: uuidv4(),
    keyreload: 0,
    paramsURL: {},
    filetype: null,
  }),
  beforeMount() {
    // get type && objectid from param or from url
    let type = this.data?.type;
    let objectid = this.data?.objectid;
    if (!type) {
      type = this.$route.params.type;
    }
    if (!objectid) {
      objectid = this.$route.params.objectid;
    }

    this.parseParamsUrl();
    this.filetype = this.data.fileType;
    if (!this.filetype && this.paramsURL["filetype"]) {
      this.filetype = this.paramsURL["filetype"];
    }
    if (this.paramsURL["filetype"]) {
      // important check this for router inside tabs 'authoring'
      this.setParamsUrl({ filetype: null });
    }

    if (type) {
      this.streamerOperationID = type + "_" + objectid;
    } else {
      // for system library don't need use 'type' param
      this.streamerOperationID = "system";
    }
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    this.streamerUpdateDisconnect();
    this.streamerReadDisconnect();
    this.streamerReadByTypeDisconnect();
  },
  watch: {
    "$streamer.isConnect.value": {
      handler(newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          this.init();
        }
      },
      immediate: false,
    },
    // $route(to, from) {},
  },
  methods: {
    init() {
      if (this.$streamer.isConnect.value === false) {
        return;
      }
      this.$store.commit("setCurrentLibrary", {});
      this.isloaded = false;
      this.streamerReadByTypeConnect();
    },
    getLibrary() {
      this.streamerReadByType.send({
        Operation: "select_library",
        Object: {},
      });
    },
    streamerUpdateConnect() {
      this.isreadyUpdate = false;
      this.canUpdate = false;
      var lnk = this;
      lnk.streamerUpdate = lnk.$streamer.addTicket(
        "library_update",
        this.library.ID,
        function (obj, sessionID) {},
        function (obj, lastSend, lastOp) {
          let res = JSON.parse(obj);
          if (lastOp == "generate_ticket_token") {
            // don't show error here (user can read, but can't update library)
            lnk.canUpdate = false;
            lnk.isreadyUpdate = true;
          } else {
            Notify.create({
              group: true,
              timeout: 10000,
              message:
                res?.ErrCode === "ret-2000"
                  ? res?.Err
                  : lnk.$t("-raw-error-occurred"),
              color: "1",
              textColor: "n",
              classes: "round-both q-ml-lg q-mb-sm",
              position: "bottom",
            });
            if (lastSend) {
              lnk.$refs["infoRef_" + lnk.sessionID].sync(lastSend, "error");
            }
          }
        },
        this.sessionID,
        function (obj, sessionID) {
          lnk.isreadyUpdate = true;
          lnk.canUpdate = true;
        },
      );
    },
    streamerUpdateDisconnect() {
      if (this.streamerUpdate !== null) {
        this.streamerUpdate.removeTicket();
      }
    },
    streamerReadByTypeConnect() {
      var lnk = this;
      lnk.streamerReadByType = lnk.$streamer.addTicket(
        "library_read_by_type",
        this.streamerOperationID,
        function (obj, sessionID) {
          switch (obj.Operation) {
            case "select_library":
              lnk.$store.commit("setCurrentLibrary", obj.Object);
              lnk.library = obj.Object;
              lnk.isloaded = true;
              lnk.streamerReadConnect();
              lnk.streamerUpdateConnect();
              break;
          }
        },
        function (obj, lastSend, lastOp) {
          // let res = JSON.parse(obj);
          if (lastOp == "generate_ticket_token") {
            Notify.create({
              group: true,
              timeout: 10000,
              message: "Have no access", // + " Can't select data.",
              color: "1",
              textColor: "n",
              classes: "round-both q-ml-lg q-mb-sm",
              position: "bottom",
            });
          } else {
            Notify.create({
              group: true,
              timeout: 10000,
              message:
                res?.ErrCode === "ret-2000"
                  ? res?.Err
                  : lnk.$t("-raw-error-occurred"),
              color: "1",
              textColor: "n",
              classes: "round-both q-ml-lg q-mb-sm",
              position: "bottom",
            });
          }
        },
        this.sessionID,
        function (obj, sessionID) {
          lnk.getLibrary();
        },
      );
    },
    streamerReadByTypeDisconnect() {
      if (this.streamerReadByType !== null) {
        this.streamerReadByType.removeTicket();
      }
    },
    streamerReadConnect() {
      this.isreadyRead = false;
      var lnk = this;
      lnk.streamerRead = lnk.$streamer.addTicket(
        "library_read",
        this.library.ID,
        function (obj, sessionID) {
          switch (obj.Operation) {
            case "select_category_library":
              lnk.$refs["infoRef_" + lnk.sessionID].sync(
                obj.Object,
                "select_category_library",
              );
              break;
            case "insert_category_library":
            case "update_category_library":
            case "delete_category_library":
            case "update_category_library_order":
              lnk.$refs["infoRef_" + lnk.sessionID].sync(
                obj.Object,
                obj.Operation,
              );
              break;
            case "select_file_type_library":
              lnk.$refs["contentRef_" + lnk.sessionID].sync(
                obj.Object,
                obj.Operation,
              );
              break;
            case "insert_library_file":
            case "file_group_change":
            case "file_delete":
            case "file_name_update":
              lnk.$refs["contentRef_" + lnk.sessionID].syncSearch(
                obj.Object,
                obj.Operation,
              );
              break;
            case "search_file_library":
              lnk.$refs["contentRef_" + lnk.sessionID].sync(
                obj.Object,
                "search_file_library",
                null,
                obj.RequestID,
              );
              break;
          }
        },
        function (obj, lastSend, lastOp) {
          let res = JSON.parse(obj);
          if (lastOp == "generate_ticket_token") {
            Notify.create({
              group: true,
              timeout: 10000,
              message: "Have no access", // + " Can't select data.",
              color: "1",
              textColor: "n",
              classes: "round-both q-ml-lg q-mb-sm",
              position: "bottom",
            });
          } else {
            Notify.create({
              group: true,
              timeout: 10000,
              message:
                res?.ErrCode === "ret-2000"
                  ? res?.Err
                  : lnk.$t("-raw-error-occurred"),
              color: "1",
              textColor: "n",
              classes: "round-both q-ml-lg q-mb-sm",
              position: "bottom",
            });
          }
        },
        this.sessionID,
        function (obj, sessionID) {
          lnk.isreadyRead = true;
        },
      );
    },
    streamerReadDisconnect() {
      if (this.streamerRead !== null) {
        this.streamerRead.removeTicket();
      }
    },
    save(obj, method) {
      this.streamerUpdate.send({
        Operation: method,
        Object: obj,
      });
    },
    read(obj, method, requestid) {
      this.streamerRead.send({
        Operation: method,
        Object: obj,
        RequestID: requestid,
      });
    },
    groupChanged(group) {
      this.$refs["contentRef_" + this.sessionID].groupChanged(group);
    },
    treeChanged(tree) {
      this.$refs["contentRef_" + this.sessionID].treeChanged(tree);
    },
    chooseFile(fileObjectID) {
      this.$emit("choosefile", fileObjectID);
    },
    parseParamsUrl() {
      if (!this.$router.currentRoute.value.query) {
        return;
      }

      this.paramsURL = {};
      var qr = this.$router.currentRoute.value.query;
      for (var name in qr) {
        this.paramsURL[name] = qr[name];
      }
    },
    setParamsUrl(params) {
      const query = Object.assign({}, this.$route.query);

      for (var name in params) {
        this.paramsURL[name] = encodeURIComponent(params[name]);
      }
      for (var name in this.paramsURL) {
        if (this.paramsURL[name]?.length > 0) {
          if (this.paramsURL[name] == "null") {
            delete query[name];
          } else {
            query[name] = this.paramsURL[name];
          }
        } else {
          query[name] = "";
        }
      }

      this.$router.push({ query });
    },
    // setParamsUrl(params) {
    //   const query = Object.assign({}, this.$route.query);

    //   for (var name in params) {
    //     this.paramsURL[name] = encodeURIComponent(params[name]);
    //   }
    //   for (var name in this.paramsURL) {
    //     if (this.paramsURL[name]?.length > 0) {
    //       query[name] = this.paramsURL[name];
    //     } else {
    //       query[name] = "";
    //     }
    //   }

    //   // NOTE: If we want to use back and forward navigation t needs to be analyzed more thoroughly
    //   // if (query['t'] != this.$route.query['t']) {
    //   //   if (this.$route.name === "route-campus" && !this.$route.query['t']) {
    //   //     this.$router.replace({ query })
    //   //   } else {
    //   //     this.$router.push({ query })
    //   //   }
    //   // } else { // other params
    //   //   this.$router.push({ query })
    //   // }
    // },
    startdrag(item) {
      this.$refs["infoRef_" + this.sessionID].startdrag(item);
    },
  },
};
