import { Loader } from "@googlemaps/js-api-loader";

let googleMap = {};

export default ({ app }) => {
  console.debug(
    "%cboot/google-map.js",
    "background: black; font-weight: bold; color: white;",
  );

  googleMap = new Loader({
    apiKey: process.env.GOOGLE_MAP_PUBLIC_KEY,
    version: "weekly",
  });

  app.config.globalProperties.$googleMap = googleMap;
};

export { googleMap };
