<template>
  <q-btn class="q-mx-sm mont-800 rounded-8 strech q-px-sm" style="color: var(--lc)" flat no-caps :label="link" />
</template>

<script setup>
defineProps({
  link: String,
});
</script>

<style scoped>
.strech {
  transition: all 0.2s ease-in-out;
  /* font-stretch: 100%; */
}
.strech:hover {
  transform: scale(1.1, 1);
  /* font-stretch: 150% !important; */
}
</style>
