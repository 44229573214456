export default {
  state: {
    playlistID: null,
    playlistChannelID: null,
    editPlaylist: false,
    playlistName: null,
    playlistCategories: [],
    playlistLanguages: [],
    playlistSourceLanguage: null,
  },
  mutations: {
    setPlaylistID(state, ID) {
      state.playlistID = ID;
    },
    setPlaylistChannelID(state, ID) {
      state.playlistChannelID = ID;
    },
    setPlaylistName(state, name) {
      state.playlistName = name;
    },
    setPlaylistCategories(state, payload) {
      state.playlistCategories.splice(
        0,
        state.playlistCategories.length,
        ...payload,
      );
    },
    setPlaylistLanguages(state, payload) {
      state.playlistLanguages.splice(
        0,
        state.playlistLanguages.length,
        ...payload,
      );
    },
    setPlaylistSourceLanguage(state, payload) {
      state.playlistSourceLanguage = payload;
    },
    setEditPlaylist(state, bool) {
      state.editPlaylist = bool;
    },
    resetPlaylist(state) {
      state.playlistID = null;
    },
  },
  getters: {
    playlistID: (state) => state.playlistID,
    playlistChannelID: (state) => state.playlistChannelID,
    editPlaylist: (state) => state.editPlaylist,
    playlistName: (state) => state.playlistName,
    playlistCategories: (state) => state.playlistCategories,
    playlistLanguages: (state) => state.playlistLanguages,
    playlistSourceLanguage: (state) => state.playlistSourceLanguage,
  },
};
