<template>
  <div class="language-level-circle">
    <!-- center-color="white"
      track-color="lightgrey" -->
    <q-circular-progress
      :value="progressValue"
      size="150px"
      color="v"
      show-value
      font-size="20px"
      class="circle-progress bg-0 round-both"
    >
      <template #default>
        <div class="star" style="user-select: none">&#9733;</div>
        <!-- Star character -->
        <div class="level-text" style="user-select: none">
          {{ currentLevel }}
        </div>
        <q-tooltip
          anchor="top middle"
          class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
          self="bottom middle"
          transition-hide="jump-up"
          transition-show="jump-down"
        >
          {{ currentLevelName }}
          <!-- ({{ progressValue }}) -->
        </q-tooltip>
      </template>
    </q-circular-progress>
    <!-- <div class="level-legend">Current Level</div> -->
    <div class="level-legend">{{ language }}</div>
  </div>
</template>

<script>
export default {
  props: ["score", "lang"],
  data: () => ({
    levelInterval: [30, 40, 50, 60, 70],
    levels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    levelNames: [
      "Begginer",
      "Elementary",
      "Intermediate",
      "Upper intermediate",
      "Advanced",
      "Profecient",
    ],
    currentLevel: "",
    currentLevelName: "",
  }),
  computed: {
    progressValue() {
      return parseInt(this.score);
    },
    language() {
      return this.lang;
    },
  },
  mounted() {
    let score = parseInt(this.score);
    let levelNum = 0;
    for (let j = 0; j < this.levelInterval.length; j++) {
      if (score <= this.levelInterval[j]) {
        levelNum = j;
        break;
      }
    }
    if (score > this.levelInterval[this.levelInterval.length - 1]) {
      levelNum = this.levels.length - 1; // it's right, we have no interval for big value
    }
    this.currentLevel = this.levels[levelNum];
    this.currentLevelName = this.levelNames[levelNum];
  },
};
</script>

<style>
.language-level-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.level-text {
  position: absolute;
  font-size: 1.6em;
  font-weight: bold;
  color: var(--q-0);
  z-index: 2;
}

.star {
  position: absolute;
  font-size: 6.9rem; /* Larger size for the star */
  color: var(--q-w); /* Gold color for the star */
  z-index: 1; /* Ensure the star is behind the text */
  opacity: 0.8; /* Adjust opacity for a subtle effect */
}

.level-legend {
  margin-top: 10px;
  font-weight: 600;
  text-align: center;
}
</style>
