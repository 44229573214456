<template>
  <q-btn
    flat
    color="0"
    class="gt-sm bg-v round-1"
    :icon="showInbox ? 'speaker_notes_off' : 'chat'"
    style="height: 3rem; width: 3rem"
  >
    <q-badge
      v-if="newMessages > 0"
      floating
      style="font-size: 0.8rem; font-weight: 2000; color: var(--q-0)"
      rounded
      color="n"
      :label="newMessages"
    />
    <q-menu
      v-model="showInbox"
      fit
      transition-show="jump-left"
      transition-hide="jump-right"
      anchor="top right"
      self="top left"
      :offset="[16, 79]"
      style="overflow: hidden; border-radius: 0; box-shadow: none; height: 100%"
      class="z-max bg-0"
      @show="openInboxEvent()"
    >
      <div
        class="relative"
        style="
          height: 100%;
          min-width: 500px;
          overflow: hidden;
          border-left: 1px solid var(--q-2);
        "
      >
        <div
          class="relative row full-width items-center text-8 ubuntu q-pa-md"
          style="height: 5rem"
        >
          <q-btn
            v-if="selectedInbox.ID || writeTo.objectID"
            flat
            color="8"
            class="round-1 z-max"
            icon="arrow_back"
            @click="[(selectedInbox = {}), (writeTo = {})]"
          />
          <div class="text-v text-left" style="font-size: 1.5rem">
            {{ $t("-raw-main-chat") }}
          </div>
        </div>
        <div
          v-if="!selectedInbox.ID && !writeTo.objectID"
          class="absolute column full-width"
          style="height: calc(100% - 5rem)"
        >
          <div
            class="col q-mb-md"
            style="overflow-y: auto; overflow-x: hidden; position: relative"
          >
            <div
              class="inboxelement"
              :class="
                (activeObject.ID === selectedInbox.ID
                  ? 'selected'
                  : 'nselected',
                activeObject.MessageStatus === 'new'
                  ? 'newmessageunread'
                  : 'nselected')
              "
              v-for="activeObject in activeList"
              :key="activeObject.ID + '_chat_active'"
              @click="ChooseInboxElement(activeObject)"
            >
              <!--
                Don't work, never fill 'Separator'
                 <div v-if="activeObject.Separator">
                <br />
                <div class="row full-width items-center text-v q-py-sm">
                  Old messages
                </div>
                <q-separator size="2px" class="col round-both" />
              </div> -->
              <div class="row full-width items-center q-py-sm q-px-md">
                <!-- :style="
                  activeObject.Type === 'system_personal'
                    ? 'position: absolute; top: -1rem; right: 0.5rem; width: 50% !important;'
                    : ''
                " -->
                <div
                  style="font-size: 1rem"
                  class="row full-width items-center"
                >
                  <q-img
                    src="/logo.png"
                    width="2em"
                    height="2em"
                    class="q-mr-sm"
                    v-if="activeObject.Type === 'system_personal'"
                  />
                  <div
                    :class="{
                      'text-v text-bold':
                        activeObject.Type === 'system_personal',
                    }"
                  >
                    {{
                      activeObject.SubjectName.length > 20
                        ? `${activeObject.SubjectName.slice(0, 19)}...`
                        : activeObject.SubjectName
                    }}
                  </div>
                  <q-space />
                  <template
                    v-for="usr in activeObject.Users"
                    :key="usr.ID + '_chat_users'"
                  >
                    <div
                      v-if="activeObject.Type !== 'system_personal'"
                      class="bg-2 row items-center round-both q-ma-xs"
                      style="
                        font-size: 0.8rem;
                        font-weight: bold;
                        padding: 0 0.75rem;
                        height: 2rem;
                      "
                    >
                      {{ usr.Name }}
                    </div>
                  </template>
                  <q-icon
                    v-show="activeObject.MessageStatus === 'new'"
                    name="star"
                    size="1.5rem"
                    color="v"
                  />
                </div>
              </div>
              <q-separator
                style="
                  height: 1px;
                  background: linear-gradient(
                    90deg,
                    var(--q-2) 69%,
                    var(--q-0)
                  );
                "
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="absolute column full-width q-pl-md"
          style="height: calc(100% - 5rem); width: 100%"
        >
          <chat
            v-if="selectedInbox.ID"
            :chatid="selectedInbox.ID"
            :chatsubject="selectedInbox.SubjectName"
            :chatusers="selectedInbox.Users"
            :key="selectedInbox.ID"
            :chatvar="selectedInbox.Type"
          />
          <chat
            v-if="writeTo.objectID"
            :chatid="null"
            @creatednew="CreatedNewChat"
            :chattype="writeTo.type"
            :chatobjectid="writeTo.objectID"
            :key="writeTo.objectID"
          />
        </div>
      </div>
    </q-menu>
  </q-btn>
</template>

<style scoped>
.inboxelement {
  cursor: pointer;
  user-select: none;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.inboxelement.nselected {
  background: var(--q-0);
  transition: all 0.2s ease-in-out;
}
.inboxelement.nselected:hover {
  background: var(--q-1);
}
.inboxelement.newmessageunread {
  font-weight: 1000;
  background: var(--q-3);
}
.inboxelement.newmessageunread:hover {
  background: var(--q-6);
}
.inboxbutton {
  user-select: none;
  /* //TODO:  fill: use('iconz.svg#vocgradient') #777FFF !important; */
  background: linear-gradient(45deg, var(--q-w), var(--q-h), var(--q-v));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
</style>

<script src="./inbox.js"></script>
